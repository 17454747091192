import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Calendar, Users, Lock, Brain, Building, ChevronRight, Github, Linkedin, Facebook, Check, Crown, FileIcon } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { InvertedPyramid } from '../components/InvertedPyramid';
import { useState } from 'react';
import { useEffect } from 'react';
import { MetallicRings } from '../components/MetallicRings';
import { useRef } from 'react';
import { AnimatedRingsExplanation } from '../components/AnimatedRingsExplanation';
import { NewsletterSignup } from '../components/NewsletterSignup';


export default function HomePage() {


const useElementOnScreen = (options: IntersectionObserverInit) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [options]);

  return [containerRef, isVisible] as const;
};

const [videoRef, isVideoVisible] = useElementOnScreen({
  threshold: 0.5
});






  return (
    <>
    <Helmet>
      <title>Ctrl AI - Build Your Billion-Dollar Company Today</title>
      <meta name="description" content="Build your billion-dollar company using AI, community, and ownership. Join our first 1,000 founders and get everything you need to succeed with Ctrl AI." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://ctrlai.com/" />
      <meta property="og:title" content="Ctrl AI - Build Your Billion-Dollar Company Today" />
      <meta property="og:description" content="Build your billion-dollar company using AI, community, and ownership. Join our first 1,000 founders and get everything you need to succeed with Ctrl AI." />
      <meta property="og:image" content="https://ctrlai.com/og-image-home.jpg" />

      {/* Twitter/X */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://ctrlai.com/" />
      <meta name="twitter:title" content="Ctrl AI - Build Your Billion-Dollar Company Today" />
      <meta name="twitter:description" content="Build your billion-dollar company using AI, community, and ownership. Join our first 1,000 founders and get everything you need to succeed with Ctrl AI." />
      <meta name="twitter:image" content="https://ctrlai.com/og-image-home.jpg" />

      {/* Additional SEO tags */}
      <meta name="keywords" content="AI company building, startup, entrepreneurship, artificial intelligence, business growth, community building, ownership model, billion dollar company" />
      <link rel="canonical" href="https://ctrlai.com/" />
      <meta name="robots" content="index, follow" />
    </Helmet>
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="min-h-[90vh] container text-center mx-auto px-0 md:px-4 pt-20 lg:mt-40 lg:pt-0">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-14"
          >
            <h1 className="text-6xl lg:text-7xl font-bold tracking-tight">
            Ctrl AI: The First AI-Era {' '}
              <span className="inline-block gold-gradient">Solo Founder Incubator</span>{' '}
              
            </h1>
            <h2 className="text-4xl lg:text-5xl font-bold text-gray-400 dark:text-gray-400 leading-relaxed">
            Build High-Impact Companies Solo—No Big Teams, No VCs, No Massive Ad Budgets
            </h2>
            <Button 
              size="lg"
              className="text-lg px-16 font-bold py-6 rounded-full gold-button"
              onClick={() => window.location.href = 'https://www.skool.com/ctrlai'}
            >
              <span className="hidden md:inline">Join the Core Ring – $100/month</span>
              <span className="md:hidden">Join the Core Ring</span>
            </Button>
          </motion.div>
        </div>
      </div>

      {/* Main Value Proposition */}
      <section className="py-4 md:py-16 container mx-auto px-4">
        <div className="max-w-6xl mx-auto space-y-16">
          <div className="text-center space-y-8">
            <h2 className="text-5xl lg:text-5xl font-bold tracking-tight">The Solo Founder Revolution</h2>
            <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
              For the first time in history, AI empowers one founder to accomplish what once took entire teams. Instead of VC-driven hierarchies, imagine a future where community and ownership fuel growth.
              <br />
              <br />
              <span className="dark:text-white">Ctrl AI is your blueprint, your incubator, and your launchpad into this new era.</span>
            </p>
          </div>

          {/* Old Model Section */}
          <div className="text-center pt-16 pb-24 overflow-hidden">
            <h2 className="text-4xl lg:text-5xl font-bold text-gray-400 dark:text-gray-400 leading-relaxed mb-28 mt-28">The Paradigm Shift</h2>
            <div className="max-w-2xl mx-auto space-y-6">
              <h3 className="text-4xl lg:text-5xl font-bold mb-8">Old Model: Inverted Pyramid</h3>
              <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed pb-8">
                This unstable structure serves investor interests first, leaving founders and users in the background.
              </p>
              <div className="overflow-hidden max-w-full">
                <InvertedPyramid className="mx-auto max-w-full" />
              </div>
            </div>
          </div>
          </div>
          </section>

          {/* New Model Section - Full Width */}
          <div className="text-center pt-24 mt-24 relative overflow-hidden" style={{
            background: 'radial-gradient(ellipse at top, #2c2c2c, #111111)',
            padding: '6rem 0',
          }}>
            <div className="w-5xl mx-auto space-y-6">
              <h3 className="text-4xl lg:text-5xl font-bold mb-8">New Model: Concentric Rings</h3>
              <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed pb-8">
                Stable, expansive, and user-aligned—this model puts everyone's interests front and center.
              </p>
              <AnimatedRingsExplanation />
            </div>
          </div>

          <h3 className="text-4xl lg:text-5xl text-center font-bold mb-4 pt-24">Our Three Pillars</h3>

          <div className="max-w-5xl mx-auto space-y-8">

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex flex-col md:flex-row p-8 rounded-3xl bg-white dark:bg-gray-950"
            >
              <div className="w-full md:w-1/3 flex items-center justify-center mb-6 md:mb-0">
                <span
                  style={{
                    fontSize: '20rem',
                    WebkitTextStroke: '1px #00B8FF',
                    color: 'transparent',
                  }}
                  className="font-bold"
                >
                  1
                </span>   
              </div>
              <div className="w-full md:w-2/3 space-y-6">
                  <Brain className="h-10 w-10 mt-6 md:mt-16 text-blue-500" />
                <h3 className="text-4xl font-bold mb-4">AI Replaces Teams</h3>
                <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
                What took 20 people can now be done by you and AI.
                </p>
                <ul className="space-y-3">
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.6 }}
                    viewport={{ once: true }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Code complex apps with natural language</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 1 }}
                    viewport={{ once: true }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Generate designs & branding instantly</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 1.4 }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Automate marketing & operations at scale</span>
                  </motion.li>
                  
                </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="flex flex-col md:flex-row p-8 rounded-3xl bg-white dark:bg-gray-950"
            >
              <div className="w-full md:w-1/3 flex items-center justify-center mb-6 md:mb-0">
                <span
                  style={{
                    fontSize: '20rem',
                    WebkitTextStroke: '1px #FF00FF',
                    color: 'transparent',
                  }}
                  className="font-bold"
                >
                  2
                </span>   
              </div>
              <div className="w-full md:w-2/3 space-y-6">
                  <Users className="h-10 w-10 mt-6 md:mt-16  text-violet-500" />
              <h3 className="text-4xl font-bold mb-4">Community Replaces Investors</h3>
              <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
              No more chasing VCs. Build with your earliest supporters.
              </p>
              <ul className="space-y-3">
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Immediate product feedback & validation</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Collective expertise at your fingertips</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.4 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Authentic growth channels from day one</span>
                </motion.li>
                
              </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}
              className="flex flex-col md:flex-row p-8 rounded-3xl bg-white dark:bg-gray-950"
            >
              <div className="w-full md:w-1/3 flex items-center justify-center mb-6 md:mb-0">
                <span
                  style={{
                    fontSize: '20rem',
                    WebkitTextStroke: '1px green',
                    color: 'transparent',
                  }}
                  className="font-bold"
                >
                  3
                </span>   
              </div>
              <div className="w-full md:w-2/3 space-y-6">
                  <Crown className="h-10 w-10 mt-6 md:mt-16 text-green-500" />
              <h3 className="text-4xl font-bold mb-4">Ownership Replaces Marketing</h3>
              <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
              Turn users into owners, not just customers.
              </p>
              <ul className="space-y-3">
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Distribute shares instead of buying ads</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Users become your brand champions</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.4 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Organic, viral growth powered by shared incentives</span>
                </motion.li>
                
                
              </ul>
              </div>
            </motion.div>
          </div>  


  {/* What Makes Us Different */}
    <section className="py-32 p-0 md:p-8 bg-blue-50 dark:bg-gray-900/50">
      <div className="container mx-auto px-4 my-32">
        <div className="max-w-5xl mx-auto text-center space-y-12">
          <div className="space-y-4">
            <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Our Unique Approach</h2>
            <p className="text-xl lg:text-2xl font-bold mx-auto  mb-16 text-gray-600 dark:text-gray-500 leading-relaxed">
            Not Just Another Course—A Real Incubator
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Feature Cards */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className=" p-8 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-800 text-center"
            >
              <div className="flex justify-center">
                <FileIcon className="h-12 w-12 text-blue-500 mb-6" />
              </div>
              <h3 className="text-2xl font-bold mb-3">Complete Blueprint</h3>
              <p className="text-gray-600 dark:text-gray-400">
              Detailed steps to build your own community-owned, AI-driven venture.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className=" p-8 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-800 text-center"
            >
              <div className="flex justify-center">
                <Brain className="h-12 w-12 text-violet-500 mb-6" />
              </div>
              <h3 className="text-2xl font-bold mb-3">Real-Time Case Study</h3>
              <p className="text-gray-600 dark:text-gray-400">
              Watch us build Ctrl AI using this exact model
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className=" p-8 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-800 text-center"
            >
              <div className="flex justify-center">
                <Crown className="h-12 w-12 text-yellow-500 mb-6" />
              </div>
              <h3 className="text-2xl font-bold mb-3">Meaningful Ownership</h3>
              <p className="text-gray-600 dark:text-gray-400">
              15% ownership reserved for our first 1,000 founders in the Core Ring.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.5 }}
              className=" p-8 rounded-2xl shadow-sm border border-gray-200 dark:border-gray-800 text-center"
            >
              <div className="flex justify-center">
                <Users className="h-12 w-12 text-green-500 mb-6" />
              </div>
              <h3 className="text-2xl font-bold mb-3">Network of Builders</h3>
              <p className="text-gray-600 dark:text-gray-400">
              Collaborate with ambitious founders pioneering the future.
              </p>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
            className="pt-8"
          >
           
          </motion.div>
        </div>
      </div>
    </section>


      {/* Video Section - full width */}
      <div className="text-center space-y-8 my-32 p-32">
                <h2 className="text-5xl lg:text-5xl font-bold tracking-tight">The Time is Now</h2>
                <p className="text-md  lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
                AI’s capabilities and community-building tools align perfectly—this unique window may last only 24 months. Act now and claim your position in a future where AI and community co-create tomorrow’s giants.
                </p>
              </div>

      <div className="w-full px-6">
                  <div ref={videoRef} className="w-full aspect-video relative">
                    <iframe
                      className="w-full h-full rounded-2xl border mt-20 border-gray-200 dark:border-gray-800"
                      src="https://www.youtube.com/embed/AJZHdjf8RBw?rel=0&modestbranding=1&controls=1"
                      title="Ctrl AI Demo Video"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>


      {/* How We're Proving It */}
      <section className="py-32 bg-gradient-to-b from-blue-50 to-white dark:from-black dark:to-gray-950">
        <div className="container mx-auto px-0 md:px-4">
          <div className="max-w-6xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-white dark:bg-black rounded-3xl shadow-xl p-4 md:p-12"
            >
              <div className="text-center space-y-8 mb-12">
                <h2 className="text-5xl lg:text-5xl font-bold tracking-tight">Real-Time Proof: Building Ctrl AI</h2>
                <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
                We’re not just talking—we’re doing it:
                </p>
              </div>
              <div className="space-y-8">
                {[
                  "AI Instead of Teams: A founder + AI building complex features alone.",
                  "Community Instead of VCs: Early members co-creating product features.",
                  "Ownership Instead of Ads: Our community, not paid campaigns, fuels growth."
                ].map((text, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.3 }}
                    className="flex items-center space-x-4 p-4 mx-0 rounded-full bg-white dark:bg-gray-950 border border-gray-200 dark:border-gray-800"
                  >
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.3 + 0.5 }}
                      className="h-10 w-10 rounded-full bg-gray-900 flex items-center justify-center"
                    >
                      <Check className="h-5 w-5 text-blue-500" />
                    </motion.div>
                    <span className="text-xl md:pl-2 text-gray-700 dark:text-gray-300">{text}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Join Our Founding Members */}
      <section className="py-32 container mx-auto px-0 md:px-4">
        <div className="max-w-4xl mx-auto text-center space-y-12">
          <div className="space-y-4">
            <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Join the Core Ring</h2>
            <p className="text-xl lg:text-2xl text-gray-600 dark:text-gray-400 leading-relaxed">
            Limited to the First 1,000 Founders. After that, the Growth and Scale Rings open at lower monthly rates—but with smaller ownership shares.

            </p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="rounded-3xl border-2 md:max-w-xl mx-auto border-gray-200 dark:border-gray-900 overflow-hidden"
          >
            <div className="p-8 md:p-12 bg-gray-900 text-white">
              <div className="flex items-baseline justify-center">
                <span className="text-3xl font-semibold">$100</span>
                <span className="text-xl ml-1">/month</span>
              </div>
            </div>

            <div className="p-4 md:p-8 space-y-8 bg-white dark:bg-gray-950">
              {[
                {
                  title: "Complete blueprint for AI-era solo building",
                },
                {
                  title: "Weekly building sessions—see Ctrl AI’s creation live",
                },
                {
                  title: "Access to a curated network of innovative founders",
                },
                {
                  title: "Significant ownership stake in Ctrl AI and future incubator successes",
                }
              ].map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-4"
                >
                    <Check className="h-4 w-4 mt-1.5  text-blue-500" />
                  <div className="text-left">
                    <h3 className="font-semibold text-lg">{benefit.title}</h3>
                  </div>
                </motion.div>
              ))}

              <Button 
                size="lg"
                className="text-lg w-full px-8 md:px-14 font-bold py-6 rounded-full gold-button"
                onClick={() => window.location.href = 'https://www.skool.com/ctrlai'}
              >
                <span className="hidden md:inline">Sign Up - $100/month</span>
                <span className="md:hidden">Sign Up - $100/month</span>
              </Button>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="py-32 container mx-auto px-4">
        <NewsletterSignup variant="homepage" />
      </section>

      {/* Final Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="mt-16 mb-32 md:max-w-3xl mx-auto p-2 md:p-16 border-gray-200 dark:border-gray-900 rounded-2xl dark:text-white text-center"
      >
        <h2 className="text-4xl mb-12 lg:text-5xl font-bold tracking-tight">The Network Effect: Share in the Success</h2>
        <p className="text-xl mb-32 font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">Every successful venture emerging from this incubator shares a portion back with the community network. As these projects grow—some reaching massive valuations—members benefit collectively.
        <br></br>
        <br></br>
        <span className="dark:text-white">You’re not just building one company; you’re joining a network of future-leading startups, each reinforcing the others.
        </span> </p>
        <Button 
          size="lg"
          className="text-lg px-14 w-full md:px-14 font-bold py-6 rounded-full gold-button"
          onClick={() => window.location.href = 'https://www.skool.com/ctrlai'}
        >
          <span className="hidden md:inline">Join the Core Ring – $100/month</span>
          <span className="md:hidden">Join Now - $100/month</span>
        </Button>
        <p className="text-lg mt-8 mb-16">Price for first 100 members only</p>

        <div className="space-y-4">
            <h2 className="text-4xl pt-12 lg:text-5xl mt-32 font-bold tracking-tight">Final Note</h2>
            <p className="text-xl pt-6 lg:text-2xl text-gray-600 dark:text-gray-400 leading-relaxed">
            This is the moment when one founder can reshape industries. With AI as your team, a community as your backers, and ownership fueling growth, you can challenge the giants and stand among them. Embrace the new model, join the Core Ring, and let’s build the future together.

            </p>
          </div>

      </motion.div>
    </div>

      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Ctrl AI",
            "description": "Build your billion-dollar company using AI, community, and ownership.",
            "url": "https://ctrlai.com",
            "logo": "https://ctrlai.com/logo.png",
            "sameAs": [
              "https://x.com/ctrlai_",
              "https://www.linkedin.com/company/100084573"
            ]
          })}
        </script>

        </>

  );
}