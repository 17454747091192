import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from './avatar';
import { DEFAULT_PROFILE_PICTURE, FIREBASE_STORAGE_URLS } from '../../constants';
import { useThemeStore } from '../../stores/themeStore';

interface ProfileAvatarProps {
  userId: string;
  className?: string;
  userName?: string;
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ 
  userId, 
  className,
  userName = 'User'
}) => {
  const { isDarkMode } = useThemeStore();
  const defaultImage = isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light;
  const thumbnailUrl = FIREBASE_STORAGE_URLS.profilePicture.getThumbnailUrl(userId);

  return (
    <Avatar className={className}>
      <AvatarImage 
        src={thumbnailUrl} 
        alt={userName}
        onError={(e) => {
          (e.target as HTMLImageElement).src = defaultImage;
        }}
      />
      <AvatarFallback>
        <img 
          src={defaultImage} 
          alt={userName} 
          className="h-full w-full object-cover"
        />
      </AvatarFallback>
    </Avatar>
  );
}; 