export const processImage = async (file: File, maxSize: number): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    // Validate file type
    if (!file.type.startsWith('image/')) {
      reject(new Error('Invalid file type. Please upload an image file.'));
      return;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      // Clean up object URL
      URL.revokeObjectURL(objectUrl);

      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;
      

      // Ensure minimum dimensions before scaling
      const minSize = 100;
      if (width < minSize || height < minSize) {
        reject(new Error(`Image must be at least ${minSize}x${minSize} pixels. Current size: ${width}x${height}`));
        return;
      }

      // Calculate new dimensions while maintaining aspect ratio
      const aspectRatio = width / height;

      if (width > height) {
        if (width > maxSize) {
          width = maxSize;
          height = Math.round(width / aspectRatio);
        }
      } else {
        if (height > maxSize) {
          height = maxSize;
          width = Math.round(height * aspectRatio);
        }
      }

      // Ensure the new dimensions are not smaller than minimum
      width = Math.max(width, minSize);
      height = Math.max(height, minSize);


      canvas.width = width;
      canvas.height = height;
      
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }
      
      // Draw image with white background (for transparent PNGs)
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, width, height);

      // Use better image smoothing
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      // Draw the image
      ctx.drawImage(img, 0, 0, width, height);
      
      // Convert to blob with quality compression
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to convert canvas to blob'));
          }
        },
        'image/jpeg',
        0.9 // Increased quality slightly
      );
    };
    
    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject(new Error('Failed to load image. Please try another file.'));
    };

    img.src = objectUrl;
  });
};
