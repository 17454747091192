import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MetallicRings } from './MetallicRings';
import { Button } from './ui/button';
import { Play, Pause, SkipBack, SkipForward } from 'lucide-react';

interface RingStep {
  id: string;
  title: string;
  subheadline: string;
  ownership?: string;
  members?: string;
  subscription?: string;
  details: string[];
}

const RING_STEPS: RingStep[] = [
  {
    id: 'founder',
    title: "Founder's Circle",
    subheadline: 'One Visionary Founder + AI',
    details: [
      'Launching the future solo, no large team needed',
      'Guided by AI to design, build, and grow from scratch',
      'Sets the foundation before community layers join'
    ]
  },
  {
    id: 'gold',
    title: 'Core Ring',
    subheadline: 'The First 1,000 Founders',
    ownership: '15%',
    members: '1,000',
    subscription: '$100',
    details: [
      'Direct input on product direction, largest influence',
      'Early access to the full blueprint, top-tier involvement'
    ]
  },
  {
    id: 'silver',
    title: 'Growth Ring',
    subheadline: 'Expand to 10,000 Builders',
    ownership: '15%',
    members: '10,000',
    subscription: '$20',
    details: [
      'Broadening the community, fueling rapid evolution',
      'Strong ownership stake with proven early traction'
    ]
  },
  {
    id: 'bronze',
    title: 'Scale Ring',
    subheadline: 'Welcoming 100,000 Innovators',
    ownership: '15%',
    members: '100,000',
    subscription: '$5',
    details: [
      'Stable growth phase with widespread participation',
      'Benefit from matured systems and refined strategies'
    ]
  },
  {
    id: 'network',
    title: 'Network Ring',
    subheadline: '1,000,000 Global Participants',
    ownership: '20%',
    members: '1M',
    subscription: 'Minimal or free',
    details: [
      'Massive user-driven expansion and cultural reach',
      'Viral network effects, a diverse, global user base'
    ]
  },
  {
    id: 'mass',
    title: 'Mass Ring',
    subheadline: '10,000,000+ Universal Adopters',
    ownership: '20%',
    members: '10M',
    subscription: 'Free',
    details: [
      'Unstoppable brand awareness and community evangelism',
      'Establishing a global standard for user-owned platforms'
    ]
  }
];

export const AnimatedRingsExplanation: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [visibleRings, setVisibleRings] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
        
        if (entry.isIntersecting) {
          setIsPlaying(true);
          setVisibleRings(['founder']);
        } else {
          setIsPlaying(false);
        }
      },
      {
        threshold: 0.3
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isPlaying && isInView && currentStep < RING_STEPS.length - 1) {
      timer = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + (50 / 5000) * 100;
          if (newProgress >= 100) {
            nextStep();
            return 0;
          }
          return newProgress;
        });
      }, 50);
    }
    return () => clearInterval(timer);
  }, [currentStep, isPlaying, isInView]);

  const nextStep = () => {
    if (currentStep < RING_STEPS.length - 1) {
      setCurrentStep(prev => prev + 1);
      setVisibleRings(prev => [...prev, RING_STEPS[currentStep + 1].id]);
      setProgress(0);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
      setVisibleRings(prev => prev.slice(0, -1));
      setProgress(0);
    }
  };

  const resetAnimation = () => {
    setCurrentStep(0);
    setVisibleRings(['founder']);
    setIsPlaying(true);
    setProgress(0);
  };

  const handleStepChange = (newStep: number) => {
    if (newStep === currentStep) return;
    
    setIsTransitioning(true);
    setIsPlaying(false);
    
    // Calculate which rings should be visible based on the step
    const newRings = RING_STEPS.slice(0, newStep + 1).map(step => step.id);
    setVisibleRings(newRings);
    setCurrentStep(newStep);
    setProgress(0);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 200);
  };

  // Update keyboard controls to only work when in view
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Only handle keyboard events if the component is in view
      if (!isInView) return;
      
      // Prevent space bar from scrolling when component is in view
      if (e.key === ' ') {
        e.preventDefault();
        setIsPlaying(prev => !prev);
      } else if (e.key === 'ArrowLeft') {
        prevStep();
      } else if (e.key === 'ArrowRight') {
        nextStep();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentStep, isInView]); // Add isInView to dependencies

  return (
    <div ref={componentRef} className="flex flex-col md:flex-row gap-12 md:gap-12 items-center justify-center px-4">
      {/* Container for mobile layout */}
      <div className="w-full flex flex-col md:hidden"> {/* Mobile only */}
        {/* Rings with even smaller height */}
        <div className="relative" style={{ minHeight: '300px' }}> 
          <div className="relative h-full flex flex-col">
            <div className="flex-grow flex items-center justify-center">
              <MetallicRings 
                className="mx-auto scale-70"
                forceShowMass={true} 
                visibleRings={visibleRings}
                currentStep={currentStep}
                setCurrentStep={handleStepChange}
              />
            </div>
          </div>
        </div>

        {/* Controls - now positioned below rings */}
        <div className="flex justify-center -mt-4 mb-2"> {/* Negative margin to bring it closer to rings */}
          <div className="flex items-center justify-center space-x-2 bg-black/50 backdrop-blur-sm rounded-full p-1.5">
            <Button
              variant="ghost"
              size="sm"
              onClick={prevStep}
              disabled={currentStep === 0}
            >
              <SkipBack className="h-3 w-3" />
            </Button>
            
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsPlaying(!isPlaying)}
            >
              {isPlaying ? (
                <Pause className="h-3 w-3" />
              ) : (
                <Play className="h-3 w-3" />
              )}
            </Button>
            
            <Button
              variant="ghost"
              size="sm"
              onClick={nextStep}
              disabled={currentStep === RING_STEPS.length - 1}
            >
              <SkipForward className="h-3 w-3" />
            </Button>
          </div>
        </div>

        {/* Content for mobile - minimal spacing */}
        <div className="space-y-2">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ delay: 0.2 }}
              className="space-y-2 mt-4" // Minimal spacing between elements
            >
              <h3 className="text-2xl md:text-4xl text-center font-bold">{RING_STEPS[currentStep].title}</h3>
              <p className="text-base md:text-xl font-bold text-center text-gray-600 dark:text-gray-400">
                {RING_STEPS[currentStep].subheadline}
              </p>
              
              {/* Stats Grid - more compact on mobile */}
              {(RING_STEPS[currentStep].ownership || RING_STEPS[currentStep].members || RING_STEPS[currentStep].subscription) && (
                <div className="flex items-center justify-center grid-cols-3 gap-2 md:gap-4 py-3 md:py-4">
                  {RING_STEPS[currentStep].ownership && (
                    <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                      <p className="text-sm text-gray-500">Ownership</p>
                      <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].ownership}</p>
                    </div>
                  )}
                  {RING_STEPS[currentStep].members && (
                    <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                      <p className="text-sm text-gray-500">Members</p>
                      <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].members}</p>
                    </div>
                  )}
                  {RING_STEPS[currentStep].subscription && (
                    <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                      <p className="text-sm text-gray-500">Membership</p>
                      <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].subscription}</p>
                    </div>
                  )}
                </div>
              )}

              <ul className="space-y-1 md:space-y-3 text-left">
                {RING_STEPS[currentStep].details.map((detail, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.2 }}
                    className="flex items-start space-x-2 text-left"
                  >
                    <span className="w-1.5 h-1.5 mt-2 bg-gray-500 rounded-full flex-shrink-0" /> {/* Smaller bullets */}
                    <span className="text-gray-500 text-md md:text-xl">{detail}</span>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {/* Desktop layout - hidden on mobile */}
      <div className="hidden md:flex md:flex-row gap-12 w-full">
        {/* Original desktop layout */}
        <div className="w-1/2 relative" >
          <div className="relative flex flex-col">
            <div className="flex-grow flex items-center justify-center">
              <MetallicRings 
                className="mx-auto" 
                forceShowMass={true} 
                visibleRings={visibleRings}
                currentStep={currentStep}
                setCurrentStep={handleStepChange}
              />
            </div>
            
            <div className="absolute -bottom-20 left-0 right-0 flex justify-center pb-4">
              <div className="flex items-center justify-center space-x-4 bg-black/20 backdrop-blur-sm rounded-full p-4">
                <Button
                  variant="ghost"
                  size="icon"
                  rounded="full"
                  onClick={prevStep}
                  disabled={currentStep === 0}
                >
                  <SkipBack className="h-4 w-4" />
                </Button>
                
                <div className="relative">
                  <div
                    className="absolute inset-0 -m-1"
                    style={{
                      background: `conic-gradient(#333 ${progress}%, transparent ${progress}%)`,
                      borderRadius: '50%',
                    }}
                  />
                  <Button
                    variant="ghost"
                    size="icon"
                    rounded="full"
                    onClick={() => setIsPlaying(!isPlaying)}
                    className="relative z-10"
                  >
                    {isPlaying ? (
                      <Pause className="h-4 w-4" />
                    ) : (
                      <Play className="h-4 w-4" />
                    )}
                  </Button>
                </div>
                
                <Button
                  variant="ghost"
                  size="icon"
                  rounded="full"
                  onClick={nextStep}
                  disabled={currentStep === RING_STEPS.length - 1}
                >
                  <SkipForward className="h-4 w-4" />
                </Button>
                
                <Button
                  variant="ghost"
                  onClick={resetAnimation}
                  className="ml-4"
                >
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 flex items-center min-h-[500px]">
          <div className="space-y-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: 0.2 }}
                className="space-y-3 md:space-y-6"
              >
                <h3 className="text-3xl md:text-4xl text-left font-bold">{RING_STEPS[currentStep].title}</h3>
                <p className="text-lg md:text-xl font-bold text-left text-gray-600 dark:text-gray-400">
                  {RING_STEPS[currentStep].subheadline}
                </p>
                
                {/* Stats Grid */}
                {(RING_STEPS[currentStep].ownership || RING_STEPS[currentStep].members || RING_STEPS[currentStep].subscription) && (
                  <div className="flex flex-col md:grid md:grid-cols-3 gap-2 md:gap-4 py-2 md:py-4">
                    {RING_STEPS[currentStep].ownership && (
                      <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                        <p className="text-sm text-gray-500">Ownership</p>
                        <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].ownership}</p>
                      </div>
                    )}
                    {RING_STEPS[currentStep].members && (
                      <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                        <p className="text-sm text-gray-500">Members</p>
                        <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].members}</p>
                      </div>
                    )}
                    {RING_STEPS[currentStep].subscription && (
                      <div className="border-2 border-white/5 rounded-2xl p-4 space-y-2">
                        <p className="text-sm text-gray-500">Membership / month</p>
                        <p className="text-xl md:text-2xl font-bold">{RING_STEPS[currentStep].subscription}</p>
                      </div>
                    )}
                  </div>
                )}

                <ul className="space-y-2 md:space-y-3 text-left">
                  {RING_STEPS[currentStep].details.map((detail, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.2 }}
                      className="flex items-start space-x-2 text-left"
                    >
                      <span className="w-2 h-2 mt-2.5 bg-gray-500 rounded-full flex-shrink-0" />
                      <span className="text-gray-500 text-base md:text-xl">{detail}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}; 