import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../firebase';

interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

export const validateSocialMeta = async (imageUrl: string): Promise<ValidationResult> => {
  const errors: string[] = [];
  const platforms = ['og-image', 'linkedin', 'twitter', 'facebook'];

  for (const platform of platforms) {
    try {
      const platformUrl = imageUrl
        .replace('og-image.jpg', `${platform}.jpg`)
        .replace('/s/', '/u/');
      
      const response = await fetch(platformUrl, { method: 'HEAD' });
      if (!response.ok) {
        errors.push(`${platform} image not accessible`);
      }
    } catch (error) {
      errors.push(`${platform} image not found`);
    }
  }

  return {
    isValid: errors.length === 0,
    errors
  };
}; 