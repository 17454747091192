import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { cn } from '../../lib/utils';
import { useMediaQuery } from '../../hooks/use-media-query';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string; // Add description prop
  children: React.ReactNode;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  description, // Add description to props
  children,
  className
}) => {
  const isMobile = useMediaQuery("(max-width: 640px)");

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay 
          className={cn(
            "fixed inset-0 bg-black/50 z-50",
            "animate-in fade-in-0",
            isMobile && "duration-100"
          )} 
        />
        <Dialog.Content 
          className={cn(
            "fixed z-50 bg-background",
            "border border-border shadow-lg",
            "duration-200 data-[state=open]:animate-in",
            "data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
            "data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
            "data-[state=closed]:zoom-out-95",
            isMobile
              ? "modal-mobile"
              : [
                  "rounded-lg w-[90vw] max-w-md",
                  "top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
                  "p-6" // Add padding for desktop
                ].join(" "),
            className
          )}
        >
          <div className={cn(
            "modal-header",
            isMobile ? "px-4 pt-4" : "mb-4"
          )}>
            {title && (
              <>
                <Dialog.Title className="text-xl font-semibold">
                  {title}
                </Dialog.Title>
                {description && (
                  <Dialog.Description className="text-sm text-muted-foreground mt-1.5">
                    {description}
                  </Dialog.Description>
                )}
              </>
            )}
          </div>
          
          <div className={cn(
            "modal-body",
            isMobile ? "px-4" : "",
            "space-y-4" // Add consistent vertical spacing
          )}>
            {children}
          </div>

          <Dialog.Close 
            className={cn(
              "absolute",
              isMobile ? "top-4 right-4" : "top-4 right-4",
              "hover:opacity-100 opacity-70 transition-opacity"
            )}
          >
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export type { ModalProps };
