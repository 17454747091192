// src/components/Badge.tsx

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MdAccessTime, MdOutlineSmartToy, MdLink } from 'react-icons/md';
import './Badge.css';
import { useUserStore } from '../stores/userStore';
import { User, Task } from '../types';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card"
import { BadgeSVG } from './badge/BadgeSVG';
import { generateTaskColor } from '../utils/colorUtils';
import { RingSegments } from './badge/RingSegments';
import { MapPin, Globe } from 'lucide-react';
import { toast } from './ui/use-toast';
import { generateBadgeImage } from '../utils/badgeImageGenerator';
import { Button } from './ui/button';
import { ShareMenu } from './ShareMenu';

interface BadgeProps {
  layout: 'centered' | 'split';
  userId: string | null;
  showAIOnly: boolean;
  isEditable?: boolean;
  onAddTask?: () => void;
  onEditTask?: (task: Task) => void;
  onEditFirstTask?: () => void;
  user: User | null;
  onLayoutChange?: (layout: 'centered' | 'split') => void;
  children?: React.ReactNode;
  showUserInfo?: boolean;
  onOpenShareModal?: () => void;
  isDemo?: boolean;
  hideShareMenu?: boolean;
}

const Badge: React.FC<BadgeProps> = ({
  userId,
  showAIOnly,
  isEditable,
  onAddTask,
  onEditTask,
  user,
  layout = 'centered',
  onLayoutChange,
  showUserInfo = false,
  onOpenShareModal,
  isDemo = false,
  hideShareMenu = false,
}) => {
  const { fetchUserData, currentUser } = useUserStore();
  const displayUser = user || currentUser;

  const [hoveredTask, setHoveredTask] = useState<Task | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewBox, setViewBox] = useState("0 0 600 600");
  const [isMobile, setIsMobile] = useState(false);

  const rightPaneRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleOpenSettings = () => {
      window.location.href = '/settings';
    };

    window.addEventListener('openUserSettings', handleOpenSettings);
    return () => window.removeEventListener('openUserSettings', handleOpenSettings);
  }, []);

  const handleTaskHover = (task: Task, index: number) => {
    setHoveredTask(task);
  };

  const handleTaskClick = (task: Task) => {
    if (!currentUser) {
      toast({
        title: "Sign up required",
        description: "Please sign up to view and share insights with the community.",
      });
      return;
    }
    onEditTask && onEditTask(task);
  };

  const handleTaskLeave = () => {
    setHoveredTask(null);
  };

  const getAnimationStyle = (task: Task) => {
    // Implement your animation style logic here
    return {};
  };

  const formatUrl = (url: string) => {
    if (!url) return '';
    return url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `https://${url}`;
  };

  const getWebsiteDisplayName = (url: string) => {
    try {
      const urlObj = new URL(formatUrl(url));
      return urlObj.hostname.replace('www.', '');
    } catch (e) {
      return url;
    }
  };

  const renderBadgeSvg = () => (
    <div className="badge-svg-container">
      <BadgeSVG
        user={displayUser}
        tasks={displayUser?.tasks || []}
        showAIOnly={showAIOnly}
        isLoaded={isLoaded}
        handleTaskHover={handleTaskHover}
        handleTaskClick={handleTaskClick}
        handleTaskLeave={handleTaskLeave}
        getAnimationStyle={getAnimationStyle}
        selectedTask={selectedTask}
        hoveredTask={hoveredTask}
        isEditable={!!isEditable}
        onOpenShareModal={onOpenShareModal}
        onEditTask={onEditTask}
        onAddTask={onAddTask}
        viewBox={viewBox}
        isMobile={isMobile}
        showUserInfo={showUserInfo}
      />
    </div>
  );

  const renderContent = () => (
    <div className="content">
      {displayUser && Array.isArray(displayUser.tasks) && displayUser.tasks.map((task, index) => (
        <div
          key={task.taskId}
          className={`section ${(hoveredTask === task || selectedTask === task) ? 'active-section' : ''}`}
          onMouseEnter={() => handleTaskHover(task, index)}
          onMouseLeave={handleTaskLeave}
          onClick={() => handleTaskClick(task)}
        >
          <div className="section-header">
            <div
              className="section-marker"
              style={{ backgroundColor: task.aiToolsUsed?.[0]?.aiToolName || '#ccc' }}
            />
            <h2>{task.taskName}</h2>
          </div>
          <div className="section-content">
            <p><MdAccessTime /> Total Time: {task.totalTime || 0}h</p>
            <p><MdOutlineSmartToy /> AI Assisted: {task.aiAssistedPercentage || 0}%</p>
            <p><MdLink /> <a href="#" target="_blank" rel="noopener noreferrer">{task.aiToolsUsed?.[0]?.aiToolName || 'N/A'}</a></p>
          </div>
        </div>
      ))}
    </div>
  );

  const renderRingSegments = () => (
    <RingSegments
      tasks={displayUser?.tasks.map(task => ({
        ...task,
        color: generateTaskColor(task.taskId, task.taskName)
      })) || []}
      showAIOnly={showAIOnly}
      isLoaded={isLoaded}
      handleTaskHover={handleTaskHover}
      handleTaskClick={handleTaskClick}
      handleTaskLeave={handleTaskLeave}
      getAnimationStyle={getAnimationStyle}
      selectedTask={selectedTask}
      hoveredTask={hoveredTask}
      isEditable={!isDemo && !!isEditable}  // Only show edit controls if not demo and isEditable is true
      onEditTask={onEditTask}
      onAddTask={onAddTask}
    />
  );

  const handleShare = async (platform: 'linkedin' | 'twitter' | 'facebook' | 'default') => {
    try {
      const badgeElement = document.querySelector('.badge-svg-container');
      if (!badgeElement || !user?.id) {
        throw new Error('Badge element or user ID not found');
      }

      toast({
        title: "Generating image",
        description: "Please wait while we prepare your badge...",
      });

      // Instead of creating a new React element, clone and modify the existing DOM element
      const shareableBadge = badgeElement.cloneNode(true) as HTMLElement;
      
      // Remove any editable elements
      const editableElements = shareableBadge.querySelectorAll('.add-task-ring');
      editableElements.forEach(el => el.remove());

      const imageUrl = await generateBadgeImage(
        shareableBadge,
        user.id
      );

      // Clean up
      shareableBadge.remove();

      if (platform !== 'default') {
        // Use /s/ URL for social sharing
        const socialShareUrl = `${window.location.origin}/s/${user.id}`;
        
        const shareLinks = {
          linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(socialShareUrl)}`,
          twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(socialShareUrl)}`,
          facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(socialShareUrl)}`,
        };

        window.open(shareLinks[platform], 'share');
      } else {
        // Download the image
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'ai-badge.jpg';
        link.style.display = 'none';
        document.body.appendChild(link);
        
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      }

      onOpenShareModal?.();
      
      toast({
        title: "Success",
        description: platform === 'default' 
          ? "Badge downloaded successfully!" 
          : "Badge ready to share!",
      });
    } catch (error) {
      console.error('Share error:', error);
      toast({
        title: "Error",
        description: "Failed to generate shareable image",
        variant: "destructive",
      });
    }
  };

  const getSocialShareUrl = (platform: string, url: string) => {
    const encodedUrl = encodeURIComponent(url);
    switch (platform) {
      case 'linkedin':
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?url=${encodedUrl}`;
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
      default:
        return '';
    }
  };

  const renderShareMenu = () => {
    if (isDemo || hideShareMenu) return null;
    
    return (
      <div className="mt-4">
        <ShareMenu 
          onOpenShareModal={onOpenShareModal || (() => {})}
          disabled={!user || !currentUser}
        />
      </div>
    );
  };

  // Add null check for user
  if (!user) {
    return null;
  }

  return (
    <div className={`badge-container ${layout === 'split' ? 'flex' : ''} p-0`}>
      {layout === 'split' && !isMobile ? (
        <div className="split-layout">
          <div className="left-pane">
            {renderBadgeSvg()}
          </div>
          <div className="right-pane" ref={rightPaneRef}>
            {renderContent()}
          </div>
        </div>
      ) : (
        renderBadgeSvg()
      )}
      {renderShareMenu()}
    </div>
  );
};

export default Badge;
