// src/components/ProfessionSelect.tsx

import React, { useState, useEffect, useMemo } from 'react';
import { useProfessionStore } from '../stores/professionStore';
import { ProfessionBasic } from '../types';
import { Button } from "./ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"
import { cn } from "../lib/utils"
import { useUserStore } from '../stores/userStore';
import { ProfessionChangeModal } from './ProfessionChangeModal';
import { useToast } from './ui/use-toast';
import { useVirtualizer } from '@tanstack/react-virtual';

interface ProfessionSelectProps {
  onSelect: (profession: ProfessionBasic) => void;
  selectedProfession: ProfessionBasic | null;
  isInitialSelection?: boolean;
}

export const ProfessionSelect: React.FC<ProfessionSelectProps> = ({ 
  onSelect, 
  selectedProfession,
  isInitialSelection = false
}) => {
  const [search, setSearch] = useState('');
  const { professionList, isLoading: isProfessionsLoading, fetchProfessionList } = useProfessionStore();
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [professionToChange, setProfessionToChange] = useState<ProfessionBasic | null>(null);
  const { currentUser, changeProfession } = useUserStore();
  const [isOpening, setIsOpening] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    fetchProfessionList();
  }, [fetchProfessionList]);

  // Memoized filtered professions
  const filteredProfessions = useMemo(() => {
    if (!search.trim()) return professionList;
    const searchLower = search.toLowerCase();
    return professionList.filter(profession => 
      profession.name.toLowerCase().includes(searchLower)
    );
  }, [search, professionList]);

  // Virtual list setup
  const parentRef = React.useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: filteredProfessions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 10
  });

  const handleProfessionSelect = async (profession: ProfessionBasic) => {
    try {
      if (currentUser && !isInitialSelection) {
        setProfessionToChange(profession);
        setIsModalOpen(true);
      } else {
        onSelect(profession);
      }
      setOpen(false);
    } catch (error) {
      console.error('Error selecting profession:', error);
      toast({
        title: "Error",
        description: "Failed to select profession. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleConfirmChange = async () => {
    if (!professionToChange) return;

    try {
      setIsUpdating(true);
      const result = await changeProfession(
        professionToChange.id,
        professionToChange.name
      );

      if (result.success) {
        onSelect(professionToChange);
        await useUserStore.getState().fetchUserData(currentUser?.id || '');
        
        toast({
          title: "Success",
          description: "Your profession has been updated successfully.",
        });
      } else {
        toast({
          title: "Update failed",
          description: result.message,
          variant: "destructive",
        });
      }
    } catch (error) {
      console.error('Error confirming profession change:', error);
      toast({
        title: "Error",
        description: "Failed to update profession. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsUpdating(false);
      setIsModalOpen(false);
      setProfessionToChange(null);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setIsOpening(true);
      // Show loading state for a brief moment while the popover prepares to open
      setTimeout(() => {
        setOpen(true);
        setIsOpening(false);
      }, 100);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <div className="space-y-4">
        <Popover open={open} onOpenChange={handleOpenChange}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className={cn(
                "w-full text-xl h-16 px-4 justify-between relative",
                !selectedProfession && "text-muted-foreground",
                isOpening && "animate-pulse"
              )}
              disabled={isOpening}
            >
              {selectedProfession?.name || (isProfessionsLoading || isOpening ? "Loading professions..." : "Select profession")}
              {isOpening ? (
                <div className="ml-2 h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
              ) : (
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent 
            className="w-[var(--radix-popover-trigger-width)] p-4 border-gray-800" 
            align="start"
            sideOffset={4}
          >
            <Command>
              <CommandInput
                placeholder={isProfessionsLoading ? "Loading..." : "Search for a profession..."}
                className="h-12 text-xl"
                value={search}
                onValueChange={setSearch}
                disabled={isProfessionsLoading}
              />
              <CommandList className="max-h-[300px]">
                {isProfessionsLoading ? (
                  <CommandEmpty>Loading professions...</CommandEmpty>
                ) : (
                  <>
                    <CommandEmpty>No profession found.</CommandEmpty>
                    <CommandGroup>
                      {filteredProfessions.map((profession) => (
                        <CommandItem
                          key={profession.id}
                          value={profession.name}
                          className="text-md"
                          onSelect={() => handleProfessionSelect(profession)}
                        >
                          {profession.name}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              profession.id === selectedProfession?.id
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        
        {!currentUser?.isPremium && selectedProfession && (
          <p className="text-sm text-gray-500">
            You can update your profession once every 30 days. 
            <Button 
              variant="link" 
              className="text-primary px-1"
              onClick={() => {/* Add upgrade to premium handler */}}
            >
              Premium Membership coming soon
            </Button>
          </p>
        )}
      </div>

      <ProfessionChangeModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setProfessionToChange(null);
        }}
        onConfirm={handleConfirmChange}
        currentProfession={selectedProfession?.name || ''}
        newProfession={professionToChange?.name || ''}
        isPremium={currentUser?.isPremium}
      />
    </>
  );
};
