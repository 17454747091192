// src/components/badge/CenterInfo.tsx

import React, { useState, useEffect, useMemo } from 'react';
import { useUserStore } from '../../stores/userStore';
import { User } from '../../types';
import { calculateUserScoreAndLevel } from '../../utils/scoreCalculation';
import { DEFAULT_PROFILE_PICTURE } from '../../constants';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogTitle,
} from "../ui/dialog"
import { MapPin, Globe } from 'lucide-react';
import { useThemeStore } from '../../stores/themeStore';
import { FIREBASE_STORAGE_URLS } from '../../constants';
import { Button } from "../ui/button";
import { Camera } from 'lucide-react';
import { ProfilePictureUpload } from '../ProfilePictureUpload';

interface CenterInfoProps {
  user: User | null;
  showUserInfo?: boolean;
}

export const CenterInfo: React.FC<CenterInfoProps> = ({ user, showUserInfo = false }) => {
  const { isDarkMode } = useThemeStore();
  const [currentImage, setCurrentImage] = useState(
    isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light
  );

  const logoSrc = useMemo(() => {
    return isDarkMode ? "/logo_dark.svg" : "/logo_light.svg";
  }, [isDarkMode]);

  useEffect(() => {
    if (!user) return;

    // If it's a demo user (has profilePictureURL starting with '/demo/')
    if (user.profilePicture?.mainUrl?.startsWith('/demo/')) {
      setCurrentImage(user.profilePicture?.mainUrl);
      return;
    }

    // For regular users, use Firebase storage path
    if (user.id) {
      const customUrl = FIREBASE_STORAGE_URLS.profilePicture.getMainUrl(user.id);
      const tempImg = new Image();
      tempImg.onload = () => {
        setCurrentImage(customUrl);
      };
      tempImg.src = customUrl;
    }
  }, [user?.id, user?.profilePicture?.mainUrl, isDarkMode]);

  if (!user) return null;

  const score = user.points ?? 0;
  const level = user.aiLevel ?? 0;
  const formattedScore = score.toLocaleString();

  const formatUrl = (url: string) => {
    if (!url) return '';
    return url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `https://${url}`;
  };

  const getWebsiteDisplayName = (url: string) => {
    try {
      const urlObj = new URL(formatUrl(url));
      return urlObj.hostname.replace('www.', '');
    } catch (e) {
      return url;
    }
  };

  const isDefaultAvatar = !user?.profilePicture?.mainUrl;

  return (
    <g>
      {/* Curved text paths */}
      <defs>
        <path id="upperArc" d="M -160 0 A 160 160 0 0 1 160 0" />
        <path id="professionArc" d="M -135 0 A 135 135 0 0 1 135 0" />
        <path id="lowerArc" d="M -170 0 A 170 170 0 0 0 170 0" />
        <path id="infoArc" d="M -170 10 A 170 170 0 0 0 170 10" />
      </defs>

      {/* User name on upper arc */}
      <text fill={isDarkMode ? "#FFFFFF" : "#000000"} fontSize="26" letterSpacing="-1" fontWeight="bold">
        <textPath href="#upperArc" startOffset="50%" textAnchor="middle">
          {user.name}
        </textPath>
      </text>

      {/* User profession */}
      <text fill="#666666" fontSize="16">
        <textPath href="#professionArc" startOffset="50%" textAnchor="middle">
          {user.professionName}
        </textPath>
      </text>

      {/* Profile picture with conditional dialog */}
      <foreignObject x="-100" y="-100" width="200" height="200">
        <Dialog>
          <DialogTrigger asChild>
            <div className="w-full h-full cursor-pointer">
              <img
                src={currentImage}
                alt={user.name}
                className="w-full h-full rounded-full object-cover"
              />
            </div>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px]">
            <DialogTitle>Profile Information</DialogTitle>
            <div className="space-y-4">
              <div className="flex flex-col items-center text-center">
                {showUserInfo ? (
                  <ProfilePictureUpload 
                    size="md"
                    showEditButton={true}
                  />
                ) : (
                  <img
                    src={currentImage}
                    alt={user.name}
                    className="w-20 h-20 rounded-full object-cover"
                  />
                )}
                <h4 className="font-semibold text-lg mt-4">{user.name}</h4>
                <p className="text-sm text-muted-foreground">{user.professionName}</p>
              </div>

              {user.bio && (
                <p className="text-sm text-center border-t pt-2">
                  {user.bio}
                </p>
              )}

              <div className="space-y-2 border-t pt-2">
                {user.location && (
                  <div className="flex items-center justify-center text-sm text-muted-foreground">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>{user.location}</span>
                  </div>
                )}
                
                {user.website && (
                  <div className="flex flex-col items-center text-sm">
                    <a 
                      href={formatUrl(user.website)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-primary hover:underline"
                    >
                      <Globe className="w-4 h-4 mr-1" />
                      {getWebsiteDisplayName(user.website)}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </foreignObject>

      <image
        href={logoSrc}
        x={126}
        y={-25}
        width={50}
        height={50}
      />

      
      {/* Score information with safety checks */}
      <text>
        <textPath href="#infoArc" startOffset="40%" textAnchor="end">
          <tspan fill="#666666" fontSize="14" dy="-4">Score</tspan>
        </textPath>
        <textPath href="#infoArc" startOffset="50%" textAnchor="middle">
          <tspan fill={isDarkMode ? "#FFFFFF" : "#000000"} fontSize="30" fontWeight="bold" dy="5">
            {formattedScore}
          </tspan>
        </textPath>
        <textPath href="#infoArc" startOffset="60%" textAnchor="start">
          <tspan fill="#666666" fontSize="14" dy="-4">Level {level}</tspan>
        </textPath>
      </text>
    </g>
  );
};
