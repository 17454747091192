// TimeSlider.tsx

import React, { useState, useEffect } from 'react';
import { Slider } from "./ui/slider";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { PlusIcon, MinusIcon } from "@radix-ui/react-icons";
import './TimeSlider.css';
import { cn } from "../lib/utils";

const WORK_DAYS_PER_MONTH = 22;
const TOTAL_WORK_MINUTES_PER_MONTH = 8 * WORK_DAYS_PER_MONTH * 60;

interface TimeSliderProps {
  frequency: number;
  duration: number; // in minutes
  aiPercentage: number;
  onTimeChange: (frequency: number, duration: number, aiPercentage: number) => void;
  color?: string;
  disableAISlider?: boolean;
}

export const TimeSlider: React.FC<TimeSliderProps> = ({
  frequency,
  duration,
  aiPercentage,
  onTimeChange,
  color,
  disableAISlider = false
}) => {
  const [hours, setHours] = useState(Math.floor(duration / 60));
  const [minutes, setMinutes] = useState(duration % 60);

  // Update hours and minutes when duration prop changes
  useEffect(() => {
    setHours(Math.floor(duration / 60));
    setMinutes(duration % 60);
  }, [duration]);

  const handleFrequencyChange = (newFrequency: number) => {
    onTimeChange(Math.max(1, Math.min(300, newFrequency)), duration, aiPercentage);
  };

  const handleFrequencyStep = (increment: boolean) => {
    const newFrequency = frequency + (increment ? 1 : -1);
    handleFrequencyChange(newFrequency);
  };

  const handleTimeChange = (newHours: number, newMinutes: number) => {
    const newDuration = Math.max(0, newHours * 60 + newMinutes);
    setHours(newHours);
    setMinutes(newMinutes);
    onTimeChange(frequency, newDuration, aiPercentage);
  };

  const handleDurationStep = (increment: boolean) => {
    let newDuration = duration;

    if (increment) {
      if (duration < 10) {
        newDuration = Math.min(10, duration + 2);
      } else if (duration < 30) {
        newDuration = Math.min(30, duration + 5);
      } else if (duration < 180) {
        newDuration = Math.min(180, duration + 15);
      } else if (duration < 360) {
        newDuration = Math.min(360, duration + 30);
      } else {
        newDuration = duration + 60;
      }
    } else {
      if (duration <= 10) {
        newDuration = Math.max(2, duration - 2);
      } else if (duration <= 30) {
        newDuration = Math.max(10, duration - 5);
      } else if (duration <= 180) {
        newDuration = Math.max(30, duration - 15);
      } else if (duration <= 360) {
        newDuration = Math.max(180, duration - 30);
      } else {
        newDuration = Math.max(360, duration - 60);
      }
    }

    const newHours = Math.floor(newDuration / 60);
    const newMinutes = newDuration % 60;
    handleTimeChange(newHours, newMinutes);
  };

  const handleAiPercentageChange = (value: number | number[]) => {
    const percentage = Array.isArray(value) ? value[0] : value;
    onTimeChange(frequency, duration, percentage);
  };

  const formatTime = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes}min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}h`;
  };

  const totalMonthlyMinutes = Math.min(duration * frequency, TOTAL_WORK_MINUTES_PER_MONTH);
  const aiAssistedMonthlyMinutes = Math.round(totalMonthlyMinutes * aiPercentage / 100);
  const aiSavedPerTask = Math.round((aiPercentage / 100) * duration);

  const handleDurationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    if (!isNaN(hours) && !isNaN(minutes)) {
      handleTimeChange(hours, minutes);
    }
  };

  // Add a separate format function for the input
  const formatInputTime = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="space-y-6">
      <div className="space-y-0">
        <Label className="text-center text-muted-foreground block">Task Duration</Label>
        <div className="flex items-center justify-center space-x-4">
          <Button
            onClick={() => handleDurationStep(false)}
            type="button"
            variant="outline"
            size="lg"
            className="h-12 w-12 mb-4 rounded-full p-0"
          >
            <MinusIcon className="h-6 w-6" />
          </Button>
          <div className="text-center">
            <Input
              type="text"
              value={formatInputTime(duration)}
              onChange={handleDurationInputChange}
              className="text-3xl h-8 p-1 font-bold w-24 text-center border-none"
            />
            <div className="text-sm text-muted-foreground">Hours : Minutes</div>
          </div>
          <Button
            onClick={() => handleDurationStep(true)}
            type="button"
            variant="outline"
            size="lg"
            className="h-12 w-12 mb-4 rounded-full p-0"
          >
            <PlusIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <div className="space-y-0">
        <Label className="text-center text-muted-foreground block">Repeated</Label>
        <div className="flex items-center justify-center space-x-4">
          <Button 
            onClick={() => handleFrequencyStep(false)} 
            type="button" 
            variant="outline" 
            size="lg"
            className="h-12 w-12 mb-4 rounded-full p-0"
          >
            <MinusIcon className="h-6 w-6" />
          </Button>
          <div className="text-center">
            <Input
              type="number"
              min={1}
              max={300}
              value={frequency}
              onChange={(e) => handleFrequencyChange(parseInt(e.target.value) || 1)}
              className="text-3xl h-9 p-1 font-bold w-24 text-center border-none no-spinner"
            />
            <div className="text-sm text-muted-foreground">per month</div>
          </div>
          <Button 
            onClick={() => handleFrequencyStep(true)} 
            type="button" 
            variant="outline" 
            size="lg"
            className="h-12 w-12 mb-4 rounded-full p-0"
          >
            <PlusIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <Label>
            How much time does AI save you?
          </Label>
          <span className={cn(
            "text-sm",
            disableAISlider ? "text-muted-foreground" : ""
          )}>
            {disableAISlider ? (
              "Select AI tools first"
            ) : (
              `${aiPercentage}% faster with AI`
            )}
          </span>
        </div>
        <Slider
          value={[aiPercentage]}
          max={100}
          step={5}
          className={cn(
            disableAISlider ? "opacity-50 cursor-not-allowed" : "",
            "cursor-pointer"
          )}
          onValueChange={handleAiPercentageChange}
          disabled={disableAISlider}
          style={{
            "--slider-color": color || "var(--primary)",
          } as React.CSSProperties}
        />
      </div>
    </div>
  );
};
