// src/components/EditForm.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useUserStore } from '../stores/userStore';
import { useProfessionStore, ProfessionTask } from '../stores/professionStore';
import { useAIToolStore, AITool, AIToolModel } from '../stores/aiToolStore';
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { Button } from "./ui/button"
import { Card, CardContent, CardHeader } from "./ui/card"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command"
import { CaretSortIcon, CheckIcon, Cross1Icon, DotsHorizontalIcon, Pencil1Icon, FileIcon } from "@radix-ui/react-icons"
import { cn } from "../lib/utils"
import { TimeSlider } from './TimeSlider';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./ui/dropdown-menu"
import { Modal } from './ui/modal';
import { useMediaQuery } from '../hooks/use-media-query';
import { generateTaskColor } from '../utils/colorUtils';
import { User, Task, UserAITool } from '../types';
import { calculateUserScoreAndLevel } from '../utils/scoreCalculation';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip"
import { ArrowLeft } from 'lucide-react';
import { toast } from './ui/use-toast';
const WORK_DAYS_PER_MONTH = 22;



interface EditFormProps {
  user: User | null;
  task: Task | null;
  onSave: (task: Task, profession: string) => Promise<void>;
  onCancel: () => void;
  onDelete?: (taskId: string) => void;
}

type SelectionItem = ProfessionTask | AITool;

export const EditForm: React.FC<EditFormProps> = ({ user, task, onSave, onCancel, onDelete }) => {
  const { detailedProfessions, fetchProfessionDetails, getTasksForJobTitle } = useProfessionStore();
  const [formData, setFormData] = useState<Task>(() => initializeFormData(task));
  const [filteredTasks, setFilteredTasks] = useState<ProfessionTask[]>([]);
  const [isNewTask, setIsNewTask] = useState(!task);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { aiTools, fetchAITools } = useAIToolStore();
  const [selectedAITools, setSelectedAITools] = useState<UserAITool[]>([]);
  const [filteredAITools, setFilteredAITools] = useState<AITool[]>([]);
  const aiToolButtonRef = useRef<HTMLButtonElement>(null);
  const [aiToolPopoverWidth, setAIToolPopoverWidth] = useState(0);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isAIToolModalOpen, setIsAIToolModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 640px)");
  const timeSliderRef = useRef<HTMLDivElement>(null);
  const { updateUserTask, addUserTask, deleteUserTask } = useUserStore();
  const [isTaskPopoverOpen, setIsTaskPopoverOpen] = useState(false);
  const [isAIToolPopoverOpen, setIsAIToolPopoverOpen] = useState(false);
  const [taskSearchValue, setTaskSearchValue] = useState('');
  const [aiToolSearchValue, setAIToolSearchValue] = useState('');
  const [showInsightForm, setShowInsightForm] = useState(false);
  const [savedTask, setSavedTask] = useState<Task | null>(null);
  const [savedAITool, setSavedAITool] = useState<UserAITool | null>(null);

  const ControlledCommandInput = React.memo(
    React.forwardRef<HTMLInputElement, React.ComponentPropsWithoutRef<typeof CommandInput>>(
      (props, ref) => {
        
        return (
          <CommandInput
            ref={ref}
            {...props}
            // Prevent selection on mount
            autoFocus={false}
            // Handle selection manually
            onSelect={(e) => {
              e.preventDefault();
              const input = e.target as HTMLInputElement;
              input.setSelectionRange(input.value.length, input.value.length);
            }}
            // Prevent default key behaviors
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                e.stopPropagation();
              }
            }}
            // Ensure cursor is at the end when focused
            onFocus={(e) => {
              const input = e.target as HTMLInputElement;
              requestAnimationFrame(() => {
                input.setSelectionRange(input.value.length, input.value.length);
              });
            }}
          />
        );
      }
    ),
    (prevProps, nextProps) => prevProps.value === nextProps.value
  );

  ControlledCommandInput.displayName = 'ControlledCommandInput';

  // Use this function to initialize or update form data
  function initializeFormData(task: Task | null): Task {
    const now = new Date().toISOString();
    
    if (task) {
      return {
        taskId: task.taskId,
        taskName: task.taskName,
        frequency: task.frequency || WORK_DAYS_PER_MONTH,
        totalTime: task.totalTime || 60,
        aiAssistedTime: task.aiAssistedTime || 0,
        aiAssistedPercentage: task.aiAssistedTime ? Math.round((task.aiAssistedTime / task.totalTime) * 100 * 100) / 100 : 0,
        color: task.color || generateTaskColor(task.taskId, task.taskName),
        aiToolsUsed: task.aiToolsUsed.map(tool => ({
          aiToolId: tool.aiToolId,
          aiToolName: tool.aiToolName,
          aiToolLink: tool.aiToolLink,
          lastUpdated: tool.lastUpdated
        })),
        createdAt: task.createdAt || now,
        updatedAt: now
      };
    } else {
      return {
        taskId: generateUniqueId(),
        taskName: '',
        frequency: WORK_DAYS_PER_MONTH,
        totalTime: 60,
        aiAssistedTime: 0,
        aiAssistedPercentage: 0,
        color: '', // Will be set when task name is chosen
        aiToolsUsed: [],
        createdAt: now,
        updatedAt: now
      };
    }
  }

  useEffect(() => {
    setFormData(initializeFormData(task));
    setIsNewTask(!task);
    if (task && task.aiToolsUsed) {
      const userAITools: UserAITool[] = task.aiToolsUsed.map(tool => ({
        ...tool,
        lastUpdated: tool.lastUpdated || ''
      }));
      setSelectedAITools(userAITools);
    } else {
      setSelectedAITools([]);
    }
  }, [task]);

  useEffect(() => {
    if (user?.professionId) {
      // Extract titleSOC from the job title ID (e.g., "11-1011.00" from "11-1011.00.01")
      const titleSOC = user.professionId.split('.').slice(0, -1).join('.');
      fetchProfessionDetails(titleSOC);
    }
  }, [user?.professionId, fetchProfessionDetails]);

  useEffect(() => {
    if (user?.professionId && isNewTask) {
      
      const parts = user.professionId.split('.');
      const titleSOC = `${parts[0]}.${parts[1]}`;
      const jobTitleId = user.professionId;
      
      const profession = detailedProfessions[titleSOC];
      
      if (profession) {
        const commonTasks = profession.commonTasks || [];
        const jobTitleTasks = profession.jobTitles[jobTitleId]?.tasks || [];
        
        // Get the list of already selected task IDs
        const existingTaskIds = new Set(user.tasks?.map(task => task.taskId) || []);
        
        const allTasks = [
          ...commonTasks.map(task => ({
            ...task,
            type: 'common' as const
          })),
          ...jobTitleTasks.map(task => ({
            ...task,
            type: 'specific' as const
          }))
        // Filter out tasks that are already added by the user
        ].filter(task => !existingTaskIds.has(task.id));
        
        const filtered = taskSearchValue
          ? allTasks.filter(t => 
              t.description.toLowerCase().includes(taskSearchValue.toLowerCase())
            )
          : allTasks;
        
        
        setFilteredTasks(filtered);
      }
    }
  }, [taskSearchValue, user?.professionId, user?.tasks, isNewTask, detailedProfessions]);

  useEffect(() => {
    const savedData = sessionStorage.getItem('tempTaskData');
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      setPopoverWidth(buttonRef.current.offsetWidth);
    }
    if (aiToolButtonRef.current) {
      setAIToolPopoverWidth(aiToolButtonRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (user && user.professionId) {
      fetchAITools(user.professionId);
    }
  }, [user, fetchAITools]);

  useEffect(() => {
    if (user?.professionId) {
      const generalTools = aiTools['general'] || [];
      const filtered = aiToolSearchValue
        ? generalTools.filter(tool => 
            tool.aiToolName.toLowerCase().includes(aiToolSearchValue.toLowerCase()) ||
            (tool.hasModels && tool.models?.some(model => 
              `${tool.aiToolName} ${model.name}`.toLowerCase().includes(aiToolSearchValue.toLowerCase())
            ))
          )
        : generalTools;
      setFilteredAITools(filtered);
    }
  }, [aiToolSearchValue, aiTools, user?.professionId]);

  useEffect(() => {
    if (buttonRef.current) {
      setPopoverWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  useEffect(() => {
  }, [taskSearchValue, aiToolSearchValue, isTaskPopoverOpen, isAIToolPopoverOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: name === 'totalTime' || name === 'aiAssistedTime' ? parseFloat(value) : value };
    setFormData(newFormData);
    saveToSessionStorage(newFormData);
  };

  const handleTaskSelect = (selectedTask: ProfessionTask) => {
    setFormData(prevData => ({
      ...prevData,
      taskId: selectedTask.id,
      taskName: selectedTask.description,
      color: generateTaskColor(selectedTask.id, selectedTask.description)
    }));
    setSelectedAITools([]);
    setIsTaskPopoverOpen(false);
    setTaskSearchValue('');
    scrollToTimeSlider();
  };

  const handleTimeChange = (frequency: number, duration: number, aiPercentage: number) => {
    // Only allow AI percentage changes if AI tools are selected
    const allowAIPercentage = selectedAITools.length > 0;
    const finalAIPercentage = allowAIPercentage ? aiPercentage : 0;
    
    const aiAssistedTime = Math.round((finalAIPercentage / 100) * duration * 100) / 100;
    
    setFormData(prevData => ({
      ...prevData,
      frequency,                // Times per month
      totalTime: duration,      // Minutes per occurrence
      aiAssistedTime,          // AI-assisted minutes per occurrence
      aiAssistedPercentage: Math.round(finalAIPercentage * 100) / 100
    }));
  };

  const handleAIToolSelect = (selectedTool: AITool) => {
    setSelectedAITools(prev => {
      const isAlreadySelected = prev.some(tool => tool.aiToolId === selectedTool.aiToolId);
      if (isAlreadySelected) {
        return prev.filter(tool => tool.aiToolId !== selectedTool.aiToolId);
      } else {
        const userAITool: UserAITool = {
          aiToolId: selectedTool.aiToolId,
          aiToolName: selectedTool.aiToolName,
          aiToolLink: selectedTool.aiToolLink || '',
          lastUpdated: new Date().toISOString()
        };
        return [...prev, userAITool];
      }
    });
    
    setIsAIToolPopoverOpen(false);
    setIsAIToolModalOpen(false);
    setAIToolSearchValue('');
    scrollToTimeSlider();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Add validation check
    if (!formData.taskName) {
      toast({
        title: "Task Required",
        description: "Please select a task before saving.",
        variant: "destructive"
      });
      return;
    }
    
    try {
      const aiAssistedTime = Math.round((formData.aiAssistedPercentage / 100) * formData.totalTime * 100) / 100;
      
      const updatedFormData: Task = {
        taskId: formData.taskId,
        taskName: formData.taskName,
        frequency: formData.frequency,
        totalTime: formData.totalTime,
        aiAssistedTime,
        aiAssistedPercentage: formData.aiAssistedPercentage,
        color: formData.color,
        aiToolsUsed: selectedAITools.map(tool => ({
          aiToolId: tool.aiToolId,
          aiToolName: tool.aiToolName,
          aiToolLink: tool.aiToolLink,
          lastUpdated: tool.lastUpdated
        })),
        createdAt: formData.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
      
      await onSave(updatedFormData, user?.professionName || '');
      
      if (!user) {
        onCancel();
        return;
      }

      sessionStorage.removeItem('tempTaskData');
      onCancel();
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast({
        title: "Error",
        description: "Failed to save task. Please try again.",
        variant: "destructive"
      });
    }
  };

  const handleDelete = async (taskId: string) => {
    if (!onDelete) return;
    try {
      // Call onDelete directly - let EditableBadge handle the state updates
      await onDelete(taskId);
    } catch (error) {
      console.error('Error in handleDelete:', error);
      throw error;
    }
  };

  const saveToSessionStorage = (data: Task) => {
    sessionStorage.setItem('tempTaskData', JSON.stringify(data));
  };

  const handleCancel = () => {
    onCancel();
  };

  const renderTaskType = (task: ProfessionTask) => {
    return (
      <span className={cn(
        "ml-2 text-xs px-2 py-1 rounded-full",
        task.type === 'common' 
          ? "bg-blue-100 text-blue-800 dark:bg-blue-900/20 dark:text-blue-300"
          : "bg-purple-100 text-purple-800 dark:bg-purple-900/20 dark:text-purple-300"
      )}>
        {task.type === 'common' ? 'Common' : 'Specific'}
      </span>
    );
  };

  const renderSelectionContent = (
    items: SelectionItem[],
    onSelect: (item: SelectionItem) => void,
    selectedItems: string[],
    placeholder: string
  ) => {
    const isAISearch = placeholder.toLowerCase().includes('ai');
    const searchValue = isAISearch ? aiToolSearchValue : taskSearchValue;
    const setSearchValue = isAISearch ? setAIToolSearchValue : setTaskSearchValue;


    return (
      <Command shouldFilter={false}>
        <ControlledCommandInput 
          placeholder={`Search ${placeholder}...`} 
          value={searchValue}
          onValueChange={value => {
            // Prevent popover state changes during typing
            if (isAISearch) {
              setAIToolSearchValue(value);
            } else {
              setTaskSearchValue(value);
            }
          }}
          className="h-12 command-input"
        />
        <CommandList className="h-[calc(100dvh-121px)] sm:h-[300px] overflow-y-auto">
          <CommandEmpty>No {placeholder} found.</CommandEmpty>
          <CommandGroup>
            {items.map((item) => (
              <CommandItem
                key={isAITool(item) ? item.aiToolId : item.id}
                value={isAITool(item) ? item.aiToolName : item.description}
                onSelect={() => {
                  onSelect(item);
                  // Only close popover after actual selection
                  if (isAISearch) {
                    setIsAIToolPopoverOpen(false);
                  } else {
                    setIsTaskPopoverOpen(false);
                  }
                }}
                className="py-3 command-item"
              >
                <span>{isAITool(item) ? item.aiToolName : item.description}</span>
                <CheckIcon
                  className={cn(
                    "ml-auto h-5 w-5",
                    selectedItems.includes(isAITool(item) ? item.aiToolId : item.id)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    );
  };

  const isAITool = (item: SelectionItem): item is AITool => {
    return 'aiToolId' in item;
  };

  const TaskSelection = () => (
    <div className="space-y-2 relative">
      {isMobile ? (
        <>
          <Button
            onClick={() => setIsTaskModalOpen(true)}
            variant="outline"
            role="combobox"
            className="w-full justify-between text-base py-6"
          >
            {formData.taskName || "Search and Select Task"}
            <CaretSortIcon className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </Button>
          <Modal
            isOpen={isTaskModalOpen}
            onClose={() => setIsTaskModalOpen(false)}
            title="Search and Select Task"
          >
            {renderSelectionContent(filteredTasks, handleTaskSelect as (item: SelectionItem) => void, [formData.taskId], "task")}
          </Modal>
        </>
      ) : (
        <Popover 
          open={isTaskPopoverOpen} 
          onOpenChange={(open) => {
            // Only allow manual closing, not during typing
            if (!open) {
              setIsTaskPopoverOpen(false);
              setTaskSearchValue('');
            } else {
              setIsTaskPopoverOpen(true);
            }
          }}
        >
          <PopoverTrigger asChild>
            <Button
              ref={buttonRef}
              variant="outline"
              role="combobox"
              className="w-full justify-between text-base py-6"
            >
              {formData.taskName || "Search and Select Task"}
              <CaretSortIcon className="ml-2 h-5 w-5 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent 
            className="p-0 border-gray-800 z-50" 
            align="start"
            side="bottom"
            sideOffset={5}
            style={{ width: `${popoverWidth}px`, maxWidth: '100vw' }}
          >
            {renderSelectionContent(filteredTasks, handleTaskSelect as (item: SelectionItem) => void, [formData.taskId], "task")}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );

  const AIToolSelection = () => (
    <div className="space-y-2 relative">
      {isMobile ? (
        <>
          <Button
            onClick={() => setIsAIToolModalOpen(true)}
            variant="outline"
            role="combobox"
            className="w-full justify-between text-base py-6"
          >
            {selectedAITools.map(tool => tool.aiToolName).join(', ') || "Select AI Tools Used"}
            <CaretSortIcon className="ml-2 h-5 w-5 shrink-0 opacity-50" />
          </Button>
          <Modal
            isOpen={isAIToolModalOpen}
            onClose={() => setIsAIToolModalOpen(false)}
            title="Select AI Tools Used"
            description="Search and select AI tools to use with this task"
          >
            {renderAIToolContent(filteredAITools, selectedAITools)}
          </Modal>
        </>
      ) : (
        <Popover 
          open={isAIToolPopoverOpen} 
          onOpenChange={(open) => {
            if (!open) {
              setIsAIToolPopoverOpen(false);
              setAIToolSearchValue('');
            } else {
              setIsAIToolPopoverOpen(true);
            }
          }}
        >
          <PopoverTrigger asChild>
            <Button
              ref={aiToolButtonRef}
              variant="outline"
              role="combobox"
              className="w-full justify-between text-base py-6"
            >
              {selectedAITools.map(tool => tool.aiToolName).join(', ') || "Select AI tools"}
              <CaretSortIcon className="ml-2 h-5 w-5 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent 
            className="p-0 border-gray-800 z-50" 
            align="start"
            side="bottom"
            sideOffset={5}
            style={{ width: `${aiToolPopoverWidth}px`, maxWidth: '100vw' }}
          >
            {renderAIToolContent(filteredAITools, selectedAITools)}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );

  const scrollToTimeSlider = () => {
    if (isMobile && timeSliderRef.current) {
      setTimeout(() => {
        timeSliderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
        setTimeout(() => {
          const yOffset = -80; // Adjust this value based on your layout
          window.scrollBy({ top: yOffset, behavior: 'smooth' });
        }, 100);
      }, 500);
    }
  };

  const highlightTimeSlider = () => {
    if (timeSliderRef.current) {
      timeSliderRef.current.classList.add('highlight-effect');
      setTimeout(() => {
        timeSliderRef.current?.classList.remove('highlight-effect');
      }, 2000); // Remove highlight after 2 seconds
    }
  };

  const renderAIToolContent = (items: AITool[], selectedTools: UserAITool[]) => {
    return (
      <Command shouldFilter={false}>
        <ControlledCommandInput 
          placeholder="Search AI tools..."
          value={aiToolSearchValue}
          onValueChange={(value: string) => setAIToolSearchValue(value)}
          className="h-12 command-input"
        />
        <CommandList className="h-[calc(100dvh-121px)] sm:h-[300px] overflow-y-auto">
          <CommandEmpty>No AI tools found.</CommandEmpty>
          
          {/* Selected Tools Group */}
          {selectedTools.length > 0 && (
            <CommandGroup heading="Selected Tools">
              {selectedTools.map((tool) => (
                <CommandItem
                  key={tool.aiToolId}
                  value={tool.aiToolName}
                  onSelect={() => {
                    if (tool.modelId) {
                      const parentTool = items.find(t => t.aiToolId === tool.aiToolId.split('_')[0]);
                      const model = parentTool?.models?.find(m => m.id === tool.modelId);
                      if (parentTool && model) {
                        handleAIToolModelSelect(parentTool, model);
                      }
                    } else {
                      handleAIToolSelect(tool as AITool);
                    }
                  }}
                  className="py-3 command-item"
                >
                  <span>{tool.aiToolName}</span>
                  <CheckIcon className="ml-auto h-5 w-5 opacity-100" />
                </CommandItem>
              ))}
            </CommandGroup>
          )}

          {/* Available Tools Group */}
          <CommandGroup heading="Available Tools">
            {items
              .filter(tool => !selectedTools.some(selected => 
                selected.aiToolId === tool.aiToolId && !selected.modelId
              ))
              .map((tool) => (
                <React.Fragment key={tool.aiToolId}>
                  {/* Parent Tool */}
                  <CommandItem
                    value={tool.aiToolName}
                    onSelect={() => handleAIToolSelect(tool)}
                    className="py-3 command-item"
                  >
                    <span>
                      {tool.aiToolName}
                      {tool.hasModels && Array.isArray(tool.models) && tool.models.length > 0 && (
                        <span className="ml-2 text-xs text-muted-foreground">(All models)</span>
                      )}
                    </span>
                    <CheckIcon 
                      className={cn(
                        "ml-auto h-5 w-5",
                        selectedTools.some(t => t.aiToolId === tool.aiToolId && !t.modelId) 
                          ? "opacity-100" 
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                  
                  {/* Models */}
                  {tool.hasModels && Array.isArray(tool.models) && tool.models.length > 0 && (
                    <div className="pl-4 border-l-2 ml-4 border-muted">
                      {tool.models.map((model) => (
                        <CommandItem
                          key={model.id}
                          value={`${tool.aiToolName} ${model.name}`}
                          onSelect={() => handleAIToolModelSelect(tool, model)}
                          className="py-2 command-item text-sm text-muted-foreground"
                        >
                          <span>└ {model.name}</span>
                          <CheckIcon 
                            className={cn(
                              "ml-auto h-4 w-4",
                              selectedTools.some(t => t.modelId === model.id) 
                                ? "opacity-100" 
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
          </CommandGroup>
        </CommandList>
      </Command>
    );
  };

  const handleAIToolModelSelect = (tool: AITool, model: AIToolModel) => {
    const userAITool: UserAITool = {
      aiToolId: tool.aiToolId, // Store parent tool ID
      aiToolName: `${tool.aiToolName} ${model.name}`, // Combined name
      aiToolLink: tool.aiToolLink || '',
      lastUpdated: new Date().toISOString(),
      modelId: model.id, // Store model ID
      modelName: model.name // Store model name
    };

    setSelectedAITools(prev => {
      const isAlreadySelected = prev.some(t => t.modelId === model.id);
      if (isAlreadySelected) {
        return prev.filter(t => t.modelId !== model.id);
      }
      return [...prev, userAITool];
    });

    setIsAIToolPopoverOpen(false);
    setIsAIToolModalOpen(false);
    setAIToolSearchValue('');
    scrollToTimeSlider();
  };

  useEffect(() => {
    if (aiTools.general) {
    }
  }, [aiTools]);

  useEffect(() => {
    if (aiTools.general) {
    }
  }, [aiTools]);

  return (
    <Card className="w-full space-y-1  overflow-y-auto">
      <CardHeader className="pb-3 pt-2">
        <div className="flex items-center mb-0 ml-[-0.75rem]">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  className="rounded-full size-10" 
                  variant="ghost" 
                  size="icon" 
                  onClick={handleCancel}
                >
                  <ArrowLeft className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Back</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          
          {!isNewTask && onDelete && (
            <div className="ml-auto">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="rounded-full size-10">
                    <DotsHorizontalIcon className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem 
                    className="text-red-600 focus:text-red-600" 
                    onClick={() => onDelete(formData.taskId)}
                  >
                    Delete Task
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>

        {/* Task name below */}
        <div className="w-full">
          <h2 className="text-xl font-bold">
            {isNewTask 
              ? (formData.taskName || "Add Work Task Using AI")
              : `Customize: ${formData.taskName}`
            }
          </h2>
          {isNewTask && !formData.taskName && (
            <p className="text-muted-foreground text-sm mt-1">
              Search and select a common task from your profession where you use AI tools to assist your work. 
              This helps track your AI integration and see how others enhance similar tasks.
            </p>
          )}
        </div>
      </CardHeader>
      <CardContent>
        <form 
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className="space-y-8"
        >
          {isNewTask && !formData.taskName && <TaskSelection />}
          <AIToolSelection />
          <div ref={timeSliderRef}>
            <TimeSlider
              frequency={formData.frequency}
              duration={formData.totalTime}
              aiPercentage={formData.aiAssistedPercentage}
              onTimeChange={handleTimeChange}
              color={formData.color}
              disableAISlider={selectedAITools.length === 0}
            />
          </div>
          <Button 
            className="w-full py-6 text-lg" 
            type="submit"
            disabled={!formData.taskName}
          >
            Add to My Dashboard
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

// Helper function to generate a unique ID if needed
function generateUniqueId(): string {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export default EditForm;