import React, { useState, useRef, useCallback } from 'react';
import { Button } from './ui/button';
import { useUserStore } from '../stores/userStore';
import { useToast } from './ui/use-toast';
import { Spinner } from './ui/Spinner';
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/dialog';
import { DEFAULT_PROFILE_PICTURE } from '../constants'; // Import default profile pictures
import { useThemeStore } from '../stores/themeStore'; // Import theme store
import { Camera } from 'lucide-react'; // Add this import

interface ProfilePictureUploadProps {
  size?: 'sm' | 'md' | 'lg';
  showEditButton?: boolean;
}

export const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({ 
  size = 'md',
  showEditButton = true 
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [showCropDialog, setShowCropDialog] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
    x: 0,
    y: 0
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  
  const imgRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentUser, updateProfilePicture } = useUserStore();
  const { toast } = useToast();
  const { isDarkMode } = useThemeStore(); // Access current theme

  const sizeClasses = {
    sm: 'w-16 h-16',
    md: 'w-24 h-24',
    lg: 'w-32 h-32'
  };

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    
    const initialCrop = centerAspectCrop(width, height, 1);
    
    setCrop(initialCrop);
    
    const pixelCrop: PixelCrop = {
      unit: 'px',
      x: Math.round((initialCrop.x * width) / 100),
      y: Math.round((initialCrop.y * height) / 100),
      width: Math.round((initialCrop.width * width) / 100),
      height: Math.round((initialCrop.height * height) / 100)
    };
    
    setCompletedCrop(pixelCrop);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast({
        title: "Invalid file type",
        description: "Please select an image file",
        variant: "destructive"
      });
      return;
    }

    // Validate file size (5MB max)
    if (file.size > 5 * 1024 * 1024) {
      toast({
        title: "File too large",
        description: "Please select an image under 5MB",
        variant: "destructive"
      });
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '');
      setShowCropDialog(true);
    });
    reader.readAsDataURL(file);
  };

  const generateCroppedImage = useCallback(async (): Promise<Blob | null> => {
    try {
      if (!imgRef.current || !completedCrop) {
        return null;
      }

      const image = imgRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        console.error('No 2d context');
        return null;
      }

      // Calculate scale factor between natural and display sizes
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      // Set desired output size
      const outputWidth = 400;
      const outputHeight = 400;

      // Configure canvas
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      // Calculate crop values in natural image coordinates
      const pixelCrop = {
        x: completedCrop.x * scaleX,
        y: completedCrop.y * scaleY,
        width: completedCrop.width * scaleX,
        height: completedCrop.height * scaleY
      };

      // Set rendering quality
      ctx.imageSmoothingQuality = 'high';
      ctx.imageSmoothingEnabled = true;

      // Clear the canvas
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the cropped image
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        outputWidth,
        outputHeight
      );

      // Convert to blob
      return new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              console.error('Failed to create blob');
              resolve(null);
              return;
            }
            resolve(blob);
          },
          'image/jpeg',
          0.95 // High quality
        );
      });
    } catch (error) {
      console.error('Error in generateCroppedImage:', error);
      return null;
    }
  }, [completedCrop]);

  const handleSave = async () => {
    if (!currentUser) {
      console.error('No current user');
      return;
    }

    try {
      setIsUploading(true);
      const croppedImage = await generateCroppedImage();
      
      if (!croppedImage) {
        throw new Error('Failed to generate cropped image');
      }

      const file = new File([croppedImage], 'profile.jpg', { type: 'image/jpeg' });
      const urls = await updateProfilePicture(currentUser.id, file);
      
      setShowCropDialog(false);
      setImgSrc('');
      
      toast({
        title: "Success",
        description: "Profile picture updated successfully",
      });
    } catch (error) {
      console.error('Profile picture upload failed:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update profile picture",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
    }
  };

  const isDefaultAvatar = !currentUser?.profilePicture?.mainUrl;

  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="relative group">
            <img 
              src={currentUser?.profilePicture?.mainUrl 
                ? currentUser.profilePicture.mainUrl 
                : (isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light)} 
              alt="Profile" 
              className={`${sizeClasses[size]} rounded-full object-cover border-2 border-gray-200 dark:border-gray-800`}
            />
            
            {/* Add upload overlay for default avatar */}
            {isDefaultAvatar && (
              <div 
                className="absolute inset-0 flex items-center justify-center rounded-full bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                onClick={() => fileInputRef.current?.click()}
              >
                <Camera className="w-6 h-6 text-white" />
              </div>
            )}
          </div>
          
          {/* Only show edit button if it's not default avatar and showEditButton is true */}
          {!isDefaultAvatar && showEditButton && (
            <>
              <input
                type="file"
                ref={fileInputRef}
                onChange={onSelectFile}
                accept="image/*"
                className="hidden"
              />
              <Button
                onClick={() => fileInputRef.current?.click()}
                disabled={isUploading}
                variant="outline"
                className="w-full max-w-[200px]"
              >
                Change Profile Picture
              </Button>
            </>
          )}
          
          {/* Hidden input for default avatar upload */}
          {isDefaultAvatar && (
            <input
              type="file"
              ref={fileInputRef}
              onChange={onSelectFile}
              accept="image/*"
              className="hidden"
            />
          )}
        </div>
      </div>

      <Dialog open={showCropDialog} onOpenChange={setShowCropDialog}>
        <DialogContent className="max-w-[800px] w-full">
          <DialogHeader>
            <DialogTitle>Crop Profile Picture</DialogTitle>
            <DialogDescription>
              Adjust the circular frame to crop your profile picture. You can drag and resize the selection.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="flex justify-center">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => {
                  setCrop(percentCrop);
                }}
                onComplete={(c) => {
                  const pixelCrop: PixelCrop = {
                    unit: 'px',
                    x: Math.round(c.x),
                    y: Math.round(c.y),
                    width: Math.round(c.width),
                    height: Math.round(c.height)
                  };
                  setCompletedCrop(pixelCrop);
                }}
                aspect={1}
                circularCrop
                className="max-h-[500px]"
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  className="max-h-[500px] w-auto"
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                onClick={() => setShowCropDialog(false)}
                disabled={isUploading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                disabled={isUploading}
              >
                {isUploading ? <Spinner className="w-4 h-4" /> : 'Save'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}; 