import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import HeaderNavigation from './components/HeaderNavigation';
import MyBadgePage from './pages/MyBadgePage';
import ProfilePage from './pages/ProfilePage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { useUserStore } from './stores/userStore';
import { UserSettingsPage } from './pages/UserSettingsPage';
import { useGoogleAnalytics } from './hooks/useGoogleAnalytics';
import HomePage from './pages/HomePage';
import { DefaultMetaTags } from './components/DefaultMetaTags';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { VerifyEmailPage } from './pages/VerifyEmailPage';
import { Spinner } from './components/ui/Spinner';
import OnboardingPage from './pages/OnboardingPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import SkoolPage from './pages/SkoolPage';
import Home from './pages/Home';
import Platform from './pages/Platform'
import RingsTest from './pages/RingsTest';
import NewsletterPage from './pages/Newsletter';
import ThankYouPage from './pages/ThankYouPage';
import RemindMePage from './pages/RemindMePage';
import PrivacyPolicy from './pages/PrivacyPolicy';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser, isEmailVerified } = useAuth();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (currentUser) {
        await currentUser.reload();
      }
      setIsChecking(false);
    };

    checkAuth();
  }, [currentUser]);

  if (isChecking) {
    return <Spinner />;
  }

  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!isEmailVerified && location.pathname !== '/verify-email') {
    return <Navigate to="/verify-email" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const MainContent: React.FC = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  useGoogleAnalytics();
  
  const isMyBadgePage = location.pathname === '/my-badge';
  const isUserPage = location.pathname.startsWith('/u/');
  const isSocialPage = location.pathname.startsWith('/s/');

  return (
    <HelmetProvider>
      <div className={`min-h-screen bg-gradient-radial from-background to-background-end text-foreground`}>
        {!isUserPage && !isSocialPage && <DefaultMetaTags />}
        {!isSocialPage && <HeaderNavigation />}
        <main className="container mx-auto px-4 py-4 md:py-8">
          <Routes>
            <Route path="/" element={!currentUser ? <HomePage /> : <Navigate to="/dashboard" />} />
            <Route path="/u/:userId" element={<ProfilePage />} />
            <Route path="/verify-email" element={<VerifyEmailPage />} />
            
            <Route path="/onboarding" element={
              currentUser ? <Navigate to="/dashboard" /> : <OnboardingPage />
            } />
            
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <MyBadgePage />
              </ProtectedRoute>
            } />
            
            <Route path="/settings" element={
              <ProtectedRoute>
                <UserSettingsPage />
              </ProtectedRoute>
            } />
            <Route path="/skool" element={<SkoolPage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/rings-test" element={<RingsTest />} />
            <Route path="/newsletter" element={<NewsletterPage />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/remind-me" element={<RemindMePage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
      </div>
    </HelmetProvider>
  );
};

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <MainContent />
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
