import { storage, db } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { DEFAULT_PROFILE_PICTURE } from '../constants';
import { useUserStore } from '../stores/userStore';

interface ImageDimensions {
  width: number;
  height: number;
}

interface PlatformDimensions {
  width: number;
  height: number;
}

const PLATFORM_DIMENSIONS = {
  social: { width: 1200, height: 627 },     // 1.91:1 ratio for all social platforms
  default: { width: 1200, height: 1200 }    // 1:1 ratio for downloads
};

async function waitForImageLoad(url: string): Promise<string> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, { 
        mode: 'cors',
        credentials: 'omit'
      });
      const blob = await response.blob();
      
      const img = new Image();
      img.crossOrigin = "anonymous";
      let timeoutId: NodeJS.Timeout;

      const cleanup = () => {
        clearTimeout(timeoutId);
        URL.revokeObjectURL(img.src);
      };

      img.onload = () => {
        cleanup();
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      };

      img.onerror = (error) => {
        cleanup();
        console.error('Image load error:', error);
        reject(new Error(`Failed to load image: ${url}`));
      };

      const objectUrl = URL.createObjectURL(blob);
      img.src = objectUrl;
      
      timeoutId = setTimeout(() => {
        cleanup();
        reject(new Error(`Image load timeout: ${url}`));
      }, 10000);
    } catch (error) {
      console.error('Image fetch error:', error);
      reject(error);
    }
  });
}

// Add quality parameter for JPEG compression
async function svgToImage(svgElement: SVGElement, width: number, height: number, quality = 0.8): Promise<Blob> {
  try {
    const svgClone = svgElement.cloneNode(true) as SVGElement;
    const imageLoadPromises: Promise<void>[] = [];
    
    // Calculate aspect ratio and dimensions
    const originalWidth = 600; // Original SVG width
    const originalHeight = 600; // Original SVG height
    const targetAspectRatio = width / height;
    const originalAspectRatio = originalWidth / originalHeight;
    
    let scaledWidth: number;
    let scaledHeight: number;
    let offsetX = 0;
    let offsetY = 0;
    
    if (targetAspectRatio > originalAspectRatio) {
      // Target is wider than original
      scaledHeight = height;
      scaledWidth = height * originalAspectRatio;
      offsetX = (width - scaledWidth) / 2;
    } else {
      // Target is taller than original
      scaledWidth = width;
      scaledHeight = width / originalAspectRatio;
      offsetY = (height - scaledHeight) / 2;
    }

    // Keep all existing styles and add them to SVG
    const styles = document.createElement('style');
    styles.textContent = `
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
      
      :root {
        --badge-center-color: black;
        --badge-outer-color: #331c45;
      }
      
      text {
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }

      .profile-image-container {
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;
      }

      .profile-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      /* Force dark mode colors */
      text {
        fill: #FFFFFF !important;
      }
      text[fill="#666666"] {
        fill: #888888 !important;
      }

      /* Ensure gradient colors are applied */
      circle[fill="var(--badge-center-color)"] {
        fill: black !important;
      }
      
      circle[fill="var(--badge-outer-color)"] {
        fill: #331c45 !important;
      }
    `;
    svgClone.insertBefore(styles, svgClone.firstChild);

    // Ensure profile image is loaded before proceeding
    const foreignObject = svgClone.querySelector('foreignObject');
    if (foreignObject) {
      const img = foreignObject.querySelector('img') as HTMLImageElement | null;
      if (img) {
        const imgSrc = img.getAttribute('src');
        if (imgSrc) {
          try {
            // Wait for image with timeout and retry
            let attempts = 3;
            let dataUrl: string | null = null;
            
            while (attempts > 0 && !dataUrl) {
              try {
                dataUrl = await Promise.race([
                  waitForImageLoad(imgSrc),
                  new Promise<string>((_, reject) => 
                    setTimeout(() => reject(new Error('Image load timeout')), 3000)
                  )
                ]);
              } catch (error) {
                console.warn(`Profile image load attempt failed, ${attempts - 1} attempts remaining`);
                attempts--;
                if (attempts === 0) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1s before retry
              }
            }

            if (dataUrl) {
              foreignObject.innerHTML = `
                <div xmlns="http://www.w3.org/1999/xhtml" class="profile-image-container">
                  <img
                    src="${dataUrl}"
                    class="profile-image"
                    style="visibility: visible; opacity: 1;"
                  />
                </div>
              `;
            }
          } catch (error) {
            console.error('Failed to load profile image:', error);
            foreignObject.innerHTML = `
              <div xmlns="http://www.w3.org/1999/xhtml" class="profile-image-container">
                <img
                  src="${DEFAULT_PROFILE_PICTURE.dark}"
                  class="profile-image"
                  style="visibility: visible; opacity: 1;"
                />
              </div>
            `;
          }
        }
      }
    }

    // Handle logo
    const logoImage = svgClone.querySelector('image[href*="logo"]');
    if (logoImage) {
      const logoUrl = `${window.location.origin}/logo_dark.svg`;
      const logoPromise = (async () => {
        try {
          const dataUrl = await getCachedImage(logoUrl);
          logoImage.setAttribute('href', dataUrl);
        } catch (error) {
          console.error('Logo load error:', error);
        }
      })();
      
      imageLoadPromises.push(logoPromise);
    }

    // Ensure all circle elements use the correct colors
    svgClone.querySelectorAll('circle').forEach(circle => {
      const fill = circle.getAttribute('fill');
      if (fill === 'var(--badge-center-color)') {
        circle.setAttribute('fill', 'black');
      } else if (fill === 'var(--badge-outer-color)') {
        circle.setAttribute('fill', '#331c45');
      }
    });

    // Wait for all images to load
    await Promise.all(imageLoadPromises);

    // Convert to blob with JPEG compression
    return new Promise((resolve, reject) => {
      try {
        const serializer = new XMLSerializer();
        const svgString = serializer.serializeToString(svgClone);
        const svgBase64 = btoa(unescape(encodeURIComponent(svgString)));
        const dataUrl = `data:image/svg+xml;base64,${svgBase64}`;

        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          
          const ctx = canvas.getContext('2d', { willReadFrequently: true });
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }
          
          // Fill background
          ctx.fillStyle = '#000';
          ctx.fillRect(0, 0, width, height);
          
          // Draw image maintaining aspect ratio
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);
          
          // Use JPEG format with quality setting
          canvas.toBlob(
            (blob) => blob ? resolve(blob) : reject(new Error('Failed to create blob')),
            'image/jpeg',
            quality
          );
        };
        
        img.onerror = (e) => {
          console.error('Final image load error:', e);
          reject(new Error('Failed to load final SVG image'));
        };

        img.src = dataUrl;
      } catch (error) {
        reject(error);
      }
    });
  } catch (error) {
    console.error('SVG conversion error:', error);
    throw error;
  }
}

// Helper function to get cached image
const imageCache = new Map<string, string>();

async function getCachedImage(src: string): Promise<string> {
  // Check memory cache first
  if (imageCache.has(src)) {
    return imageCache.get(src)!;
  }

  // For Firebase Storage URLs, remove the query parameters for caching
  const cacheKey = src.split('?')[0];

  try {
    // Fetch the image
    const response = await fetch(src, { 
      mode: 'cors',
      cache: 'force-cache' // Force browser to use cache
    });
    const blob = await response.blob();
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;
        // Store in memory cache
        imageCache.set(src, dataUrl);
        imageCache.set(cacheKey, dataUrl); // Also cache without query params
        resolve(dataUrl);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error loading image:', src, error);
    throw error;
  }
}

// Modified image loading function with explicit checks
async function loadAndCacheImage(src: string, retries = 3): Promise<string> {
  // Check memory cache first
  const cacheKey = src.split('?')[0]; // Remove query params for cache key
  const cachedImage = imageCache.get(cacheKey);
  if (cachedImage) {
    return cachedImage;
  }

  const loadImage = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      
      img.onload = async () => {
        try {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          
          const ctx = canvas.getContext('2d');
          if (!ctx) throw new Error('Failed to get canvas context');
          
          ctx.drawImage(img, 0, 0);
          const dataUrl = canvas.toDataURL('image/png');
          
          // Cache using the URL without query params
          imageCache.set(cacheKey, dataUrl);
          
          resolve(dataUrl);
        } catch (error) {
          reject(error);
        }
      };
      
      img.onerror = () => {
        console.error(`Failed to load image: ${src}`);
        reject(new Error(`Failed to load image: ${src}`));
      };
      
      // Add timestamp to Firebase URLs to prevent caching
      const urlWithTimestamp = src.includes(FIREBASE_STORAGE_BASE_URL)
        ? `${src}&t=${new Date().getTime()}`
        : src;
      
      img.crossOrigin = 'anonymous';
      img.src = urlWithTimestamp;
    });
  };

  // Retry logic with increasing delays
  let lastError;
  for (let i = 0; i < retries; i++) {
    try {
      return await loadImage();
    } catch (error) {
      console.warn(`Attempt ${i + 1}/${retries} failed:`, error);
      lastError = error;
      await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
    }
  }
  throw lastError;
}

const FIREBASE_STORAGE_BASE_URL = 'https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/';

async function generateLayeredBadge(
  badgeElement: HTMLElement,
  dimensions: ImageDimensions,
  userId: string
): Promise<Blob> {
  try {
    // Construct the known Firebase Storage URL for profile picture
    const customUrl = `https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/users%2F${userId}%2Fprofile%2Fmain.jpg?alt=media`;

    // Create profile picture layer
    const profileCanvas = document.createElement('canvas');
    profileCanvas.width = dimensions.width;
    profileCanvas.height = dimensions.height;
    const profileCtx = profileCanvas.getContext('2d', { willReadFrequently: true });
    
    if (profileCtx) {
      // Always draw default avatar first
      const defaultImg = new Image();
      defaultImg.crossOrigin = 'anonymous';
      await new Promise((resolve) => {
        defaultImg.onload = resolve;
        defaultImg.src = DEFAULT_PROFILE_PICTURE.dark;
      });

      const profileSize = dimensions.width * 0.33;
      const profileX = (dimensions.width - profileSize) / 2;
      const profileY = (dimensions.height - profileSize) / 2;

      // Draw default avatar
      profileCtx.save();
      profileCtx.beginPath();
      profileCtx.arc(
        profileX + profileSize / 2,
        profileY + profileSize / 2,
        profileSize / 2,
        0,
        Math.PI * 2
      );
      profileCtx.clip();
      profileCtx.drawImage(defaultImg, profileX, profileY, profileSize, profileSize);
      profileCtx.restore();

      // Try to load and draw actual profile picture over default
      try {
        const profileImg = new Image();
        profileImg.crossOrigin = 'anonymous';
        
        await new Promise((resolve, reject) => {
          profileImg.onload = resolve;
          profileImg.onerror = (error) => {
            reject(error);
          };
          profileImg.src = customUrl;
        });

        // If profile picture loads successfully, draw it over default
        profileCtx.save();
        profileCtx.beginPath();
        profileCtx.arc(
          profileX + profileSize / 2,
          profileY + profileSize / 2,
          profileSize / 2,
          0,
          Math.PI * 2
        );
        profileCtx.clip();
        profileCtx.drawImage(profileImg, profileX, profileY, profileSize, profileSize);
        profileCtx.restore();

      } catch (error) {
      }
    }

    // Step 1: Generate base badge (rings and text)
    const baseBadge = badgeElement.cloneNode(true) as HTMLElement;
    
    // Remove the "add task" segment if it exists
    const addTaskSegment = baseBadge.querySelector('.add-task-ring');
    if (addTaskSegment) {
      addTaskSegment.remove();
    }

    // Remove profile picture and logo elements for base layer
    const foreignObject = baseBadge.querySelector('foreignObject');
    if (foreignObject) foreignObject.remove();
    
    const logoImg = baseBadge.querySelector('image[href*="logo"]');
    if (logoImg) logoImg.remove();

    // Generate base badge image
    const baseImageBlob = await svgToImage(baseBadge.querySelector('svg') as SVGElement, dimensions.width, dimensions.height);

    // Step 2: Generate logo layer
    const logoCanvas = document.createElement('canvas');
    logoCanvas.width = dimensions.width;
    logoCanvas.height = dimensions.height;
    const logoCtx = logoCanvas.getContext('2d', { willReadFrequently: true });

    if (logoCtx) {
      const logoUrl = `${window.location.origin}/logo_dark.svg`;
      const logoImg = new Image();
      logoImg.crossOrigin = 'anonymous';
      await new Promise((resolve, reject) => {
        logoImg.onload = resolve;
        logoImg.onerror = reject;
        logoImg.src = `${logoUrl}?t=${Date.now()}`;
      });

      // Adjust logo size and position with correct aspect ratio
      const logoWidth = dimensions.width * 0.1;  // 5% of canvas width
      const logoHeight = logoWidth / 3.3138461538;  // Apply correct aspect ratio
      const logoX = (dimensions.width * 0.75) - 50;  // 3 o'clock position, moved 30px left
      const logoY = (dimensions.height - logoHeight) / 2;  // Center vertically

      logoCtx.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);
    }

    // Step 4: Combine all layers
    const finalCanvas = document.createElement('canvas');
    finalCanvas.width = dimensions.width;
    finalCanvas.height = dimensions.height;
    const finalCtx = finalCanvas.getContext('2d', { willReadFrequently: true });

    if (!finalCtx) {
      throw new Error('Failed to get canvas context');
    }

    // Draw base badge
    const baseImg = new Image();
    baseImg.crossOrigin = 'anonymous';
    await new Promise((resolve) => {
      baseImg.onload = resolve;
      baseImg.src = URL.createObjectURL(baseImageBlob);
    });
    finalCtx.drawImage(baseImg, 0, 0);

    // Draw profile picture layer
    finalCtx.drawImage(profileCanvas, 0, 0);

    // Draw logo layer
    finalCtx.drawImage(logoCanvas, 0, 0);

    // Convert to blob
    return new Promise((resolve, reject) => {
      try {
        finalCanvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to create blob'));
            }
          },
          'image/jpeg',
          0.8
        );
      } catch (error) {
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error in generateLayeredBadge:', error);
    throw error;
  }
}

async function addPlatformPadding(originalBlob: Blob, platform: 'social' | 'default'): Promise<Blob> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const dimensions = PLATFORM_DIMENSIONS[platform];
  
  // Set canvas size to platform dimensions
  canvas.width = dimensions.width;
  canvas.height = dimensions.height;
  
  if (!ctx) throw new Error('Failed to get canvas context');
  
  // Fill background with black
  ctx.fillStyle = '#000000';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  // Load original image
  const img = new Image();
  await new Promise((resolve) => {
    img.onload = resolve;
    img.src = URL.createObjectURL(originalBlob);
  });
  
  // First crop the square image to remove excess black space
  const cropSize = Math.min(img.width, img.height) * 0.833; // Crop to 83.3% to remove excess black space
  const cropX = (img.width - cropSize) / 2;
  const cropY = (img.height - cropSize) / 2;
  
  // Calculate dimensions to maintain aspect ratio
  let targetWidth, targetHeight;
  
  if (platform === 'social') {
    // For social, scale based on height to maximize badge size
    targetHeight = dimensions.height;
    targetWidth = (cropSize / cropSize) * targetHeight;
    
    // Center horizontally
    const x = (dimensions.width - targetWidth) / 2;
    ctx.drawImage(img, cropX, cropY, cropSize, cropSize, x, 0, targetWidth, targetHeight);
  } else {
    // For default square, maintain original dimensions
    targetWidth = dimensions.width;
    targetHeight = dimensions.height;
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  }
  
  // Convert to blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) resolve(blob);
        else reject(new Error('Failed to create blob'));
      },
      'image/jpeg',
      0.8
    );
  });
}

// Simplified badge generation function
export const generateBadgeImage = async (
  element: HTMLElement,
  userId: string
): Promise<string> => {
  try {
    const squareImageBlob = await generateLayeredBadge(element, PLATFORM_DIMENSIONS.default, userId);

    // Generate only two versions: default (square) and social
    const versions = ['default', 'social'] as const;
    const uploads = await Promise.all(
      versions.map(async (version) => {
        const platformBlob = await addPlatformPadding(squareImageBlob, version);
        const filename = version === 'default' ? 'og-image.jpg' : 'social.jpg';
        
        const imagePath = `users/${userId}/badges/${filename}`;
        
        const storageRef = ref(storage, imagePath);
        await uploadBytes(storageRef, platformBlob);
        return getDownloadURL(storageRef);
      })
    );

    const [defaultUrl, socialUrl] = uploads;
    
    // Update user's OG data with the social version
    await useUserStore.getState().updateOgData(userId, socialUrl);
    
    return defaultUrl; // Return the square version for the UI
  } catch (error) {
    console.error('Error generating badge image:', error);
    throw error;
  }
};

// Update getBadgeImagePath helper
export const getBadgeImagePath = (userId: string, version: 'default' | 'social') => {
  const filename = version === 'default' ? 'og-image.jpg' : 'social.jpg';
  return `users/${userId}/badges/${filename}`;
};

const loadProfilePicture = async (profileUrl: string): Promise<HTMLImageElement> => {
  const profileImg = new Image();
  profileImg.crossOrigin = 'anonymous';
  
  try {
    // First try loading the user's profile picture
    await new Promise((resolve, reject) => {
      profileImg.onload = resolve;
      profileImg.onerror = reject;
      profileImg.src = `${profileUrl}?t=${Date.now()}`;
    });
  } catch (error) {
    // If that fails, try loading the default avatar
    await new Promise((resolve, reject) => {
      profileImg.onload = resolve;
      profileImg.onerror = reject;
      profileImg.src = `${window.location.origin}/default-avatar-dark.svg`;
    });
  }
  
  return profileImg;
};
