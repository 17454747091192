import React, { useState, useEffect } from 'react';
import { useInsightStore } from '../stores/insightStore';
import { InsightCategory, Task, UserAITool, Insight } from '../types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader } from './ui/card';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Textarea } from './ui/textarea';
import { toast } from './ui/use-toast';
import { categoryConfig } from '../config/insightCategories';
import { getAuth } from 'firebase/auth';
import { ArrowLeft, ImagePlus, X } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { Spinner } from './ui/Spinner';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { processImage } from '../utils/imageUtils';
import { nanoid } from 'nanoid';

interface InsightFormProps {
  task: Task;
  aiTool: UserAITool;
  userId: string;
  onComplete: () => void;
}

export const InsightForm: React.FC<InsightFormProps> = ({
  task,
  aiTool,
  userId,
  onComplete
}) => {
  const [selectedCategory, setSelectedCategory] = useState<InsightCategory | null>(null);
  const [content, setContent] = useState('');
  const [additionalFields, setAdditionalFields] = useState({
    challengeSolution: '',
    resourceUrl: '',
    resourceTitle: '',
    metricValue: '',
    metricUnit: ''
  });
  const [selectedImage, setSelectedImage] = useState<{ file: Blob; preview: string } | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { addInsight, isLoading } = useInsightStore();

  useEffect(() => {
    return () => {
      useInsightStore.setState({ isLoading: false });
    };
  }, []);

  useEffect(() => {
    const auth = getAuth();
    if (!auth.currentUser) {
      onComplete();
    }
  }, []);

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast({
        title: "Invalid file type",
        description: "Please select an image file",
        variant: "destructive"
      });
      return;
    }

    try {
      const processedBlob = await processImage(file, 1200);
      const preview = URL.createObjectURL(processedBlob);
      
      setSelectedImage({
        file: processedBlob,
        preview
      });
    } catch (error) {
      console.error('Error processing image:', error);
      toast({
        title: "Error",
        description: "Failed to process image",
        variant: "destructive"
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      let imageUrl = '';
      const auth = getAuth();
      
      if (selectedImage) {
        const imageId = nanoid();
        const imagePath = `users/${auth.currentUser?.uid}/insights/${imageId}.jpg`;
        const storageRef = ref(storage, imagePath);
        
        await uploadBytes(storageRef, selectedImage.file);
        imageUrl = await getDownloadURL(storageRef);
      }

      const newInsight: Omit<Insight, "id"> = {
        taskId: task.taskId,
        userId: auth.currentUser?.uid || '',
        userName: auth.currentUser?.displayName || 'Anonymous',
        aiToolId: aiTool.aiToolId,
        aiToolName: aiTool.aiToolName,
        category: selectedCategory || 'tips',
        content: content.trim(),
        likes: 0,
        likedBy: [],
        createdAt: new Date().toISOString(),
        timeSaved: task.aiAssistedPercentage || 0,
        ...(imageUrl && { imageUrl }),
        ...(selectedCategory === 'challenges' && additionalFields.challengeSolution && {
          challengeSolution: additionalFields.challengeSolution.trim(),
        }),
        ...(selectedCategory === 'resources' && additionalFields.resourceUrl && {
          resourceUrl: additionalFields.resourceUrl.trim(),
          resourceTitle: additionalFields.resourceTitle.trim(),
        }),
        ...(selectedCategory === 'metrics' && additionalFields.metricValue && {
          metricValue: parseFloat(additionalFields.metricValue),
          metricUnit: additionalFields.metricUnit.trim(),
        }),
      };

      await addInsight(task.taskId, newInsight);
      toast({
        title: "Success!",
        description: "Thank you for sharing your insight!"
      });
      useInsightStore.setState({ isLoading: false });
      onComplete();
    } catch (error) {
      console.error('Error submitting insight:', error);
      toast({
        title: "Error",
        description: "Failed to save your insight. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage.preview);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage.preview);
      }
    };
  }, [selectedImage]);

  return (
    <Card className="w-full md:mt-8">
      <CardHeader className="pb-3 pt-2">
        <div className="flex items-center mb-0 ml-[-0.75rem]">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  className="rounded-full size-10" 
                  variant="ghost" 
                  size="icon" 
                  onClick={onComplete}
                >
                  <ArrowLeft className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Back</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        
        <div className="w-full">
          <h3 className="text-2xl font-bold">Would you like to share some tips or experiences?</h3>
          <p className="text-gray-600 dark:text-gray-400">
            It will help others in your profession from your experience with {aiTool.aiToolName}
          </p>
        </div>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Category Selection */}
          <div className="flex flex-wrap gap-2">
            {(Object.keys(categoryConfig) as InsightCategory[]).map((category) => (
              <Button
                key={category}
                type="button"
                variant={selectedCategory === category ? "default" : "outline"}
                onClick={() => setSelectedCategory(category)}
                className="flex items-center gap-2"
              >
                {React.createElement(categoryConfig[category].icon, {
                  className: "h-4 w-4"
                })}
                <span>{categoryConfig[category].label}</span>
              </Button>
            ))}
          </div>

          {/* Category-specific form */}
          {selectedCategory && (
            <div className="space-y-4">
              <Textarea
                placeholder={categoryConfig[selectedCategory].placeholder}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="min-h-[100px]"
              />

              {/* Additional fields based on category */}
              {selectedCategory === 'challenges' && (
                <Textarea
                  placeholder="Share how you solved this challenge..."
                  value={additionalFields.challengeSolution}
                  onChange={(e) => setAdditionalFields({
                    ...additionalFields,
                    challengeSolution: e.target.value
                  })}
                />
              )}

              {selectedCategory === 'resources' && (
                <div className="space-y-4">
                  <Input
                    placeholder="Resource title"
                    value={additionalFields.resourceTitle}
                    onChange={(e) => setAdditionalFields({
                      ...additionalFields,
                      resourceTitle: e.target.value
                    })}
                  />
                  <Input
                    type="url"
                    placeholder="Resource URL"
                    value={additionalFields.resourceUrl}
                    onChange={(e) => setAdditionalFields({
                      ...additionalFields,
                      resourceUrl: e.target.value
                    })}
                  />
                </div>
              )}

              {selectedCategory === 'metrics' && (
                <div className="flex gap-4">
                  <Input
                    type="number"
                    placeholder="Value"
                    value={additionalFields.metricValue}
                    onChange={(e) => setAdditionalFields({
                      ...additionalFields,
                      metricValue: e.target.value
                    })}
                  />
                  <Input
                    placeholder="Unit (e.g., %, hours)"
                    value={additionalFields.metricUnit}
                    onChange={(e) => setAdditionalFields({
                      ...additionalFields,
                      metricUnit: e.target.value
                    })}
                  />
                </div>
              )}

              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Label htmlFor="image-upload" className="cursor-pointer">
                    <div className="flex items-center gap-2 text-sm text-muted-foreground hover:text-foreground">
                      <ImagePlus className="h-4 w-4" />
                      <span>Add image</span>
                    </div>
                  </Label>
                  <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageSelect}
                  />
                </div>
                
                {selectedImage && (
                  <div className="relative inline-block">
                    <img
                      src={selectedImage.preview}
                      alt="Selected"
                      className="max-w-[300px] rounded-md"
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute top-2 right-2 bg-black/50 hover:bg-black/70"
                      onClick={() => {
                        URL.revokeObjectURL(selectedImage.preview);
                        setSelectedImage(null);
                      }}
                    >
                      <X className="h-4 w-4 text-white" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-end gap-4">
            <Button type="button" variant="outline" onClick={onComplete}>
              Skip
            </Button>
            <Button type="submit" disabled={!selectedCategory || isLoading}>
              {isLoading ? (
                <div className="flex items-center gap-2">
                  <span>Saving...</span>
                  <Spinner className="h-4 w-4" />
                </div>
              ) : (
                "Share Insight"
              )}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default InsightForm;