import React, { useState, useEffect, useRef } from 'react';
import Badge from '../Badge';
import { demoBadges } from '../../data/demoBadges';
import { motion, AnimatePresence } from 'framer-motion';
import { Pause, Play, SkipForward, SkipBack } from 'lucide-react';
import { cn } from '../../lib/utils';

interface DemoBadgeSectionProps {
  onDemoComplete?: () => void;
}

export const DemoBadgeSection: React.FC<DemoBadgeSectionProps> = ({ onDemoComplete }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<NodeJS.Timeout | null>(null);
  const DEMO_DURATION = 7000; // 7 seconds per demo
  const PROGRESS_UPDATE_INTERVAL = 50; // Update progress every 50ms
  const [isDemoComplete, setIsDemoComplete] = useState(false);

  const startProgressTimer = () => {
    if (progressInterval.current) clearInterval(progressInterval.current);
    
    const startTime = Date.now();
    progressInterval.current = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const newProgress = (elapsed / DEMO_DURATION) * 100;
      
      if (newProgress >= 100) {
        setProgress(0);
        handleNext();
      } else {
        setProgress(newProgress);
      }
    }, PROGRESS_UPDATE_INTERVAL);
  };

  useEffect(() => {
    if (isPlaying) {
      startProgressTimer();
    } else if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [isPlaying, currentIndex]);

  const handleNext = () => {
    if (currentIndex < demoBadges.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setProgress(0);
    } else {
      onDemoComplete?.();
      setProgress(0);
      setIsPlaying(false);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setProgress(0);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const emptyBadge = {
    user: {
      id: "empty",
      uid: "empty",
      name: "Your Name",
      email: "",
      professionId: "",
      professionName: "Your Profession",
      points: 0,
      aiLevel: 0,
      profilePictureURL: undefined,
      tasks: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      bio: "",
      location: "",
      website: "",
      isPremium: false,
      premiumUntil: undefined,
      lastProfessionChange: new Date().toISOString(),
      professionHistory: [],
      emailVerified: false
    },
    commentary: {
      title: "Create Your Badge",
      description: "Start showcasing your AI journey",
      stats: {
        timeSaved: 0,
        tasksOptimized: 0,
        aiScore: 0
      }
    }
  };

  const currentBadge = isDemoComplete ? emptyBadge : demoBadges[currentIndex];

  return (
    <div className="max-w-3xl mx-auto">
      {/* Badge */}
      <AnimatePresence mode="wait">
        <motion.div
          key={isDemoComplete ? 'empty' : currentIndex}
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.5 }}
        >
          <Badge
            layout="centered"
            userId={currentBadge.user.id}
            showAIOnly={false}
            user={currentBadge.user}
            showUserInfo={true}
            isDemo={true}
          />
        </motion.div>
      </AnimatePresence>

      {/* Simplified Controls */}
      {!isDemoComplete && (
        <div className="mt-0 flex items-center justify-center">
          {/* Combined Controls Container */}
          <div className="flex items-center gap-4 bg-gray-100 dark:bg-gray-900 rounded-full px-4 py-3">
            {/* Play/Pause Button */}
            <button
              onClick={togglePlayPause}
              className="p-2 rounded-full text-gray-500 hover:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors"
            >
              {isPlaying ? (
                <Pause className="w-5 h-5" />
              ) : (
                <Play className="w-5 h-5" />
              )}
            </button>

            {/* Progress Dots */}
            <div className="flex items-center gap-2">
              {demoBadges.map((_, index) => (
                <div 
                  key={index} 
                  className="relative flex items-center"
                  style={{ width: index === currentIndex ? '100px' : '8px', height: '8px' }}
                >
                  {index === currentIndex ? (
                    // Active slide - progress bar
                    <div className="absolute inset-0 rounded-full bg-gray-200 dark:bg-gray-800 overflow-hidden">
                      <motion.div
                        className="h-full bg-gray-100 dark:bg-gray-700 rounded-full"
                        style={{ width: `${progress}%` }}
                        transition={{ duration: 0.1 }}
                      />
                    </div>
                  ) : (
                    // Inactive slide - dot
                    <button
                      onClick={() => {
                        setCurrentIndex(index);
                        setProgress(0);
                      }}
                      className={cn(
                        "w-2 h-2 rounded-full transition-colors",
                        index < currentIndex 
                          ? "bg-gray-200 dark:bg-gray-700" 
                          : "bg-gray-300 dark:bg-gray-700",
                        "hover:bg-gray-200 dark:hover:bg-gray-600"
                      )}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}


      {/* Commentary section - more compact 
      <div className="mt-4 text-center space-y-3">
        <div className="space-y-1">
          <h1 className="text-lg font-semibold">{currentBadge.commentary.title}</h1>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {currentBadge.commentary.description}
          </p>
        </div>

        <div className="flex justify-center gap-6 text-sm">
          <div className="flex flex-col items-center">
            <p className="font-semibold">{currentBadge.commentary.stats.timeSaved}h</p>
            <p className="text-xs text-gray-500">Time Saved</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="font-semibold">{currentBadge.commentary.stats.tasksOptimized}</p>
            <p className="text-xs text-gray-500">Tasks</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="font-semibold">{currentBadge.commentary.stats.aiScore}%</p>
            <p className="text-xs text-gray-500">AI Score</p>
          </div>
        </div>
      </div>
      
      */}
      
    </div>
  );
};
