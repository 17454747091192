import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Calendar, Users, Lock, Brain, Building, ChevronRight, Github, Linkedin, Facebook, Check, Crown } from 'lucide-react';


export default function HomePage() {


const useElementOnScreen = (options: IntersectionObserverInit) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [options]);

  return [containerRef, isVisible] as const;
};

const [videoRef, isVideoVisible] = useElementOnScreen({
  threshold: 0.5
});





  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="min-h-[90vh] container mx-auto px-4 pt-20  lg:mt-40 lg:pt-0">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-12"
          >
            <h1 className="text-6xl lg:text-7xl font-bold tracking-tight">
              Build Your Billion-Dollar Company Today
            </h1>
            <h2 className="text-2xl lg:text-3xl font-bold text-gray-400 dark:text-gray-400 leading-relaxed">
              The Rules Have Changed. AI Makes It Possible. We Show You How.
            </h2>
            <Button 
              size="xl"
              className="text-lg px-8 md:px-14 font-bold py-6 rounded-full bg-blue-500 hover:bg-blue-500/80 transition-colors"
              onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
            >
              Join Our First 1,000 Founders - $49/month
            </Button>
          </motion.div>
        </div>
      </div>

      {/* Main Value Proposition */}
      <section className="py-26 container mx-auto px-4">
        <div className="max-w-6xl mx-auto space-y-16">
          <div className="text-center space-y-8">
            <h2 className="text-5xl lg:text-5xl font-bold tracking-tight">The New Rules of Company Building</h2>
            <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
              The playbook for building billion-dollar companies has been completely rewritten. Three massive shifts have made it possible for anyone to achieve what once seemed impossible:
            </p>
          </div>
          
          <div className="max-w-5xl mx-auto space-y-8">

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex p-8 rounded-3xl  bg-white dark:bg-gray-950"
            >
              <div className="w-1/3 flex items-center justify-center">
              <span
                style={{
                  fontSize: '20rem',
                  WebkitTextStroke: '1px #00B8FF', // Tailwind's blue-500 color
                  color: 'transparent',
                }}
                className="font-bold"
              >
                1
              </span>   

              </div>
              <div className="w-2/3 space-y-6">
                  <Brain className="h-10 w-10 mt-6 text-blue-500" />
                <h3 className="text-4xl font-bold mb-4">AI Replaces Teams</h3>
                <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
                  What once required 20 people, <span className="dark:text-white">you can now do alone.</span> Create complex software without coding, design without designers, and market without marketers.
                </p>
                <ul className="space-y-3">
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.6 }}
                    viewport={{ once: true }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Code complex applications using plain English</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 1 }}
                    viewport={{ once: true }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Generate professional designs instantly</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 1.4 }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Create marketing campaigns at scale</span>
                  </motion.li>
                  <motion.li 
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 1.8 }}
                  >
                    <Check className="h-5 w-5 text-blue-500" />
                    <span>Automate repetitive tasks</span>
                  </motion.li>
                </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="flex p-8 rounded-3xl  bg-white dark:bg-gray-950"
            >
              <div className="w-1/3 flex items-center justify-center">
              <span
                style={{
                  fontSize: '20rem',
                  WebkitTextStroke: '1px #FF00FF',
                  color: 'transparent',
                  fontFamily: 'Inter, Montserrat, system-ui, sans-serif',
                  fontWeight: '700',
                  lineHeight: '1',
                  display: 'block',
                }}
              >
                2
              </span>              
              </div>
              <div className="w-2/3 space-y-6">
                  <Users className="h-10 w-10 mt-4 text-violet-500" />
              <h3 className="text-4xl font-bold mb-4">Community Replaces Investors</h3>
              <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
              Forget venture capital. Build with passionate <span className="dark:text-white">early adopters who contribute more than money ever could.</span>
              </p>
              <ul className="space-y-3">
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Get real-time feedback from actual users</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Shape your product with community insights</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.4 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Create brand ambassadors organically</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.8 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-lg text-violet-500" />
                  <span>Build loyalty from day one</span>
                </motion.li>
              </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}
              className="flex p-8 rounded-3xl  bg-white dark:bg-gray-950"
            >
              <div className="w-1/3 flex items-center justify-center">
              <span
                style={{
                  fontSize: '20rem',
                  WebkitTextStroke: '1px green',
                  color: 'transparent',
                }}
                className="font-bold"
              >
                3
              </span>             
         </div>
              <div className="w-2/3 space-y-6">
                  <Crown className="h-10 w-10 mt-4 text-green-500" />
              <h3 className="text-4xl font-bold mb-4"> Ownership Drives Growth</h3>
              <p className="text-gray-600 font-bold text-xl dark:text-gray-500 mb-6">
              Instead of spending millions on marketing, <span className="dark:text-white">turn your users into owners.</span> Watch them become your most powerful growth engine.
              </p>
              <ul className="space-y-3">
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.6 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Give ownership instead of paying for ads</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Transform users into passionate evangelists</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.4 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Create viral growth through shared success</span>
                </motion.li>
                <motion.li 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 1.8 }}
                  viewport={{ once: true }}
                >
                  <Check className="h-5 w-5 text-green-500" />
                  <span>Build a community invested in your success</span>
                </motion.li>
              </ul>
              </div>
            </motion.div>
          </div>  
        </div>
      </section>


      {/* Video Section - full width */}
      <div className="w-full px-6">
                  <div ref={videoRef} className="w-full aspect-video relative">
                    <iframe
                      className="w-full h-full rounded-2xl border mt-20 border-gray-200 dark:border-gray-800"
                      src="https://www.youtube.com/embed/ZOt_XTVRdX4?rel=0&modestbranding=1&controls=1"
                      title="Ctrl AI Demo Video"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>


      {/* How We're Proving It */}
      <section className="py-32 bg-gradient-to-b from-blue-50 to-white dark:from-black dark:to-gray-950">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-white dark:bg-black rounded-3xl shadow-xl p-12"
            >
              <div className="text-center space-y-8 mb-12">
                <h2 className="text-5xl lg:text-5xl font-bold tracking-tight">How We're Proving It</h2>
                <p className="text-xl font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
                We're building Ctrl AI using exactly this model:
                </p>
              </div>
              <div className="space-y-8">
                {[
                  "Using AI to do the work of a 20-person team",
                  "Building with our community instead of venture capital",
                  "Giving 80% ownership to our first 100 million users",
                  "Early members get up to 16,000x larger share"
                ].map((text, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.3 }}
                    className="flex items-center space-x-4 p-4 max-w-2xl mx-auto rounded-full bg-white dark:bg-gray-950 border border-gray-200 dark:border-gray-800"
                  >
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.3 + 0.5 }}
                      className="h-10 w-10 rounded-full bg-gray-900 flex items-center justify-center"
                    >
                      <Check className="h-5 w-5 text-blue-500" />
                    </motion.div>
                    <span className="text-xl md:pl-2 text-gray-700 dark:text-gray-300">{text}</span>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Join Our Founding Members */}
      <section className="py-32 container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center space-y-12">
          <div className="space-y-4">
            <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Join Our Ctrl AI Founders Club</h2>
            <p className="text-xl lg:text-2xl text-gray-600 dark:text-gray-400 leading-relaxed">
              Get Everything You Need to Build Your Billion-Dollar Company
            </p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="rounded-3xl border-2 md:max-w-xl mx-auto border-gray-200 dark:border-gray-900 overflow-hidden"
          >
            <div className="p-12 bg-gray-900 text-white">
              <div className="flex items-baseline justify-center">
                <span className="text-3xl font-semibold">$49</span>
                <span className="text-xl ml-1">/month</span>
              </div>
              <p className="mt-2 text-blue-100">Limited to first 100 members at this rate</p>
            </div>

            <div className="p-12 space-y-8 bg-white dark:bg-gray-950">
              {[
                {
                  title: "Complete Blueprint",
                  description: "Step-by-step guide to build your billion-dollar company using our new model"
                },
                {
                  title: "Weekly Strategic Sessions",
                  description: "Join live sessions with our founder. See exactly how we're building Ctrl AI"
                },
                {
                  title: "Founder Community",
                  description: "Connect with 1,000 ambitious builders using this new model"
                },
                {
                  title: "Bonus: Ctrl AI Ownership",
                  description: "Earn a significant ownership stake in Ctrl AI (16,000x larger than later users)"
                }
              ].map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-4"
                >
                    <Check className="h-4 w-4 mt-1.5  text-blue-500" />
                  <div className="text-left">
                    <h3 className="font-semibold text-lg">{benefit.title}</h3>
                    <p className="text-gray-600 dark:text-gray-400 text-sm">{benefit.description}</p>
                  </div>
                </motion.div>
              ))}

              <Button 
                size="xl"
                className="text-lg w-full px-8 md:px-14 font-bold py-6 rounded-full bg-blue-500 hover:bg-blue-500/80 transition-colors"
                onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
              >
                Join Our First 1,000 Founders
              </Button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* See Our Model in Action */}
      <section className="py-32 bg-blue-50 dark:bg-gray-900/50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center space-y-8">
            <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">See Our Model in Action: The Ctrl AI Platform</h2>
            <p className="text-xl mb-26 font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">
              We're not just teaching theory - we're building Ctrl AI using exactly this model. Our platform helps professionals across 8,000+ fields map how to enhance 250,000+ tasks with AI.
            </p>
            <p className="text-xl mb-16 lg:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed">
              Our Progress So Far:
            </p>
          

            <Button 
              size="xl"
              variant="outline"
              className="mt-12 text-lg px-8 py-6 rounded-full border-2 hover:bg-blue-50 dark:hover:bg-blue-900/20"
              onClick={() => window.location.href = '/platform'}
            >
              Explore the Ctrl AI Platform in Public Beta →
            </Button>


          
          {/* Video Section - full width */}
          <div className="w-full px-6">
            <div ref={videoRef} className="w-full aspect-video relative">
              <iframe
                className="w-full h-full rounded-2xl border mt-20 border-gray-200 dark:border-gray-800"
                src="https://www.youtube.com/embed/6l5S9Gra0XM?rel=0&modestbranding=1&controls=1"
                title="Ctrl AI Demo Video"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>



          </div>
        </div>
      </section>

      {/* Final Call to Action */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="mt-16 mb-32 md:max-w-3xl mx-auto p-4 md:p-32 border-gray-200 dark:border-gray-900 rounded-2xl dark:text-white text-center"
      >
        <h2 className="text-4xl mb-12 lg:text-5xl font-bold tracking-tight">Don't Just Watch This Revolution—Be Part of It</h2>
        <p className="text-xl mb-32 font-bold lg:text-2xl lg:max-w-3xl mx-auto text-gray-600 dark:text-gray-500 leading-relaxed">The opportunity to build a billion-dollar company has never been more real. <span className="dark:text-white">AI has leveled the playing field. Community has replaced capital. And ownership drives growth.</span> </p>
        <Button 
          size="xl"
          className="text-lg px-8 md:px-14 font-bold py-6 rounded-full bg-blue-500 hover:bg-blue-500/80 transition-colors"
          onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
        >
          Join Our First 1,000 Founders - $49/month
        </Button>
        <p className="text-lg mt-8">Limited to first 100 members at this rate</p>
        <p className="text-2xl font-bold mt-32">Building something extraordinary starts today.</p>
      </motion.div>
    </div>
  );
}