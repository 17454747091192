import { RING_CONFIG, RingType } from "./ringConfig";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

interface RingProps {
  type: RingType;
  isOpen: boolean;
  onInteraction: (id: string) => void;
  visible: boolean;
  className?: string;
  currentStep?: number;
  setCurrentStep?: (step: number) => void;
  stepIndex?: number;
}

export const Ring: React.FC<RingProps> = ({ 
  type, 
  isOpen, 
  onInteraction, 
  visible,
  className = '',
  currentStep,
  setCurrentStep,
  stepIndex
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const config = RING_CONFIG[type];
  
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const getHoverAnimation = () => {
    if (type === 'mass' || type === 'network') {
      return {
        filter: 'brightness(1.1)'
      };
    }
    return {
      scale: 1.02,
      filter: 'brightness(1.1)'
    };
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (setCurrentStep && typeof stepIndex === 'number') {
      setCurrentStep(stepIndex);
    }
    onInteraction(config.id);
  };

  return (
    <div 
      className={`absolute inset-0 transition-opacity duration-500 ${visible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} ${className}`}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ 
          opacity: visible ? 1 : 0,
          scale: visible ? 1 : 0.8
        }}
        transition={{ duration: 0.5 }}
        whileHover={getHoverAnimation()}
        className={`ring-base ${type}-ring ${currentStep === stepIndex ? 'ring-active' : ''}`}
        onClick={handleClick}
        style={{ pointerEvents: 'auto' }}
      />
    </div>
  );
};