import React from 'react';
import { MetallicRings } from '../components/MetallicRings';
import { InvertedPyramid } from '../components/InvertedPyramid';

export default function RingsTest() {
    return (
    <div className="space-y-8">
      <MetallicRings />
      <InvertedPyramid />
    </div>
  );
};