import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { useAuth } from '../contexts/AuthContext';
import { useMediaQuery } from '../hooks/use-media-query';
import { FcGoogle } from "react-icons/fc";
import { toast } from './ui/use-toast';
import { useUserStore } from '../stores/userStore';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FaLinkedin } from "react-icons/fa";
import { getLinkedInAccessToken } from '../services/linkedinAuthService';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialMode?: 'signin' | 'signup';
}

export const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose, initialMode = 'signin' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isSignUp, setIsSignUp] = useState(initialMode === 'signup');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, signUp, signInWithGoogle, sendEmailVerification } = useAuth();
  const [verificationSent, setVerificationSent] = useState(false);
  const [isResendingVerification, setIsResendingVerification] = useState(false);

  useEffect(() => {
    setIsSignUp(initialMode === 'signup');
  }, [initialMode, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      if (isSignUp) {
        if (!name.trim()) {
          setError('Please enter your name');
          setIsLoading(false);
          return;
        }

        // Create the user first
        const userCredential = await signUp(email, password, name.trim());
        
        // Then get IP and location data
        try {
          // Get IP address from ipify API
          const ipResponse = await fetch('https://api.ipify.org?format=json');
          const ipData = await ipResponse.json();
          
          // Get location data from ipapi
          const locationResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
          const locationData = await locationResponse.json();
          
          // Save the metadata using the callable function
          const functions = getFunctions();
          const saveSignupMetadata = httpsCallable(functions, 'saveSignupMetadata');
          
          const metadataPayload = {
            ip: ipData.ip,
            estimatedLocation: {
              city: locationData.city,
              region: locationData.region,
              country: locationData.country_name,
              latitude: locationData.latitude,
              longitude: locationData.longitude
            }
          };
          
          await saveSignupMetadata(metadataPayload);
        } catch (metadataError) {
          if (metadataError instanceof Error) {
            console.error('Error saving signup metadata:', metadataError.message);
          }
        }

        if (userCredential.user) {
          // Store user ID and name for verification
          sessionStorage.setItem('verifyingUserId', userCredential.user.uid);
          sessionStorage.setItem('userName', name.trim());
          
          try {
            // Explicitly send verification email
            await sendEmailVerification();
            setVerificationSent(true);
            console.log('Verification email sent successfully');
          } catch (verificationError) {
            console.error('Error sending verification email:', verificationError);
          }
          
          // Navigate to verification page
          window.location.replace('/verify-email');
        }
      } else {
        const userCredential = await signIn(email, password);
        if (!userCredential.user.emailVerified) {
          setError('Please verify your email before signing in');
          await sendEmailVerification();
          setVerificationSent(true);
          return;
        }
        onClose();
      }
    } catch (error: any) {
      console.error('SignIn/SignUp error:', error);
      setError(error.message || 'An error occurred during sign in/up');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      setIsLoading(true);
      
      const result = await signInWithGoogle();
      
      if (result.user) {
        // Check if user document exists
        const docRef = doc(db, 'users', result.user.uid);
        const docSnap = await getDoc(docRef);
        
        if (!docSnap.exists()) {
          // Create new user document with Google profile data
          const now = new Date().toISOString();
          const newUserDoc = {
            id: result.user.uid,
            uid: result.user.uid,
            name: result.user.displayName || 'User',
            createdAt: now,
            updatedAt: now,
            isPremium: false,
            tasks: [],
            points: 0,
            aiLevel: 0,
            professionId: '',
            professionName: '',
            bio: '',
            location: '',
            website: '',
            profilePicture: {
              mainUrl: result.user.photoURL || '',
              thumbnailUrl: result.user.photoURL || ''
            },
            lastProfessionChange: now,
            ogData: {
              imageUrl: null,
              generated: false,
              lastGenerated: null
            }
          };
          
          await setDoc(docRef, newUserDoc);
        }
        
        await useUserStore.getState().fetchUserData(result.user.uid);
      }
      
      onClose();
    } catch (error: any) {
      setError(error.message || 'An error occurred during Google sign in');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkedInSignIn = async () => {
    try {
      setError(null);
      setIsLoading(true);
      
      const state = Math.random().toString(36).substring(7);
      sessionStorage.setItem('linkedInState', state);
      
      const linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?` +
        `response_type=code` +
        `&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}` +
        `&redirect_uri=${encodeURIComponent(process.env.REACT_APP_URL + '/auth/linkedin/callback')}` +
        `&state=${state}` +
        `&scope=openid profile email`;

      console.log('LinkedIn URL:', linkedInUrl);
      window.location.href = linkedInUrl;
    } catch (error: any) {
      console.error('LinkedIn sign in error:', error);
      setError(error.message || 'An error occurred during LinkedIn sign in');
    } finally {
      setIsLoading(false);
    }
  };

  const renderVerificationMessage = () => {
    if (!verificationSent) return null;
    
    return (
      <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-md mb-4">
        <p className="text-sm text-yellow-800 dark:text-yellow-200">
          A verification email has been sent to {email}. Please check your inbox and spam folder to verify your email before signing in.
        </p>
        <Button 
          variant="link" 
          className="text-sm p-0 h-auto text-yellow-800 dark:text-yellow-200 underline"
          disabled={isResendingVerification}
          onClick={async () => {
            try {
              setIsResendingVerification(true);
              await sendEmailVerification();
              toast({
                title: "Verification email sent",
                description: "Please check your inbox and spam folder",
              });
            } catch (error) {
              toast({
                title: "Error",
                description: "Failed to send verification email. Please try again later.",
                variant: "destructive",
              });
            } finally {
              setIsResendingVerification(false);
            }
          }}
        >
          {isResendingVerification ? 'Sending...' : 'Resend verification email'}
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{isSignUp ? 'Sign Up' : 'Sign In'}</DialogTitle>
          <DialogDescription>
            {isSignUp 
              ? 'Create your account to save your progress and personalize your badge.'
              : 'Sign in to your account to continue your AI journey.'}
          </DialogDescription>
        </DialogHeader>
        {renderVerificationMessage()}
        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {isSignUp && (
            <Input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={isSignUp}
            />
          )}
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Button>
        </form>
        <Button onClick={handleGoogleSignIn} variant="outline" className="w-full mt-2">
          <FcGoogle className="mr-2 h-5 w-5" />
          {isSignUp ? 'Sign up with Google' : 'Sign in with Google'}
        </Button>
        <Button onClick={handleLinkedInSignIn} variant="outline" className="w-full mt-2">
          <FaLinkedin className="mr-2 h-5 w-5 text-[#0A66C2]" />
          {isSignUp ? 'Sign up with LinkedIn' : 'Sign in with LinkedIn'}
        </Button>
        <Button onClick={() => setIsSignUp(!isSignUp)} variant="link" className="w-full mt-2">
          {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
