import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { processImage } from './imageUtils';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const uploadProfilePicture = async (userId: string, file: File) => {
  try {
    // Process images
    const [mainImage, thumbnailImage] = await Promise.all([
      processImage(file, 400),
      processImage(file, 80)
    ]);

    // Upload both versions
    const mainRef = ref(storage, `users/${userId}/profile/main.jpg`);
    const thumbnailRef = ref(storage, `users/${userId}/profile/thumbnail.jpg`);

    await Promise.all([
      uploadBytes(mainRef, mainImage),
      uploadBytes(thumbnailRef, thumbnailImage)
    ]);

    // Get download URLs
    const [mainUrl, thumbnailUrl] = await Promise.all([
      getDownloadURL(mainRef),
      getDownloadURL(thumbnailRef)
    ]);

    // Update user document in Firestore to match the User interface
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      profilePicture: {
        mainUrl,
        thumbnailUrl
      }
    });

    return {
      mainUrl,
      thumbnailUrl
    };
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
}; 