import { create } from 'zustand';
import { 
  doc, 
  getDoc, 
  setDoc,
  updateDoc, 
  writeBatch, 
  increment,
  serverTimestamp,
  Timestamp,
  arrayUnion,
  arrayRemove,
  runTransaction,
  deleteField
} from 'firebase/firestore';
import { db } from '../firebase';
import { Insight, InsightCategory } from '../types';

interface InsightData {
  userId: string;
  userName: string;
  aiToolId: string;
  aiToolName: string;
  content: string;
  category: InsightCategory;
  createdAt: Timestamp;
  likes: number;
  imageId?: string;
  likedBy: string[];
  timeSaved: number;
  challengeSolution?: string;
  resourceUrl?: string;
  resourceTitle?: string;
  metricValue?: number;
  metricUnit?: string;
}

interface TaskInsightsDocument {
  taskId: string;
  totalInsights: number;
  lastUpdated: Timestamp;
  insights: {
    [key: string]: InsightData;
  };
}

interface InsightState {
  taskInsights: Record<string, TaskInsightsDocument>;
  isLoading: boolean;
  error: string | null;

  fetchTaskInsights: (taskId: string) => Promise<void>;
  addInsight: (taskId: string, insight: Omit<Insight, 'id'>) => Promise<string>;
  likeInsight: (taskId: string, insightId: string, userId: string) => Promise<void>;
  unlikeInsight: (taskId: string, insightId: string, userId: string) => Promise<void>;
  deleteInsight: (taskId: string, insightId: string) => Promise<void>;
}

export const useInsightStore = create<InsightState>((set, get) => ({
  taskInsights: {},
  isLoading: false,
  error: null,

  fetchTaskInsights: async (taskId: string) => {
    try {
      set({ isLoading: true });
      
      const taskInsightRef = doc(db, 'taskInsights', taskId);
      const taskInsightDoc = await getDoc(taskInsightRef);
      
      if (taskInsightDoc.exists()) {
        const insightsData = taskInsightDoc.data() as TaskInsightsDocument;
        set((state) => ({
          ...state,
          taskInsights: {
            ...state.taskInsights,
            [taskId]: insightsData
          },
          isLoading: false
        }));
      }
    } catch (error) {
      console.error('Error fetching task insights:', error);
      set({ error: (error as Error).message, isLoading: false });
    }
  },

  addInsight: async (taskId: string, insight: Omit<Insight, 'id'>) => {
    try {
      set({ isLoading: true });
      const insightId = crypto.randomUUID();

      const baseInsightData = {
        userId: insight.userId,
        userName: insight.userName,
        aiToolId: insight.aiToolId,
        aiToolName: insight.aiToolName,
        category: insight.category,
        content: insight.content,
        createdAt: serverTimestamp(),
        likes: 0,
        likedBy: [],
        timeSaved: insight.timeSaved,
        ...(insight.imageId && { imageId: insight.imageId }),
      };

      const categoryFields = {
        ...(insight.category === 'challenges' && insight.challengeSolution && {
          challengeSolution: insight.challengeSolution
        }),
        ...(insight.category === 'resources' && insight.resourceUrl && {
          resourceUrl: insight.resourceUrl,
          resourceTitle: insight.resourceTitle
        }),
        ...(insight.category === 'metrics' && insight.metricValue && {
          metricValue: insight.metricValue,
          metricUnit: insight.metricUnit
        })
      };

      const insightData = {
        ...baseInsightData,
        ...categoryFields
      };

      const taskInsightRef = doc(db, 'taskInsights', taskId);
      const taskInsightDoc = await getDoc(taskInsightRef);

      if (!taskInsightDoc.exists()) {
        await setDoc(taskInsightRef, {
          taskId,
          totalInsights: 1,
          lastUpdated: serverTimestamp(),
          insights: {
            [insightId]: insightData
          }
        });
      } else {
        await updateDoc(taskInsightRef, {
          [`insights.${insightId}`]: insightData,
          totalInsights: increment(1),
          lastUpdated: serverTimestamp()
        });
      }

      const userRef = doc(db, 'users', insight.userId);
      await updateDoc(userRef, {
        [`insights.${taskId}.${insightId}`]: {
          createdAt: serverTimestamp(),
          taskId,
          aiToolId: insight.aiToolId,
          category: insight.category,
          ...(insight.imageId && { imageId: insight.imageId }),
        }
      });

      await get().fetchTaskInsights(taskId);

      return insightId;
    } catch (error) {
      console.error('Error in addInsight:', error);
      set({ error: (error as Error).message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  likeInsight: async (taskId: string, insightId: string, userId: string) => {
    const taskInsightRef = doc(db, 'taskInsights', taskId);

    set(state => ({
      taskInsights: {
        ...state.taskInsights,
        [taskId]: {
          ...state.taskInsights[taskId],
          insights: {
            ...state.taskInsights[taskId].insights,
            [insightId]: {
              ...state.taskInsights[taskId].insights[insightId],
              likes: state.taskInsights[taskId].insights[insightId].likes + 1,
              likedBy: [...(state.taskInsights[taskId].insights[insightId].likedBy || []), userId]
            }
          }
        }
      }
    }));

    try {
      await runTransaction(db, async (transaction) => {
        const taskInsightDoc = await transaction.get(taskInsightRef);
        if (!taskInsightDoc.exists()) {
          throw new Error("Task insights document does not exist!");
        }

        const insightData = taskInsightDoc.data().insights[insightId];
        if (!insightData) {
          throw new Error("Insight does not exist!");
        }

        const likedBy = insightData.likedBy || [];
        if (likedBy.includes(userId)) {
          throw new Error("Already liked!");
        }

        transaction.update(taskInsightRef, {
          [`insights.${insightId}.likes`]: increment(1),
          [`insights.${insightId}.likedBy`]: arrayUnion(userId),
          lastUpdated: serverTimestamp()
        });

        const userRef = doc(db, 'users', userId);
        transaction.update(userRef, {
          [`likedInsights.${taskId}.${insightId}`]: serverTimestamp()
        });
      });
    } catch (error) {
      set(state => ({
        taskInsights: {
          ...state.taskInsights,
          [taskId]: {
            ...state.taskInsights[taskId],
            insights: {
              ...state.taskInsights[taskId].insights,
              [insightId]: {
                ...state.taskInsights[taskId].insights[insightId],
                likes: state.taskInsights[taskId].insights[insightId].likes - 1,
                likedBy: (state.taskInsights[taskId].insights[insightId].likedBy || [])
                  .filter(id => id !== userId)
              }
            }
          }
        }
      }));
      throw error;
    }
  },

  unlikeInsight: async (taskId: string, insightId: string, userId: string) => {
    const taskInsightRef = doc(db, 'taskInsights', taskId);

    set(state => ({
      taskInsights: {
        ...state.taskInsights,
        [taskId]: {
          ...state.taskInsights[taskId],
          insights: {
            ...state.taskInsights[taskId].insights,
            [insightId]: {
              ...state.taskInsights[taskId].insights[insightId],
              likes: state.taskInsights[taskId].insights[insightId].likes - 1,
              likedBy: (state.taskInsights[taskId].insights[insightId].likedBy || [])
                .filter(id => id !== userId)
            }
          }
        }
      }
    }));

    try {
      await runTransaction(db, async (transaction) => {
        const taskInsightDoc = await transaction.get(taskInsightRef);
        if (!taskInsightDoc.exists()) {
          throw new Error("Task insights document does not exist!");
        }

        const insightData = taskInsightDoc.data().insights[insightId];
        if (!insightData) {
          throw new Error("Insight does not exist!");
        }

        const likedBy = insightData.likedBy || [];
        if (!likedBy.includes(userId)) {
          throw new Error("Not liked yet!");
        }

        transaction.update(taskInsightRef, {
          [`insights.${insightId}.likes`]: increment(-1),
          [`insights.${insightId}.likedBy`]: arrayRemove(userId),
          lastUpdated: serverTimestamp()
        });

        const userRef = doc(db, 'users', userId);
        transaction.update(userRef, {
          [`likedInsights.${taskId}.${insightId}`]: deleteField()
        });
      });
    } catch (error) {
      set(state => ({
        taskInsights: {
          ...state.taskInsights,
          [taskId]: {
            ...state.taskInsights[taskId],
            insights: {
              ...state.taskInsights[taskId].insights,
              [insightId]: {
                ...state.taskInsights[taskId].insights[insightId],
                likes: state.taskInsights[taskId].insights[insightId].likes + 1,
                likedBy: [...(state.taskInsights[taskId].insights[insightId].likedBy || []), userId]
              }
            }
          }
        }
      }));
      throw error;
    }
  },

  deleteInsight: async (taskId: string, insightId: string) => {
    const taskInsightRef = doc(db, 'taskInsights', taskId);

    set(state => {
      const newState = { ...state };
      delete newState.taskInsights[taskId].insights[insightId];
      return newState;
    });

    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(taskInsightRef);
        if (!doc.exists()) throw new Error("Document not found");

        const insights = doc.data().insights;
        delete insights[insightId];

        transaction.update(taskInsightRef, {
          insights,
          totalInsights: increment(-1),
          lastUpdated: serverTimestamp()
        });
      });
    } catch (error) {
      await get().fetchTaskInsights(taskId);
      throw error;
    }
  }
}));
