// src/stores/aiToolStore.ts

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export interface AIToolModel {
  id: string;
  name: string;
  description: string;
}

export interface AITool {
  aiToolId: string;
  aiToolName: string;
  aiToolLink: string;
  description: string;
  visits: number;
  order: number;
  lastUpdated: string;
  taskIds: string[];
  userCount: number;
  hasModels?: boolean;
  models?: AIToolModel[];
}

interface AIToolState {
  aiTools: Record<string, AITool[]>;
  lastFetched: Record<string, number>;
  isLoading: boolean;
  error: string | null;
  fetchAITools: (professionId: string) => Promise<void>;
}

export const useAIToolStore = create<AIToolState>()(
  persist(
    (set, get) => ({
      aiTools: {},
      lastFetched: {},
      isLoading: false,
      error: null,

      fetchAITools: async (professionId: string) => {
        try {
          set({ isLoading: true, error: null });
          const docRef = doc(collection(db, 'aitoollist'), 'general');
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const data = docSnap.data();

            const tools = data.aitools.map((tool: any) => {
              const mappedTool = {
                aiToolId: tool.id,
                aiToolName: tool.name,
                aiToolLink: tool.link || '',
                description: tool.description || '',
                visits: tool.visits || 0,
                lastUpdated: tool.lastUpdated || '',
                taskIds: tool.taskIds || [],
                userCount: tool.userCount || 0,
                hasModels: tool.hasModels || false,
                models: tool.models || []
              };
              return mappedTool;
            });


            set(state => ({ 
              aiTools: {
                ...state.aiTools,
                general: tools
              },
              lastFetched: {
                ...state.lastFetched,
                general: Date.now()
              },
              isLoading: false
            }));
          }
        } catch (error) {
          console.error('Error in fetchAITools:', error);
          set({ error: 'Failed to fetch AI tools', isLoading: false });
        }
      },
    }),
    {
      name: 'ai-tools-storage',
      partialize: (state) => ({ 
        aiTools: state.aiTools,
        lastFetched: state.lastFetched
      }),
    }
  )
);
