export type RingType = 'mass' | 'network' | 'bronze' | 'silver' | 'gold' | 'founder';

interface BaseRingConfig {
  id: string;
  title: string;
  share: string;
  subtitle: string;
}

interface MemberRingConfig extends BaseRingConfig {
  members: string;
}

type RingConfig = MemberRingConfig | BaseRingConfig;

export const RING_CONFIG: Record<RingType, RingConfig> = {
  mass: {
    id: 'mass',
    title: 'Mass Ring',
    members: '10,000,000',
    share: '20%',
    subtitle: 'Public Distribution'
  },
  network: {
    id: 'network',
    title: 'Network Ring',
    members: '1,000,000',
    share: '20%',
    subtitle: 'Network Distribution'
  },
  bronze: {
    id: 'bronze',
    title: 'Scale Ring',
    members: '100,000',
    share: '15%',
    subtitle: 'Bronze Tier'
  },
  silver: {
    id: 'silver',
    title: 'Growth Ring',
    members: '10,000',
    share: '15%',
    subtitle: 'Silver Tier'
  },
  gold: {
    id: 'gold',
    title: 'Core Ring',
    members: '1,000',
    share: '15%',
    subtitle: 'Gold Tier'
  },
  founder: {
    id: 'founder',
    title: 'Founder',
    share: '10%',
    subtitle: 'Share after final distribution'
  }
};