// src/stores/professionStore.ts

import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { ProfessionBasic, ProfessionDetailed } from '../types';

export interface ProfessionTask {
  id: string;
  description: string;
  type: 'common' | 'specific';
  source: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  userCount?: number;
  aiToolIds?: string[];
  commentsCount?: number;
}

interface ProfessionState {
  professionList: ProfessionBasic[];
  detailedProfessions: Record<string, ProfessionDetailed>;
  lastFetched: number | null;
  isLoading: boolean;
  error: string | null;
  fetchProfessionList: () => Promise<void>;
  fetchProfessionDetails: (titleSOC: string) => Promise<void>;
  getTasksForJobTitle: (titleSOC: string, jobTitleId: string) => ProfessionTask[];
}

export const useProfessionStore = create<ProfessionState>()(
  persist(
    (set, get) => ({
      professionList: [],
      detailedProfessions: {},
      lastFetched: null,
      isLoading: false,
      error: null,

      fetchProfessionList: async () => {
        const { lastFetched, professionList } = get();
        const now = Date.now();
        const CACHE_DURATION = 24 * 60 * 60 * 1000;

        if (lastFetched && professionList.length > 0 && (now - lastFetched < CACHE_DURATION)) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const docRef = doc(db, 'professionlist', 'all_professions');
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            set({ 
              professionList: docSnap.data().jobs,
              lastFetched: now,
              isLoading: false 
            });
          } else {
            set({ error: 'No profession data found', isLoading: false });
          }
        } catch (error) {
          console.error('Error fetching profession list:', error);
          set({ error: 'Failed to fetch professions', isLoading: false });
        }
      },

      fetchProfessionDetails: async (titleSOC: string) => {
        if (get().detailedProfessions[titleSOC]) return;

        try {
          const docRef = doc(db, 'professions', titleSOC);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            set(state => ({
              detailedProfessions: {
                ...state.detailedProfessions,
                [titleSOC]: docSnap.data() as ProfessionDetailed
              }
            }));
          }
        } catch (error) {
          console.error('Error fetching profession details:', error);
        }
      },

      getTasksForJobTitle: (titleSOC: string, jobTitleId: string): ProfessionTask[] => {
        const profession = get().detailedProfessions[titleSOC];
        if (!profession) return [];

        const commonTasks = profession.commonTasks || [];
        const specificTasks = profession.jobTitles[jobTitleId]?.tasks || [];

        return [...commonTasks, ...specificTasks];
      },
    }),
    {
      name: 'profession-storage',
      partialize: (state) => ({ 
        professionList: state.professionList,
        detailedProfessions: state.detailedProfessions,
        lastFetched: state.lastFetched
      }),
    }
  )
);
