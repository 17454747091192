import { Lightbulb, Target, BookOpen, Wrench, LineChart } from 'lucide-react';

export const categoryConfig = {
  tips: {
    icon: Lightbulb,
    label: 'Tips',
    placeholder: 'Share practical advice that has enhanced your efficiency...',
    description: 'Share practical advice that has enhanced your efficiency'
  },
  useCase: {
    icon: Target,
    label: 'Use Case',
    placeholder: 'Describe specific instances where AI made a difference...',
    description: 'Describe specific instances where AI made a difference'
  },
  resources: {
    icon: BookOpen,
    label: 'Resource',
    placeholder: 'Recommend helpful guides or tools...',
    description: 'Recommend helpful guides or tools'
  },
  challenges: {
    icon: Wrench,
    label: 'Challenge',
    placeholder: 'Discuss obstacles you\'ve overcome and solutions found...',
    description: 'Discuss obstacles you\'ve overcome and solutions found'
  },
  metrics: {
    icon: LineChart,
    label: 'Metrics',
    placeholder: 'Share quantifiable results achieved through AI integration...',
    description: 'Share quantifiable results achieved through AI integration'
  }
} as const;

export type InsightCategory = keyof typeof categoryConfig;
