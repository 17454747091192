import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Calendar, Users, Lock, Brain, Building, ChevronRight, Github, Linkedin, Facebook, Check } from 'lucide-react';

export default function SkoolPage() {
  return (
    <div className="min-h-screen ">
      {/* Hero Section - Improved typography and spacing */}
      <div className="min-h-[90vh] container mx-auto px-4 pt-20 lg:pt-0">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-8"
          >
            <div className="inline-flex items-center gap-2 text-blue-500 font-medium px-4 py-2 mt-12 bg-blue-50 dark:bg-blue-900/20 rounded-full">
              <Calendar className="w-4 h-4" />
              <span className="text-sm">December 1st, 2024 | Limited to 1,000 Founding Members</span>
            </div>
            
            <h1 className="text-4xl lg:text-7xl font-bold tracking-tight">
              Exclusive Ctrl AI Launch for{' '}
              <div className="inline-flex">
                <span className="text-[#263397]">s</span>
                <span className="text-[#D3513E]">k</span>
                <span className="text-[#E0B467]">o</span>
                <span className="text-[#6BB7EE]">o</span>
                <span className="text-[#C45946]">l</span>
              </div>{' '}
              Community
            </h1>
            
            <div className="space-y-6">
              <p className="text-xl lg:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed">
                AI will transform 8,000+ professions in the next 5 years. The knowledge of HOW professionals implement AI is the final piece big tech doesn't own yet.
              </p>
              <p className="text-xl lg:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed">
                We're giving our first 1,000 members the chance to own this transformation.
              </p>
            </div>

            <div className="flex flex-col sm:flex-row gap-4 pt-8">
              <Button 
                size="lg"
                variant="outline"
                className="text-lg px-8 py-6 rounded-full border-2 hover:bg-blue-50 dark:hover:bg-blue-900/20"
                onClick={() => window.location.href = 'https://www.skool.com/ctrlaicom-free-6500'}
              >
                Join Free Community
                <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
              <Button 
                size="lg"
                className="text-lg px-8 py-6 rounded-full bg-blue-500 hover:bg-blue-700 transition-colors"
                onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
              >
                Join Founding Members $49/month
                <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Why Skool Section - Added visual interest */}
      <section className="py-32 container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center space-y-8">
          <span className="text-blue-500 font-medium">Why skool</span>
          <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Why We Chose skool</h2>
          <p className="text-xl lg:text-2xl text-gray-600 dark:text-gray-300 leading-relaxed">
            We found something special in skool. 
            This community represents the future of product development - built WITH users, 
            not FOR them. No other platform matches skool's potential for fostering the next 
            era of innovation.
          </p>
        </div>
      </section>

      {/* Founding Member Benefits - Improved card design */}
      <section className="py-32 bg-white dark:bg-gray-900/50">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <div className="text-center space-y-8 mb-16">
              <span className="text-blue-500 font-medium">Exclusive Benefits</span>
              <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">The Founding Member Opportunity</h2>
            </div>
            
            <div className="grid lg:grid-cols-3 gap-8">
              <motion.div 
                whileHover={{ y: -8 }}
                transition={{ duration: 0.3 }}
                className="p-8 bg-white dark:bg-gray-900 rounded-2xl  hover:shadow-xl transition-shadow"
              >
                <Lock className="w-12 h-12 text-blue-500 mb-6" />
                <h3 className="text-2xl font-bold mb-6">Ownership That Matters</h3>
                <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>1% direct ownership to first 1,000 members</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>50,000x larger share than later users</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Part of total 80% community ownership plan</span>
                  </li>
                </ul>
              </motion.div>

              <motion.div 
                whileHover={{ y: -8 }}
                transition={{ duration: 0.3 }}
                className="p-8 bg-white dark:bg-gray-900 rounded-2xl  hover:shadow-xl transition-shadow"
              >
                <Users className="w-12 h-12 text-blue-500 mb-6" />
                <h3 className="text-2xl font-bold mb-6">Platform Influence</h3>
                <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Weekly private strategy sessions</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Feature prioritization voting</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Shape core platform features</span>
                  </li>
                </ul>
              </motion.div>

              <motion.div 
                whileHover={{ y: -8 }}
                transition={{ duration: 0.3 }}
                className="p-8 bg-white dark:bg-gray-900 rounded-2xl  hover:shadow-xl transition-shadow"
              >
                <Brain className="w-12 h-12 text-blue-500 mb-6" />
                <h3 className="text-2xl font-bold mb-6">Knowledge Edge</h3>
                <ul className="space-y-4 text-gray-600 dark:text-gray-300">
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Early access to AI strategies</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Direct guidance from industry experts</span>
                  </li>
                  <li className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-blue-500 mt-1 flex-shrink-0" />
                    <span>Exclusive case studies & playbooks</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </section>


      {/* Updated Historical Context Section */}
      <section className="py-32 bg-blue-50 dark:bg-gray-900/50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="text-center space-y-8 mb-16">
              <span className="text-blue-500 font-medium">Learn From History</span>
              <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Historical Context</h2>
            </div>
            
            <div className="grid lg:grid-cols-2 gap-12 items-center">
              {/* Left side - Facebook stat */}
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="px-8 py-12 bg-white dark:bg-gray-900 rounded-2xl "
              >
                <div className="space-y-6 text-center">
                  <Facebook className="w-16 h-16 text-blue-500 mx-auto" />
                  <div className="space-y-2">
                    <p className="text-5xl font-bold text-gray-900 dark:text-gray-100">$15M</p>
                    <p className="text-xl text-gray-600 dark:text-gray-300">
                      Potential value per early Facebook user
                    </p>
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 text-lg">
                    If Facebook had given ownership to their first 1,000 users, each would own $15M in Meta stock today.
                  </p>
                </div>
              </motion.div>

              {/* Right side - Message */}
              <div className="space-y-8">
                <div className="space-y-4">
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    This isn't just about money - it's about control
                  </h3>
                  <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed">
                    While Facebook's early users helped build its value, they ended up with nothing. 
                    Their data, connections, and contributions became someone else's asset.
                  </p>
                </div>

                <div className="space-y-4">
                  <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                    This Time Be Different
                  </h3>
                  <ul className="space-y-4">
                    {[
                      'Be an owner, not just a user',
                      'Shape the platform\'s future',
                      'Keep AI knowledge in the hands of professionals'
                    ].map((item, index) => (
                      <li key={index} className="flex items-center gap-3">
                        <div className="h-2 w-2 rounded-full bg-blue-500 flex-shrink-0" />
                        <span className="text-lg text-gray-600 dark:text-gray-300">{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </section>

      {/* FAQ Section - Improved accordion style */}
      <section className="py-32">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="text-center space-y-8 mb-16">
              <span className="text-blue-500 font-medium">Got Questions?</span>
              <h2 className="text-4xl lg:text-5xl font-bold tracking-tight">Frequently Asked Questions</h2>
            </div>
            
            <div className="space-y-6">
              {[
                {
                  q: "Why only 1,000 members?",
                  a: "Quality over quantity. We've learned that 1,000 members create optimal engagement, allowing everyone's voice to be heard and real relationships to form."
                },
                {
                  q: "How is ownership structured?",
                  a: "Ownership is held through a Swiss entity in Zug, ensuring maximum security and transparency for all token holders."
                },
                {
                  q: "What makes this community different?",
                  a: "We're building the first platform where professionals who document AI implementation actually own the platform they're building."
                }
              ].map((faq, index) => (
                <motion.div
                  key={index}
                  initial={false}
                  whileHover={{ scale: 1.01 }}
                  className="p-6 bg-white dark:bg-gray-900 rounded-xl hover:shadow-md transition-shadow"
                >
                  <h3 className="text-xl font-bold mb-3">{faq.q}</h3>
                  <p className="text-gray-600 dark:text-gray-300">{faq.a}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA - More impactful design */}

      <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="mt-16 mb-32 p-4 md:p-32 border-gray-200 dark:border-gray-900 rounded-2xl text-white text-center"
            >
            <h2 className="text-4xl mb-12 lg:text-5xl font-bold tracking-tight">Ready to Join the Movement?</h2>
            <p className="text-xl opacity-90 mb-6">Become part of something revolutionary.</p>
            <div className="flex flex-col md:flex-row gap-4 justify-center pt-8">
              <Button 
                size="lg"
                variant="outline"
                className="text-lg px-8 py-6 rounded-full border-2 hover:bg-blue-50 dark:hover:bg-blue-900/20"
                onClick={() => window.location.href = 'https://www.skool.com/ctrlaicom-free-6500'}
              >
                Join Free Community
                <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
              <Button 
                size="lg"
                className="text-lg px-8 py-6 rounded-full bg-blue-500 hover:bg-blue-700 transition-colors"
                onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
              >
                Join Founding Members $49/month
                <ChevronRight className="ml-2 w-5 h-5" />
              </Button>
            </div>
            </motion.div>



 
    </div>
  );
}