import React, { useState, useEffect } from 'react';
import { Insight } from '../types';
import { Avatar } from './ui/avatar';
import { Button } from './ui/button';
import { Badge } from './ui/badge';
import { Heart, Clock, Timer, MoreHorizontal } from 'lucide-react';
import { categoryConfig } from '../config/insightCategories';
import { Separator } from './ui/separator';
import { formatDistanceToNow } from 'date-fns';
import { DEFAULT_PROFILE_PICTURE } from '../constants';
import { Lightbulb, Target, BookOpen, Wrench, LineChart } from 'lucide-react';
import { useUserStore } from '../stores/userStore';
import { useInsightStore } from '../stores/insightStore';
import { toast } from './ui/use-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Trash2, Flag } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { doc, collection, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Textarea } from './ui/textarea';
import { extractYoutubeVideoId } from '../utils/youtubeUtils';
import { YoutubeEmbed } from './YoutubeEmbed';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { useThemeStore } from '../stores/themeStore';
import { Brain } from 'lucide-react';
import { ProfileAvatar } from './ui/ProfileAvatar';

interface InsightCardProps {
  insight: Insight;
  insightId: string;
  taskId: string;
  isLastCard?: boolean;
}

const REPORT_REASONS = [
  { value: 'spam', label: 'Spam or misleading' },
  { value: 'inappropriate', label: 'Inappropriate content' },
  { value: 'offensive', label: 'Offensive language' },
  { value: 'other', label: 'Other' }
] as const;

type ReportReason = typeof REPORT_REASONS[number]['value'];

export const InsightCard: React.FC<InsightCardProps> = ({ insight, insightId, taskId, isLastCard = false }) => {
  const { currentUser } = useUserStore();
  const { likeInsight, unlikeInsight, deleteInsight } = useInsightStore();
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [reportReason, setReportReason] = useState<ReportReason>('spam');
  const [reportDescription, setReportDescription] = useState('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const { isDarkMode } = useThemeStore();



  useEffect(() => {
    const loadImage = async () => {
      if (insight.imageId) {
        try {
          const imageUrl = `https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/users%2F${insight.userId}%2Finsights%2F${insight.imageId}.jpg?alt=media`;
          setImageUrl(imageUrl);
        } catch (err) {
          console.error('Failed to load image:', err);
          setImageUrl(null);
        }
      }
    };

    loadImage();
  }, [insight.imageId, insight.userId]);

  const handleDelete = async () => {
    try {
      await deleteInsight(taskId, insightId);
      toast({
        title: "Success",
        description: "Insight deleted successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete insight",
        variant: "destructive"
      });
    }
  };

  const handleReport = async () => {
    try {
      if (!currentUser) throw new Error("Must be logged in to report");
      
      const reportRef = doc(collection(db, 'reports'));
      await setDoc(reportRef, {
        reportId: reportRef.id,
        insightId,
        taskId,
        reportedBy: currentUser.id,
        reportedAt: serverTimestamp(),
        reason: reportReason,
        description: reportDescription,
        status: 'pending',
        insightContent: {
          content: insight.content,
          category: insight.category,
          authorId: insight.userId,
          authorName: insight.userName
        }
      });

      setIsReportDialogOpen(false);
      setReportReason('spam');
      setReportDescription('');
      
      toast({
        title: "Report submitted",
        description: "Thank you for helping keep our community safe",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit report",
        variant: "destructive"
      });
    }
  };

  const isLiked = insight.likedBy?.includes(currentUser?.id || '');

  const handleLike = async () => {
    if (!currentUser) {
      toast({
        title: "Sign in required",
        description: "Please sign in to like insights",
        variant: "destructive"
      });
      return;
    }

    try {
      if (isLiked) {
        await unlikeInsight(taskId, insightId, currentUser.id);
      } else {
        await likeInsight(taskId, insightId, currentUser.id);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update like",
        variant: "destructive"
      });
    }
  };

  // Helper function to get the correct profile image URL
  const getProfileImageUrl = (userId: string) => {
    const defaultImage = isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light;
    // Always return an array with default first, then custom
    return [
      defaultImage,
      `https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/users%2F${userId}%2Fprofile%2Fthumbnail.jpg?alt=media`
    ];
  };

  const CategoryIcon = categoryConfig[insight.category].icon;

  const renderContent = () => {
    const youtubeId = extractYoutubeVideoId(insight.content);
    
    return (
      <div className="ml-13">
        <p className="text-base">{insight.content}</p>
        
        {/* Image rendering */}
        {insight.imageId && imageUrl && (
          <div className="mt-4">
            <img
              src={imageUrl}
              alt="Insight image"
              className="rounded-lg max-w-full md:max-w-[70%] h-auto"
              loading="lazy"
              onError={(e) => {
                console.error('Image failed to load:', imageUrl);
                (e.target as HTMLImageElement).style.display = 'none';
              }}
            />
          </div>
        )}

        {youtubeId && (
          <div className="mt-8">
            <YoutubeEmbed videoId={youtubeId} />
          </div>
        )}
        
        {/* Category-specific content */}
        {insight.category === 'challenges' && insight.challengeSolution && (
          <div className="mt-3 p-3 bg-muted rounded-md">
            <p className="text-sm font-semibold">Solution:</p>
            <p className="text-sm mt-1">{insight.challengeSolution}</p>
          </div>
        )}
        
        {insight.category === 'resources' && insight.resourceUrl && (
          <div className="mt-3">
            {extractYoutubeVideoId(insight.resourceUrl) ? (
              <YoutubeEmbed videoId={extractYoutubeVideoId(insight.resourceUrl)!} />
            ) : (
              <a 
                href={insight.resourceUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 p-3 bg-muted rounded-md hover:bg-muted/80 transition-colors"
              >
                <span>📚</span>
                <div>
                  <p className="font-medium">{insight.resourceTitle}</p>
                  <p className="text-sm text-muted-foreground">{insight.resourceUrl}</p>
                </div>
              </a>
            )}
          </div>
        )}
        
        {insight.category === 'metrics' && insight.metricValue && (
          <div className="mt-3 p-3 bg-muted rounded-md">
            <p className="text-lg font-semibold">
              {insight.metricValue}{insight.metricUnit}
            </p>
          </div>
        )}

        {/* Like button */}
        <div className="mt-4 flex items-center gap-2">
          <Button 
            variant="ghost" 
            size="sm"
            className={`hover:text-red-500 transition-colors rounded-full ${
              isLiked ? 'text-red-500' : ''
            }`}
            onClick={handleLike}
          >
            <Heart className={`h-5 w-5 ${isLiked ? 'fill-red-500' : ''}`} />
            {insight.likes > 0 && <span className="ml-2">{insight.likes}</span>}
          </Button>
        </div>
      </div>
    );
  };

  const renderAIToolName = (aiToolName: string) => {
    // Check if the name contains a model (has a space)
    const parts = aiToolName.split(' ');
    if (parts.length > 1) {
      // If it's a model, show with special formatting
      return (
        <div className="flex flex-col">
          <span>{parts[0]}</span>
          <span className="text-xs text-muted-foreground">
            Model: {parts.slice(1).join(' ')}
          </span>
        </div>
      );
    }
    // If it's just a tool, show normally
    return <span>{aiToolName}</span>;
  };

  return (
    <div className="space-y-4">
      <div className="space-y-4">
        {/* Header with user info and category */}
        <div className="flex items-start gap-3">
          <ProfileAvatar 
            userId={insight.userId}
            userName={insight.userName}
            className="h-10 w-10"
          />
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <span className="font-semibold">{insight.userName}</span>
              <span className="text-sm text-muted-foreground">·</span>
              <span className="text-sm text-muted-foreground">
                {formatDistanceToNow(insight.createdAt, { addSuffix: true })}
              </span>
            </div>
            <div className="flex flex-wrap items-center gap-2 mt-1">
              <Badge variant="secondary" className="rounded-full text-gray-500 whitespace-nowrap">
                <CategoryIcon className="h-3 w-3 mr-1" />   
                {categoryConfig[insight.category].label}
              </Badge>
              <span className="text-sm text-muted-foreground hidden md:inline">using</span>
              <div className="flex items-center gap-2">
                <Brain className="h-4 w-4 text-blue-500" />
                {renderAIToolName(insight.aiToolName)}
              </div>
              {insight.timeSaved && (
                <Badge variant="secondary" className="rounded-full text-gray-500 whitespace-nowrap hidden md:inline-flex">
                  <Clock className="h-3 w-3 mr-1" />
                  {insight.timeSaved}% saved
                </Badge>
              )}
            </div>
          </div>
          
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <MoreHorizontal className="h-4 w-4 text-gray-500 rounded-full" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {currentUser?.id === insight.userId && (
                <DropdownMenuItem onClick={handleDelete}>
                  <Trash2 className="h-4 w-4 mr-2" />
                  Delete
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onSelect={() => setIsReportDialogOpen(true)}>
                <Flag className="h-4 w-4 mr-2" />
                Report
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Content */}
        {renderContent()}
      </div>
      {!isLastCard && <Separator />}

      <Dialog open={isReportDialogOpen} onOpenChange={setIsReportDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Report Insight</DialogTitle>
            <DialogDescription>
              Please help us understand why you're reporting this insight.
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <label className="text-sm font-medium">Reason</label>
              <Select value={reportReason} onValueChange={(value) => setReportReason(value as ReportReason)}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {REPORT_REASONS.map(reason => (
                    <SelectItem key={reason.value} value={reason.value}>
                      {reason.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <div className="space-y-2">
              <label className="text-sm font-medium">Additional Details (Optional)</label>
              <Textarea
                value={reportDescription}
                onChange={(e) => setReportDescription(e.target.value)}
                placeholder="Please provide any additional context..."
              />
            </div>
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setIsReportDialogOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleReport}>
              Submit Report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
