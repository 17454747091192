import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from './ui/dialog';
import { Button } from './ui/button';
import { Spinner } from './ui/Spinner';
import { toast } from './ui/use-toast';
import { generateBadgeImage, getBadgeImagePath } from '../utils/badgeImageGenerator';
import { Linkedin, Twitter, Facebook, Download, Copy, Check, ChevronLeft, ChevronRight } from 'lucide-react';
import { FaFacebook, FaLinkedin, FaXTwitter } from 'react-icons/fa6';
import { useUserStore } from '../stores/userStore';
import { validateSocialMeta } from '../utils/socialShareValidator';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
import { isMobile } from '../utils/deviceDetection';
import { MdFacebook } from 'react-icons/md';
import { User, Task } from '../types';

interface ShareBadgeModalProps {
  isOpen: boolean;
  onClose: () => void;
  badgeElement: HTMLElement | null;
  userId: string;
  imageUrl: string;
}

const SHARE_CONFIG = {
  text: "I'm excited to share my AI Integration Badge from Ctrl AI! By leveraging AI, I'm enhancing my productivity and helping to innovate my field. Join me on Ctrl AI, and let's shape the future together.",
  hashtags: ['CtrlAI', 'ArtificialIntelligence', 'ProductivityTools'],
  platforms: {
    linkedin: {
      getShareUrl: (url: string, title: string) => 
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    },
    twitter: {
      getShareUrl: (url: string, text: string) =>
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
    },
    facebook: {
      getShareUrl: (url: string) =>
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    }
  }
};

const MAX_RETRIES = 3;

const SHARE_MESSAGES = [
  {
    id: 1,
    text: "Every professional must master AI in the next 5 years. I'm saving {hours}h weekly using AI. See how on my Ctrl AI Profile:",
    context: "Urgency + Results focus"
  },
  {
    id: 2,
    text: "Want to see how {profession} pros use AI? Check out my Ctrl AI Profile where I'm sharing real implementation insights:",
    context: "Profession-specific invitation"
  },
  {
    id: 3,
    text: "Tired of figuring out AI alone? Join me and 10,000+ professionals on Ctrl AI where we're mapping every AI-enhanced task:",
    context: "Problem-solution focus"
  },
  {
    id: 4,
    text: "In {profession}, AI is transforming our work. Saving {hours}h weekly across {taskCount} tasks. See how on my Ctrl AI Profile:",
    context: "Specific results"
  },
  {
    id: 5,
    text: "Compare your AI progress with {profession} peers. I'm saving {hours}h weekly. Check my Ctrl AI Profile:",
    context: "Peer comparison focus"
  }
];

const formatShareMessage = (messageTemplate: string, user: User) => {
  const weeklyMinutesSaved = user.tasks.reduce((total: number, task: Task) => {
    return total + (task.aiAssistedTime * task.frequency / 4); // Multiply by 4 for weekly estimate
  }, 0);
  
  // Convert to hours with 1 decimal place
  const weeklyHours = (weeklyMinutesSaved / 60).toFixed(1);
  
  // Get the most common task names (limited to 2-3 words each)
  const taskNames = user.tasks
    .map(task => task.taskName.split(' ').slice(0, 3).join(' ')) // Limit to 3 words
    .slice(0, 2) // Take only first 2 tasks
    .join(' & ');
  
  return messageTemplate
    .replace('{profession}', user.professionName)
    .replace('{hours}', weeklyHours)
    .replace('{taskCount}', user.tasks.length.toString())
    .replace('{tasks}', taskNames);
};

export const ShareBadgeModal: React.FC<ShareBadgeModalProps> = ({
  isOpen,
  onClose,
  badgeElement,
  userId,
  imageUrl: existingImageUrl
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [imageUrl, setImageUrl] = useState(existingImageUrl || '');
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;
  const [copied, setCopied] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (isOpen && !imageUrl && !isGenerating && retryCount < maxRetries && badgeElement) {
      const generateImage = async () => {
        try {
          setIsGenerating(true);
          const url = await generateBadgeImage(badgeElement, userId);
          setImageUrl(url);
        } catch (error) {
          console.error('Badge generation error:', error);
          setRetryCount(prev => prev + 1);
        } finally {
          setIsGenerating(false);
        }
      };

      generateImage();
    }
  }, [isOpen, imageUrl, badgeElement, userId, isGenerating, retryCount]);

  const handleShare = async (platform: 'linkedin' | 'twitter' | 'facebook') => {
    if (!imageUrl) return;

    try {
      const user = useUserStore.getState().currentUser;
      if (!user) {
        toast({
          title: "Error",
          description: "User data not found",
          variant: "destructive",
        });
        return;
      }
      
      const { message, url } = getShareText(user, currentMessageIndex);
      const shareTitle = `${user.name}'s AI Integration Badge - Ctrl AI`;

      if (isMobile && navigator.share) {
        try {
          await navigator.share({
            title: shareTitle,
            text: message,
            url: url,
          });
          return;
        } catch (error) {
        }
      }

      const platformConfig = SHARE_CONFIG.platforms[platform];
      const shareLink = platformConfig.getShareUrl(url, platform === 'twitter' ? message : shareTitle);

      const newWindow = window.open(shareLink, '_blank');
      
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        await navigator.clipboard.writeText(shareLink);
        toast({
          title: "Popup Blocked",
          description: `The share window was blocked. The link has been copied to your clipboard. Please paste it in your ${platform} browser tab.`,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error sharing badge:', error);
      toast({
        title: "Error",
        description: "Failed to share badge",
        variant: "destructive",
      });
    }
  };

  const handleDownload = async () => {
    if (!imageUrl) return;
    
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ai-integration-badge.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading badge:', error);
      toast({
        title: "Error",
        description: "Failed to download badge",
        variant: "destructive",
      });
    }
  };

  const handleClose = () => {
    if (isGenerating) return;
    setRetryCount(0);
    onClose();
  };

  const validateMetaTags = async () => {
    try {
      const user = useUserStore.getState().currentUser;
      if (!user) return;

      const shareUrl = `${window.location.origin}/u/${userId}`;
      await validateSocialMeta(shareUrl);
    } catch (error) {
      console.error('Meta validation error:', error);
    }
  };

  useEffect(() => {
    if (isOpen && imageUrl) {
      validateMetaTags();
    }
  }, [isOpen, imageUrl]);

  const getShareText = (user: User | null, messageIndex: number = 0) => {
    if (!user) {
      return {
        message: '',
        url: ''
      };
    }

    const shareUrl = `${window.location.origin}/s/${user.id}`;
    const messageTemplate = SHARE_MESSAGES[messageIndex].text;
    const formattedMessage = formatShareMessage(messageTemplate, user);
    
    return {
      message: formattedMessage,
      url: shareUrl
    };
  };

  const handleCopyText = async () => {
    const user = useUserStore.getState().currentUser;
    if (!user) {
      toast({
        title: "Error",
        description: "User data not found",
        variant: "destructive",
      });
      return;
    }
    
    try {
      await navigator.clipboard.writeText(getShareText(user, currentMessageIndex).message);
      setCopied(true);
      toast({
        title: "Copied!",
        description: "Share text copied to clipboard",
      });
      
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to copy text",
        variant: "destructive",
      });
    }
  };

  const nextMessage = () => {
    setCurrentMessageIndex((prev) => 
      prev === SHARE_MESSAGES.length - 1 ? 0 : prev + 1
    );
  };

  const previousMessage = () => {
    setCurrentMessageIndex((prev) => 
      prev === 0 ? SHARE_MESSAGES.length - 1 : prev - 1
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[800px] max-h-[90vh] overflow-y-auto p-6">
        <DialogHeader>
          <DialogTitle>Share Your Ctrl AI Profile</DialogTitle>
          <DialogDescription>
            {isGenerating 
              ? 'Generating badge...'
              : 'Choose how you’d like to share your AI Integration Badge and contribute to a growing community of forward-thinking professionals.'}
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col lg:flex-row gap-6 py-4">
          {isGenerating && (
            <div className="flex flex-col items-center gap-4 w-full">
              <Spinner />
              <p className="text-sm text-muted-foreground">Generating badge...</p>
            </div>
          )}

          {!isGenerating && imageUrl && (
            <>
              {/* Badge Image Section */}
              <div className="lg:w-1/2 flex items-center justify-center">
                <div className="relative w-full max-w-[300px] lg:max-w-none">
                  <img 
                    src={imageUrl} 
                    alt="Generated badge" 
                    className="w-full h-auto rounded-lg shadow-lg"
                  />
                </div>
              </div>

              {/* Share Controls Section */}
              <div className="lg:w-1/2 flex flex-col gap-6">
                {/* Copy Message Section - Now at the top */}
                <div className="w-full space-y-3">
                  <div className="flex items-center justify-between mb-2">
                    <div className="text-sm text-muted-foreground flex items-center gap-4">
                      <span>Share message:</span>
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={handleCopyText}
                        className="h-8 w-8 p-0"
                      >
                        {copied ? (
                          <Check className="h-4 w-4 text-green-500" />
                        ) : (
                          <Copy className="h-4 w-4" />
                        )}
                      </Button>
                    </div>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={previousMessage}
                        className="h-8 w-8 p-0"
                      >
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      
                      <div className="flex gap-1">
                        {SHARE_MESSAGES.map((_, index) => (
                          <div
                            key={index}
                            className={`h-1.5 w-1.5 rounded-full transition-all ${
                              index === currentMessageIndex 
                                ? 'bg-primary w-3' 
                                : 'bg-muted-foreground/30'
                            }`}
                          />
                        ))}
                      </div>

                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={nextMessage}
                        className="h-8 w-8 p-0"
                      >
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>

                  <div className="bg-muted rounded-lg p-4">
                    <div className="text-sm space-y-2">
                      <p>{getShareText(useUserStore.getState().currentUser, currentMessageIndex).message}</p>
                      <p className="break-all text-muted-foreground">
                        {getShareText(useUserStore.getState().currentUser, currentMessageIndex).url}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Share Buttons */}
                <div className="grid grid-cols-2 gap-3 w-full">
                  <Button 
                    onClick={() => handleShare('linkedin')}
                    className="flex items-center justify-center gap-2 h-10"
                  >
                    <FaLinkedin className="h-4 w-4" />
                    LinkedIn
                  </Button>
                  <Button 
                    onClick={() => handleShare('twitter')}
                    className="flex items-center justify-center gap-2 h-10"
                  >
                    <FaXTwitter className="h-4 w-4" />
                    X
                  </Button>
                  <Button 
                    onClick={() => handleShare('facebook')}
                    className="flex items-center justify-center gap-2 h-10"
                  >
                    <FaFacebook className="h-4 w-4" />
                    Facebook
                  </Button>
                  <Button 
                    onClick={handleDownload}
                    className="flex items-center justify-center gap-2 h-10"
                  >
                    <Download className="h-4 w-4" />
                    Download
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
