import React, { useState, useEffect, useRef } from 'react';
import { DemoBadgeSection } from '../components/demo/DemoBadgeSection';
import { Button } from '../components/ui/button';
import { motion } from 'framer-motion';
import { Users as UsersIcon, Clock as ClockIcon, BarChart as ChartIcon, Rocket as RocketIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { SignInModal } from '../components/SignInModal';

export default function Platform() {
  const [isDemoComplete, setIsDemoComplete] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  const handleDemoComplete = () => {
    setIsDemoComplete(true);
  };

  const handleCreateBadge = () => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/onboarding');
    }
  };

  const useElementOnScreen = (options: IntersectionObserverInit) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, options);

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, [options]);

    return [containerRef, isVisible] as const;
  };

  const [videoRef, isVideoVisible] = useElementOnScreen({
    threshold: 0.5
  });

  return (
    <>
      <div className="min-h-screen">
        {/* Split section */}
        <div className="flex flex-col lg:flex-row min-h-[90vh] mt-[80px] lg:mt-0">
          {/* Left Side - Badge Display */}
          <div className="w-full lg:w-1/2 flex items-center justify-center p-4">
            <div className="relative w-full max-w-xl">
              <DemoBadgeSection onDemoComplete={handleDemoComplete} />
            </div>
          </div>

          {/* Right Side - Hero Content */}
          <div className="w-full lg:w-1/2 flex items-center px-6 lg:px-12">
            <div className="space-y-8">
              <motion.h1 
                className="text-4xl lg:text-5xl font-bold leading-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <p className="text-lg mb-4 text-gray-800 font-bold dark:text-gray-500">
                THE CTRL AI PLATFORM
              </p>
                Where Professionals Lead the AI Revolution—Not Get Replaced By It
              </motion.h1>

              <p className="text-2xl text-gray-800 dark:text-gray-200">
                "The winners of the AI age won't be those who use AI. They'll be those who use it best."
              </p>

              <div className="flex flex-col sm:flex-row gap-4">
                <Button 
                  size="lg" 
                  className="px-12 py-6 text-lg hover:bg-blue-500 rounded-full"
                  onClick={handleCreateBadge}
                >
                  Create Your Ctrl AI Profile
                </Button>

                {!currentUser && (
                  <Button 
                    size="lg"
                    variant="outline"
                    className="px-12 py-6 text-lg hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full"
                    onClick={() => setIsSignInModalOpen(true)}
                  >
                    Sign In
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Full width mission statement */}
        <div className="w-full px-6 py-24 bg-gray-50 dark:bg-gray-900/50">
          <div className="max-w-4xl mx-auto space-y-8">
            <p className="text-3xl md:text-3xl font-bold text-gray-600 dark:text-gray-300">
            Building the Future of Professional AI Integration
                        </p>
            
            <p className="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-gray-500">
            We're using our revolutionary company-building model to create the world's first human-centric guide to professional AI integration. Our community of professionals across 8,000+ fields is mapping exactly how to enhance 250,000+ tasks with AI.            </p>
          </div>
        </div>

        {/* Full-width sections with centered content */}
        <div className="flex flex-col items-center">
          {/* Video Section - full width */}
          <div className="w-full px-6">
            <div ref={videoRef} className="w-full aspect-video relative">
              <iframe
                className="w-full h-full rounded-2xl border mt-20 border-gray-200 dark:border-gray-800"
                src="https://www.youtube.com/embed/6l5S9Gra0XM"
                title="Ctrl AI Demo Video"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>

          {/* Centered sections with 70% width on large screens */}
          <div className="w-full lg:w-[70%] px-6">
            {/* Why Ctrl AI Section */}
            <section className="py-24 border border-gray-200 mt-24 px-6 md:px-12 dark:border-gray-800 rounded-2xl">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">Why We Need Collective Intelligence</h2>
                
                <div className="grid gap-8">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <ChartIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">See Exactly How Others Integrate AI</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        No more guessing. Access real implementation insights from professionals in your field, task by task. See what works, what doesn't, and why.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <UsersIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Benchmark Against Your Peers</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        Are you ahead or behind in AI adoption? Compare your AI integration with others in your profession. Identify opportunities before they become necessities.
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <RocketIcon className="h-8 w-8 text-blue-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Stay Ahead of the Transformation</h3>
                      <p className="text-gray-600 dark:text-gray-400">
                        Your profession will change more in the next 5 years than in the past 50. Join the professionals actively mapping and mastering this change.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* How It Works */}
            <section className="py-24">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">Start Mastering AI Today</h2>

                <div className="space-y-8">
                  <div className="relative pl-8 border-l-2 border-blue-500">
                    <h3 className="text-xl font-semibold mb-2">Map Your Tasks</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Identify which tasks in your workflow can be enhanced with AI. See how others in your field are already optimizing similar tasks.
                    </p>
                  </div>

                  <div className="relative pl-8 border-l-2 border-green-500">
                    <h3 className="text-xl font-semibold mb-2">Track Real Impact</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Measure time saved and efficiency gained. Compare your results with profession benchmarks to identify optimization opportunities.
                    </p>
                  </div>

                  <div className="relative pl-8 border-l-2 border-violet-500">
                    <h3 className="text-xl font-semibold mb-2">Learn & Lead</h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      Share insights, discover best practices, and help shape how your profession adapts to AI. Your experience guides others.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/* Social Proof */}
            <section className="py-24 px-6 md:px-12 border border-gray-200 dark:border-gray-800 rounded-2xl">
              <div className="space-y-12">
                <h2 className="text-3xl font-bold">The Professional AI Revolution</h2>
                <p className="text-gray-600 text-xl dark:text-gray-400">
                  Across every industry, professionals are discovering how to enhance their work with AI:
                </p>

                <div className="grid grid-cols-2 gap-8">
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">250k+</p>
                    <p className="text-gray-600 dark:text-gray-400">Professional tasks being enhanced</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">83%</p>
                    <p className="text-gray-600 dark:text-gray-400">Report faster task completion</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">8k+</p>
                    <p className="text-gray-600 dark:text-gray-400">Professions actively adapting</p>
                  </div>
                  <div className="p-6 border border-gray-200 dark:border-gray-800 rounded-lg">
                    <p className="text-3xl font-bold text-blue-500">4.2h</p>
                    <p className="text-gray-600 dark:text-gray-400">Average daily time saved</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Final CTA */}
            <section className="py-24">
              <div className="text-center space-y-8">
                <h2 className="text-3xl font-bold">
                Community Ownership
                </h2>
                <p className="text-xl text-gray-600 dark:text-gray-400 max-w-xl mx-auto">
                As part of our revolutionary model, 80% of Ctrl AI ownership is reserved for our users. Early adopters get significantly larger shares - join now to maximize your stake.
                                </p>
                   <Button 
              size="lg"
              className="text-lg px-16 font-bold py-6 rounded-full gold-button"
              onClick={() => window.location.href = 'https://www.skool.com/ctrl-ai-founding-members-1596'}
            >
              <span className="hidden md:inline">Join Our First 1,000 Founders - $100/month</span>
              <span className="md:hidden">Join Now - $100/mo</span>
            </Button>
              </div>
            </section>
          </div>
        </div>
      </div>
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
    </>
  );
}