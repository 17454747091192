// AuthButton.tsx
import React from 'react';
import { Button } from './ui/button';

interface AuthButtonProps {
  onSignUp: () => void;
  initialMode?: 'signin' | 'signup';
}

export const AuthButton: React.FC<AuthButtonProps> = ({ 
  onSignUp,
  initialMode = 'signin'
}) => {
  const handleClick = () => {
    onSignUp();  // This will trigger the modal in the parent
  };

  return (
    <Button onClick={handleClick} className="py-6 text-white bg-violet-600 hover:bg-violet-500 text-lg mt-8 px-10">
      Sign Up
    </Button>
  );
};
