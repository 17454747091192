import { User, Task, UserAITool } from '../types';

export interface DemoBadge {
  user: User;
  commentary: {
    title: string;
    description: string;
    stats: {
      timeSaved: number;
      tasksOptimized: number;
      aiScore: number;
    };
  };
}

// Helper function to generate ISO date strings
const getISODate = (daysAgo: number = 0) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  return date.toISOString();
};

export const demoBadges: DemoBadge[] = [
  {
    user: {
      id: "demo1",
      uid: "demo1",
      name: "Sarah Chen",
      professionId: "15-1252",
      professionName: "Software Developer",
      points: 2850,
      aiLevel: 6,
      profilePicture: {
        mainUrl: "/demo/user_01.jpg",
        thumbnailUrl: "/demo/user_01_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Code Review",
          frequency: 10,
          totalTime: 240,
          aiAssistedTime: 80,
          aiAssistedPercentage: 35,
          color: "#FF6F61", // Coral
          createdAt: getISODate(30),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "1",
              aiToolName: "GitHub Copilot",
              aiToolLink: "https://github.com/features/copilot",
              lastUpdated: getISODate(1)
            },
            {
              aiToolId: "2",
              aiToolName: "ChatGPT",
              aiToolLink: "https://chat.openai.com",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Bug Fixing",
          frequency: 15,
          totalTime: 300,
          aiAssistedTime: 120,
          aiAssistedPercentage: 40,
          color: "#6B5B95", // Indigo
          createdAt: getISODate(25),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "1",
              aiToolName: "GitHub Copilot",
              aiToolLink: "https://github.com/features/copilot",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Feature Implementation",
          frequency: 8,
          totalTime: 360,
          aiAssistedTime: 144,
          aiAssistedPercentage: 40,
          color: "#88B04B", // Green
          createdAt: getISODate(20),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "3",
              aiToolName: "Tabnine",
              aiToolLink: "https://www.tabnine.com",
              lastUpdated: getISODate(2)
            }
          ]
        }
      ],
      createdAt: getISODate(30),
      updatedAt: getISODate(1),
      bio: "Full-stack developer passionate about AI integration",
      location: "San Francisco, CA",
      website: "github.com",
      isPremium: false,
      lastProfessionChange: getISODate(30),
    },
    commentary: {
      title: "AI-Powered Development",
      description: "See how developers are using AI to streamline code reviews and boost productivity",
      stats: {
        timeSaved: 15,
        tasksOptimized: 8,
        aiScore: 85
      }
    }
  },
  {
    user: {
      id: "demo2",
      uid: "demo2",
      name: "Matthias Carnagan",
      professionId: "15-2051",
      professionName: "Data Scientist",
      points: 2350,
      aiLevel:  5,
        profilePicture: {
        mainUrl: "/demo/user_02.jpg",
        thumbnailUrl: "/demo/user_02_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Collect and clean data from various sources to ensure accuracy and completeness.",
          frequency: 20,
          totalTime: 200,
          aiAssistedTime: 50,
          aiAssistedPercentage: 40,
          color: "#FFC300", // Yellow
          createdAt: getISODate(20),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "4",
              aiToolName: "AutoML",
              aiToolLink: "https://cloud.google.com/automl",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Create predictive models and machine learning algorithms to enhance decision-making.",
          frequency: 12,
          totalTime: 200,
          aiAssistedTime: 90,
          aiAssistedPercentage: 30,
          color: "#FF5733", // Orange
          createdAt: getISODate(15),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "5",
              aiToolName: "TensorFlow",
              aiToolLink: "https://tensorflow.org",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Utilize programming languages such as Python or R for data manipulation and analysis.",
          frequency: 10,
          totalTime: 200,
          aiAssistedTime: 100,
          aiAssistedPercentage: 50,
          color: "#C70039", // Red
          createdAt: getISODate(10),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "6",
              aiToolName: "Tableau AI",
              aiToolLink: "https://www.tableau.com/products/ai",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(45),
      updatedAt: getISODate(1),
      bio: "Utilize programming languages such as Python or R for data manipulation and analysis.",
      location: "Boston, MA",
      website: "",
      isPremium: true,
      lastProfessionChange: getISODate(45),
    },
    commentary: {
      title: "AI-Enhanced Data Science",
      description: "Discover how data scientists are leveraging AI to accelerate analysis and model development",
      stats: {
        timeSaved: 22,
        tasksOptimized: 12,
        aiScore: 92
      }
    }
  },
  {
    user: {
      id: "demo3",
      uid: "demo3",
      name: "Dr. Lisa Thompson",
      professionId: "29-1069",
      professionName: "Family Medicine Physicians",
      points: 2090,
      aiLevel: 4,
        profilePicture: {
        mainUrl: "/demo/user_03.jpg",
        thumbnailUrl: "/demo/user_03_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Conduct comprehensive patient examinations and assessments.",
          frequency: 14,
          totalTime: 200,
          aiAssistedTime: 70,
          aiAssistedPercentage: 20,
          color: "#52B5F8", // blue
          createdAt: getISODate(40),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "7",
              aiToolName: "IBM Watson Health",
              aiToolLink: "https://www.ibm.com/watson-health",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Create and manage patient treatment plans.",
          frequency: 12,
          totalTime: 200,
          aiAssistedTime: 10,
          aiAssistedPercentage: 20,
          color: "#FFB6B9", // Light Pink
          createdAt: getISODate(30),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "8",
              aiToolName: "PubMed AI",
              aiToolLink: "https://pubmed.ncbi.nlm.nih.gov/",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Patient Follow-up",
          frequency: 20,
          totalTime: 200,
          aiAssistedTime: 40,
          aiAssistedPercentage: 25,
          color: "#FCE38A", // Light Yellow
          createdAt: getISODate(20),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "9",
              aiToolName: "HealthKeeper",
              aiToolLink: "https://healthkeeper.ai",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "4",
          taskName: "Respond to patient inquiries and address concerns.",
          frequency: 15,
          totalTime: 200,
          aiAssistedTime: 50,
          aiAssistedPercentage: 25,
          color: "#B4A0FE", // Lavender
          createdAt: getISODate(10),
          updatedAt: getISODate(3),
          aiToolsUsed: [
            {
              aiToolId: "10",
              aiToolName: "EHR AI Assistant",
              aiToolLink: "https://ehraiassistant.com",
              lastUpdated: getISODate(3)
            }
          ]
        }
      ],
      createdAt: getISODate(60),
      updatedAt: getISODate(1),
      bio: "Physician integrating AI to enhance patient care",
      location: "New York, NY",
      website: "",
      isPremium: true,
      lastProfessionChange: getISODate(60),
    },
    commentary: {
      title: "AI in Healthcare",
      description: "Explore how medical professionals are utilizing AI for accurate diagnoses and efficient patient management",
      stats: {
        timeSaved: 25,
        tasksOptimized: 10,
        aiScore: 90
      }
    }
  },
  {
    user: {
      id: "demo4",
      uid: "demo4",
      name: "Dieter Rams",
      professionId: "43-9091",
      professionName: "Mechanical Engineer",
      points: 1790,
      aiLevel: 4,
      profilePicture: {
        mainUrl: "/demo/user_04.jpg",
        thumbnailUrl: "/demo/user_04_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "CAD Modeling",
          frequency: 18,
          totalTime: 360,
          aiAssistedTime: 45,
          aiAssistedPercentage: 20,
          color: "#FF7F50", // Coral
          createdAt: getISODate(35),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "11",
              aiToolName: "AutoCAD AI",
              aiToolLink: "https://autodesk.com/products/autocad/ai",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Simulation Testing",
          frequency: 10,
          totalTime: 400,
          aiAssistedTime: 70,
          aiAssistedPercentage: 40,
          color: "#2E8B57", // Sea Green
          createdAt: getISODate(25),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "12",
              aiToolName: "Simulink AI",
              aiToolLink: "https://www.mathworks.com/products/simulink.html",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Construction Planning and Scheduling",
          frequency: 15,
          totalTime: 300,
          aiAssistedTime: 75,
          aiAssistedPercentage: 25,
          color: "#4682B4", // Steel Blue
          createdAt: getISODate(20),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "13",
              aiToolName: "Asana AI",
              aiToolLink: "https://asana.com/ai",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(50),
      updatedAt: getISODate(1),
      bio: "Mechanical Engineer leveraging AI for efficient design and testing",
      location: "Dusseldorf, Germany",
      website: "",
      isPremium: false,
      lastProfessionChange: getISODate(50),
    },
    commentary: {
      title: "AI in Engineering",
      description: "See how mechanical engineers are integrating AI into design and simulation processes",
      stats: {
        timeSaved: 10,
        tasksOptimized: 5,
        aiScore: 70
      }
    }
  },
  {
    user: {
      id: "demo5",
      uid: "demo5",
      name: "Aisha Khan",
      professionId: "15-1131",
      professionName: "Graphic Designer",
      points: 1250,
      aiLevel: 4,
      profilePicture: {
        mainUrl: "/demo/user_05.jpg",
        thumbnailUrl: "/demo/user_05_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Logo Design",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 20,
          color: "#FF6347", // Tomato
          createdAt: getISODate(28),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "14",
              aiToolName: "Canva AI",
              aiToolLink: "https://www.canva.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Social Media Graphics",
          frequency: 20,
          totalTime: 100,
          aiAssistedTime: 65,
          aiAssistedPercentage: 20,
          color: "#40E0D0", // Turquoise
          createdAt: getISODate(15),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "15",
              aiToolName: "Adobe Sensei",
              aiToolLink: "https://www.adobe.com/sensei.html",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Client Presentations",
          frequency: 8,
          totalTime: 120,
          aiAssistedTime: 36,
          aiAssistedPercentage: 30,
          color: "#DA70D6", // Orchid
          createdAt: getISODate(10),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "16",
              aiToolName: "Prezi AI",
              aiToolLink: "https://prezi.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "4",
          taskName: "Portfolio Updates",
          frequency: 5,
          totalTime: 90,
          aiAssistedTime: 27,
          aiAssistedPercentage: 30,
          color: "#FFD700", // Gold
          createdAt: getISODate(5),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "17",
              aiToolName: "Behance AI",
              aiToolLink: "https://www.behance.net",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(40),
      updatedAt: getISODate(1),
      bio: "Creative Graphic Designer utilizing AI to enhance visual storytelling",
      location: "Amsterdam, NL",
      website: "",
      isPremium: true,
      lastProfessionChange: getISODate(40),
    },
    commentary: {
      title: "AI-Driven Design",
      description: "Learn how graphic designers are incorporating AI to create stunning visuals and streamline workflows",
      stats: {
        timeSaved: 18,
        tasksOptimized: 7,
        aiScore: 80
      }
    }
  },
  {
    user: {
      id: "demo6",
      uid: "demo6",
      name: "David Lee",
      professionId: "15-1132",
      professionName: "Financial Analyst",
      points: 1680,
      aiLevel: 4,
      profilePicture: {
        mainUrl: "/demo/user_06.jpg",
        thumbnailUrl: "/demo/user_06_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Market Research",
          frequency: 14,
          totalTime: 210,
          aiAssistedTime: 63,
          aiAssistedPercentage: 20,
          color: "#FF8C00", // Dark Orange
          createdAt: getISODate(25),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "18",
              aiToolName: "Bloomberg Terminal AI",
              aiToolLink: "https://www.bloomberg.com/professional/solution/bloomberg-terminal/",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Financial Modeling",
          frequency: 10,
          totalTime: 300,
          aiAssistedTime: 55,
          aiAssistedPercentage: 25,
          color: "#20B2AA", // Light Sea Green
          createdAt: getISODate(20),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "19",
              aiToolName: "Excel AI",
              aiToolLink: "https://www.microsoft.com/en-us/microsoft-365/excel",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Report Generation",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 30,
          color: "#87CEFA", // Light Sky Blue
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "20",
              aiToolName: "Tableau AI",
              aiToolLink: "https://www.tableau.com/products/ai",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "4",
          taskName: "Investment Analysis",
          frequency: 8,
          totalTime: 150,
          aiAssistedTime: 45,
          aiAssistedPercentage: 30,
          color: "#9370DB", // Medium Purple
          createdAt: getISODate(10),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "21",
              aiToolName: "RoboAdvisor AI",
              aiToolLink: "https://roboadvisor.ai",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(35),
      updatedAt: getISODate(1),
      bio: "Financial Analyst utilizing AI to enhance investment strategies",
      location: "London, UK",
      website: "",
      isPremium: false,
      lastProfessionChange: getISODate(35),
    },
    commentary: {
      title: "AI in Finance",
      description: "Understand how financial analysts are leveraging AI for market research and investment strategies",
      stats: {
        timeSaved: 12,
        tasksOptimized: 6,
        aiScore: 75
      }
    }
  },
  {
    user: {
      id: "demo7",
      uid: "demo7",
      name: "Mark Thompson",
      professionId: "27-2012",
      professionName: "Civil Engineer",
      points: 3020,
      aiLevel: 6,
      profilePicture: {
        mainUrl: "/demo/user_07.jpg",
        thumbnailUrl: "/demo/user_07_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Structural Analysis",
          frequency: 16,
          totalTime: 320,
          aiAssistedTime: 96,
          aiAssistedPercentage: 30,
          color: "#FF4500", // Orange Red
          createdAt: getISODate(30),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "22",
              aiToolName: "AutoCAD Civil 3D AI",
              aiToolLink: "https://www.autodesk.com/products/civil-3d/overview",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Project Planning",
          frequency: 10,
          totalTime: 200,
          aiAssistedTime: 60,
          aiAssistedPercentage: 30,
          color: "#1E90FF", // Dodger Blue
          createdAt: getISODate(20),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "23",
              aiToolName: "Microsoft Project AI",
              aiToolLink: "https://www.microsoft.com/en-us/microsoft-365/project/project-management-software",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Site Inspection Reports",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 30,
          color: "#32CD32", // Lime Green
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "24",
              aiToolName: "DroneDeploy AI",
              aiToolLink: "https://www.dronedeploy.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "4",
          taskName: "Budget Estimation",
          frequency: 8,
          totalTime: 150,
          aiAssistedTime: 45,
          aiAssistedPercentage: 30,
          color: "#8A2BE2", // Blue Violet
          createdAt: getISODate(10),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "25",
              aiToolName: "BudgetPro AI",
              aiToolLink: "https://budgetpro.ai",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "5",
          taskName: "Compliance Documentation",
          frequency: 5,
          totalTime: 100,
          aiAssistedTime: 30,
          aiAssistedPercentage: 30,
          color: "#FFD700", // Gold
          createdAt: getISODate(5),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "26",
              aiToolName: "DocuCompliance AI",
              aiToolLink: "https://docucompliance.ai",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(45),
      updatedAt: getISODate(1),
      bio: "Civil Engineer integrating AI for efficient project management and structural analysis",
      location: "Houston, TX",
      website: "",
      isPremium: false,
      lastProfessionChange: getISODate(45),
    },
    commentary: {
      title: "AI in Civil Engineering",
      description: "Discover how civil engineers are utilizing AI for structural analysis and project planning",
      stats: {
        timeSaved: 14,
        tasksOptimized: 7,
        aiScore: 78
      }
    }
  },
  {
    user: {
      id: "demo8",
      uid: "demo8",
      name: "Rado Sukala",
      professionId: "15-1199",
      professionName: "Startup Founder",
      points: 6247,
      aiLevel: 10,
      profilePicture: {
        mainUrl: "/demo/rado_sukala.jpg",
        thumbnailUrl: "/demo/rado_sukala_thumb.jpg"
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Establish brand identity and manage market positioning.",
          frequency: 20,
          totalTime: 400,
          aiAssistedTime: 45,
          aiAssistedPercentage: 55,
          color: "#CA35D0", // Hot Pink
          createdAt: getISODate(35),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "27",
              aiToolName: "o1 Preview",
              aiToolLink: "https://ctrlai.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "2",
          taskName: "Formulate and validate the startup’s business model.",
          frequency: 15,
          totalTime: 300,
          aiAssistedTime: 35,
          aiAssistedPercentage: 30,
          color: "#319EC2", // Medium Orchid
          createdAt: getISODate(25),
          updatedAt: getISODate(2),
          aiToolsUsed: [
            {
              aiToolId: "28",
              aiToolName: "o1 Preview",
              aiToolLink: "https://ctrlai.com",
              lastUpdated: getISODate(2)
            }
          ]
        },
        {
          taskId: "3",
          taskName: "Design and manage backend code and database structures.",
          frequency: 10,
          totalTime: 200,
          aiAssistedTime: 60,
          aiAssistedPercentage: 30,
          color: "#E6A23F", // Light Salmon
          createdAt: getISODate(20),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "29",
              aiToolName: "Cursor AI + Claude",
              aiToolLink: "https://cursor.sh",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "4",
          taskName: "Oversee product testing and quality assurance.",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 35,
          color: "#E65C73", // Peach Puff
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "30",
              aiToolName: "ChatGPT 4o",
              aiToolLink: "https://openai.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "5",
          taskName: "Create and optimize frontend interfaces for user engagement.",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 55,
          color: "#4FEEEA", // Peach Puff
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "31",
              aiToolName: "Cursor AI + Claude",
              aiToolLink: "https://cursor.sh",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "6",
          taskName: "Prioritize features and manage the product roadmap.",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 30,
          color: "#BD35C7", // Peach Puff
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "32",
              aiToolName: "o1 Preview",
              aiToolLink: "https://ctrlai.com",
              lastUpdated: getISODate(1)
            }
          ]
        },
        {
          taskId: "7",
          taskName: "Build and maintain the website’s layout and functionality..",
          frequency: 12,
          totalTime: 180,
          aiAssistedTime: 54,
          aiAssistedPercentage: 50,
          color: "#633EE6", // Peach Puff
          createdAt: getISODate(15),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "33",
              aiToolName: "Cursor AI + Claude",
              aiToolLink: "https://cursor.sh",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(50),
      updatedAt: getISODate(1),
      bio: "Startup Founder leveraging AI for efficient business operations and product development",
      location: "Santa Monica, CA",
      website: "",
      isPremium: true,
      lastProfessionChange: getISODate(50),
    },
    commentary: {
      title: "AI in Startups",
      description: "Learn how startup founders are using AI to streamline operations and enhance product development",
      stats: {
        timeSaved: 20,
        tasksOptimized: 8,
        aiScore: 82
      }
    }
  },

  {
    user: {
      id: "demo8",
      uid: "demo8",
      name: "This is Your Ctrl AI Profile",
      professionId: "15-1199",
      professionName: "Start Your AI Journey",
      points: 100,
      aiLevel: 1,
      profilePicture: {
        mainUrl: "",
        thumbnailUrl: ""
      },
      tasks: [
        {
          taskId: "1",
          taskName: "Place for your first task",
          frequency: 20,
          totalTime: 100,
          aiAssistedTime: 30,
          aiAssistedPercentage: 30,
          color: "#FF69B4", // Hot Pink
          createdAt: getISODate(35),
          updatedAt: getISODate(1),
          aiToolsUsed: [
            {
              aiToolId: "27",
              aiToolName: "Ctrl AI",
              aiToolLink: "https://ctrlai.com",
              lastUpdated: getISODate(1)
            }
          ]
        }
      ],
      createdAt: getISODate(50),
      updatedAt: getISODate(1),
      bio: "Join thousands of professionals showcasing how they're mastering AI integration. Create your Ctrl AI Profile and lead the evolution of your profession.",
      location: "Santa Monica, CA",
      website: "ctrlai.com",
      isPremium: true,
      lastProfessionChange: getISODate(50),
    },
    commentary: {
      title: "AI in Human Resources",
      description: "Learn how HR managers are using AI to streamline recruitment and boost employee engagement",
      stats: {
        timeSaved: 20,
        tasksOptimized: 8,
        aiScore: 82
      }
    }
  }
  
  
    
];