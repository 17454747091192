// src/pages/MyBadgePage.tsx

import React, { useState, useEffect, useRef } from 'react';
import { EditableBadge } from '../components/EditableBadge';
import { useUserStore } from '../stores/userStore';
import { Spinner } from '../components/ui/Spinner';
import { SignInModal } from '../components/SignInModal';
import { useAuth } from '../contexts/AuthContext';
import { User, Task } from '../types';
import { calculateUserScore, calculateLevel, calculateUserScoreAndLevel } from '../utils/scoreCalculation';
import { trackEvent } from '../utils/analytics';
import { toast } from '../components/ui/use-toast';
import { generateBadgeImage } from '../utils/badgeImageGenerator';
import { ShareBadgeModal } from '../components/ShareBadgeModal';

const MyBadgePage: React.FC = () => {
  const { currentUser: authUser } = useAuth();
  const { currentUser, fetchUserData, isLoading } = useUserStore(state => state);
  const updateUserData = useUserStore(state => state.updateUserData);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'signin' | 'signup'>('signin');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [badgeImageUrl, setBadgeImageUrl] = useState<string>('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const badgeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (authUser?.uid && !currentUser) {
      fetchUserData(authUser.uid).then(() => {
        setIsDataLoaded(true);
      }).catch(error => {
        console.error('Error fetching user data:', error);
        setIsDataLoaded(false);
      });
    } else if (currentUser) {
      setIsDataLoaded(true);
    } else {
      setIsDataLoaded(true);
    }
  }, [authUser, currentUser, fetchUserData]);

  const handleSave = async (task: Task, profession: string) => {
    if (!currentUser) return;

    try {
      const existingTaskIndex = currentUser.tasks.findIndex(t => t.taskId === task.taskId);
      const updatedTasks = existingTaskIndex >= 0
        ? currentUser.tasks.map(t => t.taskId === task.taskId ? task : t)
        : [...currentUser.tasks, task];
      
      const { score, level } = calculateUserScoreAndLevel(updatedTasks);
      
      await updateUserData({
        tasks: updatedTasks,
        professionName: profession,
        points: score,
        aiLevel: level
      });

      trackEvent('task_saved', {
        task_name: task.taskName,
        profession,
        is_new: !currentUser?.tasks.some(t => t.taskId === task.taskId),
        ai_tools: task.aiToolsUsed.map(t => t.aiToolName).join(','),
        ai_percentage: task.aiAssistedPercentage,
        frequency: task.frequency
      });
    } catch (error) {
      console.error('Error in handleSave:', error);
      throw error;
    }
  };

  const handleEditTask = (task: Task) => {
    // Just handle the UI state for editing, no score recalculation
  };

  const handleDeleteTask = async (taskId: string) => {
    if (!currentUser) return;

    try {
      const updatedTasks = currentUser.tasks.filter(task => task.taskId !== taskId);
      const { score, level } = calculateUserScoreAndLevel(updatedTasks);
      
      await updateUserData({
        tasks: updatedTasks,
        points: score,
        aiLevel: level
      });

      const taskToDelete = currentUser?.tasks.find(t => t.taskId === taskId);
      
      trackEvent('task_deleted', {
        task_name: taskToDelete?.taskName,
        ai_tools: taskToDelete?.aiToolsUsed.map(t => t.aiToolName).join(','),
        task_count: currentUser?.tasks.length
      });
    } catch (error) {
      console.error('Error in handleDeleteTask:', error);
      throw error;
    }
  };

  const handleSignUp = () => {
    trackEvent('signup_initiated', {
      source: 'badge_page',
      task_count: currentUser?.tasks.length || 0
    });
    setModalMode('signup');
    setIsSignInModalOpen(true);
  };

  // Add this function
  const handleAddTask = () => {
  };

  // Handle share with retry mechanism
  const handleOpenShareModal = () => {
    if (!currentUser) {
      toast({
        title: "Error",
        description: "Please sign in to share your badge",
        variant: "destructive",
      });
      return;
    }

    const badgeElement = document.querySelector('.badge-svg-container');
    if (!badgeElement) {
      toast({
        title: "Error",
        description: "Badge element not found",
        variant: "destructive",
      });
      return;
    }

    badgeRef.current = badgeElement as HTMLElement;
    setIsShareModalOpen(true);
  };

  if (!isDataLoaded) {
    return <Spinner />;
  }

  return (
    <div className="bg-white dark:bg-gray-950">
      <EditableBadge
        userId={currentUser?.id || null}
        onSave={handleSave}
        onAddTask={handleAddTask}
        onEditTask={handleEditTask}
        onDelete={handleDeleteTask}
        onOpenShareModal={handleOpenShareModal}
        onSignUp={handleSignUp}
      />
      <ShareBadgeModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        imageUrl={badgeImageUrl}
        badgeElement={badgeRef.current}
        userId={currentUser?.id || ''}
      />
      <SignInModal 
        isOpen={isSignInModalOpen} 
        onClose={() => setIsSignInModalOpen(false)}
        initialMode={modalMode}
      />
    </div>
  );
};

export default MyBadgePage;
