export function generateTaskColor(taskId: string, taskDesc: string): string {
  // Create a unique string combining profession task description (first 15 chars) and taskId
  const colorKey = `${taskDesc.slice(0, 15)}_${taskId}`;
  
  // Generate a hash from the combined string
  let hash = 0;
  for (let i = 0; i < colorKey.length; i++) {
    hash = colorKey.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Create more visually pleasing and diverse colors using golden ratio
  const goldenRatio = 0.618033988749895;
  
  // Use hash to generate a starting point between 0 and 1
  let hue = (hash % 360) / 360;
  
  // Use golden ratio to shift the hue
  hue += goldenRatio;
  hue %= 1;
  
  // Convert to degrees and ensure good saturation and lightness
  const hueInDegrees = Math.floor(hue * 360);
  const saturation = 65 + (hash % 20); // Vary between 65-85%
  const lightness = 55 + (hash % 10);  // Vary between 55-65%
  
  return `hsl(${hueInDegrees}, ${saturation}%, ${lightness}%)`;
}
