import React from 'react';
import { Button } from "./ui/button";
import { Share2 } from "lucide-react";

interface ShareMenuProps {
  onOpenShareModal: () => void;
  disabled?: boolean;
  className?: string;
}

export const ShareMenu: React.FC<ShareMenuProps> = ({ 
  onOpenShareModal, 
  disabled,
  className 
}) => {
  return (
    <Button 
      variant="outline" 
      size="default"
      className={`gap-2 ${className} hover:bg-blue-500`}
      disabled={disabled}
      onClick={onOpenShareModal}
    >
      <Share2 className="h-4 w-4" />
      Share Your Ctrl AI Badge
    </Button>
  );
};
