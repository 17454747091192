import { NewsletterSignup } from '../components/NewsletterSignup';
import { Helmet } from 'react-helmet-async';

export default function NewsletterPage() {
  return (
    <>
      <Helmet>
        <title>Stay Updated - Ctrl AI Newsletter</title>
        <meta 
          name="description" 
          content="Get weekly updates on AI-driven solo entrepreneurship, community milestones, and upcoming opportunities with Ctrl AI." 
        />
      </Helmet>
      
      <div className="min-h-screen container mx-auto px-4 py-20">
        <NewsletterSignup variant="standalone" />
      </div>
    </>
  );
} 