import { Button } from './ui/button';
import { motion } from 'framer-motion';

interface NewsletterSignupProps {
  variant?: 'homepage' | 'standalone';
  className?: string;
}

export function NewsletterSignup({ variant = 'homepage', className = '' }: NewsletterSignupProps) {
  const isHomepage = variant === 'homepage';
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={`${className} max-w-3xl mx-auto text-center space-y-8 p-8 rounded-3xl ${
        isHomepage ? 'bg-white/5 backdrop-blur-sm' : 'bg-white dark:bg-gray-950'
      }`}
    >
      <div className="space-y-4 p-4">
        <h2 className="text-3xl lg:text-4xl font-bold tracking-tight">
          Watch Us Build the Future
        </h2>
        {!isHomepage && (
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Join our community of innovators and stay updated on the AI-driven future of solo entrepreneurship.
          </p>
        )}
      </div>

      <div className="space-y-12">
        <div className="flex flex-col space-y-6">
          <p className="text-gray-600 dark:text-gray-400">We'll send you a weekly email with updates on our progress, community milestones, and upcoming ring openings.</p>
        </div>

        {/* Updated Convertkit Form */}
        <div className="max-w-full mx-auto">
          <form
            action="https://app.convertkit.com/forms/7467620/subscriptions"
            className="seva-form formkit-form"
            method="post"
            data-sv-form="7467620"
            data-uid="5b47f3f56d"
            data-format="inline"
            data-version="5"
            data-options={`{"settings":{"after_subscribe":{"action":"redirect","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":"https://yourdomain.com/thank-you"}}}`}
          >
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="flex-1">
                <input
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-950 focus:ring-2 focus:ring-blue-500"
                  name="email_address"
                  aria-label="Email Address"
                  placeholder="Enter your email"
                  required
                  type="email"
                />
              </div>
              <Button 
                type="submit"
                className="w-full h-12 md:w-auto px-8 bg-gray-900 dark:bg-white text-white dark:text-gray-900 hover:bg-gray-800 dark:hover:bg-gray-100"
                size="lg"
              >
                Stay Updated
              </Button>
            </div>
          </form>
        </div>
      </div>

      <p className="text-sm text-gray-500">
        We respect your privacy. Unsubscribe at any time.
      </p>
    </motion.div>
  );
} 