import { Task } from '../types';

interface ScoreAndLevel {
  score: number;
  level: number;
}

export function calculateUserScore(tasks: Task[]): number {
  return tasks.reduce((totalScore, task) => {
    const monthlyAIMinutes = calculateTaskScore(task);
    return totalScore + monthlyAIMinutes;
  }, 0);
}

export function calculateTaskScore(task: Task): number {
  const timesPerMonth = task.frequency || 1;
  const aiMinutesPerOccurrence = task.totalTime * (task.aiAssistedPercentage / 100);
  return Math.round(aiMinutesPerOccurrence * timesPerMonth);
}

export function calculateUserScoreAndLevel(tasks: Task[]): ScoreAndLevel {
  const score = calculateUserScore(tasks);
  const level = calculateLevel(score);
  
  return {
    score,
    level
  };
}

export function calculateLevel(score: number): number {
  if (score < 100) return 0;
  if (score < 300) return 1;
  if (score < 600) return 2;
  if (score < 1000) return 3;
  if (score < 1500) return 4;
  if (score < 2100) return 5;
  if (score < 2800) return 6;
  if (score < 3600) return 7;
  if (score < 4500) return 8;
  if (score < 5500) return 9;
  
  // For scores above 5500, calculate level based on additional 1000 points per level
  const additionalLevels = Math.floor((score - 5500) / 1000);
  return 10 + additionalLevels;
}

export function getNextLevelThreshold(currentScore: number): number {
  const thresholds = [100, 300, 600, 1000, 1500, 2100, 2800, 3600, 4500, 5500];
  
  // Find the next threshold
  const nextThreshold = thresholds.find(threshold => threshold > currentScore);
  
  // If score is beyond the predefined thresholds, calculate next milestone
  if (!nextThreshold) {
    const currentLevel = calculateLevel(currentScore);
    return 5500 + ((currentLevel - 9) * 1000);
  }
  
  return nextThreshold;
}

export function getLevelProgress(score: number): number {
  const currentLevel = calculateLevel(score);
  const currentThreshold = getNextLevelThreshold(score);
  const previousThreshold = currentLevel === 0 ? 0 : getNextLevelThreshold(score - 1);
  
  return Math.round(((score - previousThreshold) / (currentThreshold - previousThreshold)) * 100);
}

