import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useUserStore } from '../stores/userStore';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../components/ui/Spinner';
import { UserSettings } from '../components/UserSettings/UserSettings';

export const UserSettingsPage: React.FC = () => {
  const { currentUser: authUser, isEmailVerified } = useAuth();
  const { currentUser, fetchUserData, isLoading } = useUserStore();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      if (!authUser) {
        navigate('/');
        return;
      }

      if (!isEmailVerified) {
        navigate('/verify-email');
        return;
      }

      try {
        if (authUser.uid && !currentUser) {
          await fetchUserData(authUser.uid);
        }
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error loading user data:', error);
        setIsDataLoaded(false);
      }
    };

    loadUserData();
  }, [authUser, currentUser, fetchUserData, isEmailVerified, navigate]);

  // Show loading state while checking auth and loading data
  if (!isDataLoaded || isLoading || (authUser && !isEmailVerified)) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white dark:bg-gray-950">
        <Spinner />
      </div>
    );
  }

  // Show settings only if user is authenticated and email is verified
  return (
    <div className="min-h-screen bg-white dark:bg-gray-950">
      <UserSettings />
    </div>
  );
};

export default UserSettingsPage;
