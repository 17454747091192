import { Timestamp } from 'firebase/firestore';

export const formatFirestoreDate = (date: Timestamp | Date | string): string => {
  if (date instanceof Timestamp) {
    return date.toDate().toISOString();
  }
  if (date instanceof Date) {
    return date.toISOString();
  }
  return date;
};
