import React, { useState, useEffect } from 'react';
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";

interface InvertedPyramidProps {
  className?: string;
}

export const InvertedPyramid: React.FC<InvertedPyramidProps> = ({ className }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [openHoverCard, setOpenHoverCard] = useState<string | null>(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <div 
      className={`relative w-[500px] h-[500px] mx-auto ${className}`}
    >
      <img 
        src="/pyramid.svg" 
        alt="Inverted pyramid showing company structure" 
        className="w-full h-full"
      />
      
      {/* Background hover areas */}
      <div 
        className="absolute top-0 left-0 w-full h-[30%] hover:bg-black/20 transition-colors duration-200"
      />
      <div 
        className="absolute top-[30%] left-0 w-full h-[30%] hover:bg-black/20 transition-colors duration-200"
      />
      <div 
        className="absolute top-[60%] left-0 w-full h-[40%] hover:bg-black/20 transition-colors duration-200"
      />
      
      {/* Investors Section */}
      <HoverCard>
        <HoverCardTrigger asChild>
          <div 
            className="absolute top-0 left-0 w-full h-[31%] text-center text-black cursor-pointer z-10
                       hover:bg-black/20 transition-colors duration-200"
            onClick={() => setOpenHoverCard(prev => prev === 'investors' ? null : 'investors')}
          >
            <div className="absolute top-[42%] left-0 w-full">
              <h3 className="text-xl text-center text-white font-bold">Few Investors</h3>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent 
          side="bottom"
          align="center"
          sideOffset={-75}
          avoidCollisions={false}
          className="w-64 bg-[black]/0 rounded-xl border-none text-gray-500 z-20"
        >
          <p className="text-xl font-bold text-center text-white">hold the most power</p>
        </HoverCardContent>
      </HoverCard>

      {/* Team Section */}
      <HoverCard>
        <HoverCardTrigger asChild>
          <div 
            className="absolute top-[31%] left-0 w-full h-[28%] text-center text-black cursor-pointer z-10
                       hover:bg-black/20 transition-colors duration-200"
            onClick={() => setOpenHoverCard(prev => prev === 'team' ? null : 'team')}
          >
            <div className="absolute top-[35%] left-0 w-full">
              <h3 className="text-xl text-center text-white font-bold">Team</h3>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent 
          side="bottom"
          align="center"
          avoidCollisions={false}
          sideOffset={-77}

          className="w-64 bg-[black]/0 rounded-xl border-none text-gray-500 z-20"
        >
          <p className="text-xl font-bold text-center line-height-0.7 text-white">works under investor pressure</p>
        </HoverCardContent>
      </HoverCard>

      {/* Users Section */}
      <HoverCard>
        <HoverCardTrigger asChild>
          <div 
            className="absolute top-[59%] left-0 w-full h-[40%] text-center text-black cursor-pointer z-10
                       hover:bg-black/20 transition-colors duration-200"
            onClick={() => setOpenHoverCard(prev => prev === 'users' ? null : 'users')}
          >
            <div className="absolute top-[10%] left-0 w-full">
              <h3 className="text-xl text-center text-white font-bold">Users</h3>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent 
          side="bottom"
          align="center"
          avoidCollisions={false}
          sideOffset={-170}

          className="w-64 bg-[black]/0 rounded-xl border-none text-gray-500 z-20"
        >
          <p className="text-xl font-bold text-center text-white">
            get no 
            <br />
            ownership,
            <br />
            just 
            <br />
            consumption
          </p>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};