import React from 'react';
import { CenterInfo } from './CenterInfo';
import { RingSegments } from './RingSegments';
import { User, Task } from '../../types';

interface BadgeSVGProps {
  user: User | null;
  tasks: Task[];
  showAIOnly: boolean;
  isLoaded: boolean;
  handleTaskHover: (task: Task, index: number) => void;
  handleTaskClick: (task: Task, index: number) => void;
  handleTaskLeave: () => void;
  getAnimationStyle: (task: Task) => any;
  selectedTask: Task | null;
  hoveredTask: Task | null;
  isEditable: boolean;
  onEditTask?: (task: Task) => void;
  onAddTask?: () => void;
  onOpenShareModal?: () => void;
  viewBox: string;
  isMobile: boolean;
  renderUserInfo?: () => React.ReactNode;
  showUserInfo?: boolean;
}

export const BadgeSVG: React.FC<BadgeSVGProps> = ({
  user,
  tasks,
  showAIOnly,
  isLoaded,
  handleTaskHover,
  handleTaskClick,
  handleTaskLeave,
  getAnimationStyle,
  selectedTask,
  hoveredTask,
  isEditable,
  onEditTask,
  onAddTask,
  onOpenShareModal,
  viewBox,
  isMobile,
  renderUserInfo,
  showUserInfo = false
}) => {
  return (
    <svg 
      className="badge-svg"
      viewBox={isMobile ? "0 0 480 480" : viewBox}
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <radialGradient id="centerGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="85%" stopColor="var(--badge-center-color)" />
          <stop offset="100%" stopColor="var(--badge-outer-color)" />
        </radialGradient>
        {tasks.map((task, index) => (
          <filter key={`glow-${index}`} id={`glow-${index}`} x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="5" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        ))}
      </defs>
      <g>
        <g transform={`translate(${isMobile ? 245 : 300}, ${isMobile ? 245 : 300})`}>
          <circle cx="0" cy="0" r="216" fill="url(#centerGradient)" />
          <CenterInfo user={user} showUserInfo={showUserInfo} />
          <RingSegments
            tasks={tasks}
            showAIOnly={showAIOnly}
            isLoaded={isLoaded}
            handleTaskHover={handleTaskHover}
            handleTaskClick={handleTaskClick}
            handleTaskLeave={handleTaskLeave}
            getAnimationStyle={getAnimationStyle}
            selectedTask={selectedTask}
            onOpenShareModal={onOpenShareModal}
            hoveredTask={hoveredTask}
            isEditable={isEditable}
            onEditTask={onEditTask}
            onAddTask={onAddTask}
          />

        </g>
      </g>
    </svg>
  );
};
