export const DEFAULT_PROFILE_PICTURE = {
  light: '/default-avatar-light.svg',
  dark: '/default-avatar-dark.svg',
  default: '/default-avatar-dark.svg'
};

export const FIREBASE_STORAGE_URLS = {
  profilePicture: {
    getMainUrl: (userId: string) => 
      `https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/users%2F${userId}%2Fprofile%2Fmain.jpg?alt=media`,
    getThumbnailUrl: (userId: string) => 
      `https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/users%2F${userId}%2Fprofile%2Fthumbnail.jpg?alt=media`
  }
};

export const DEFAULT_OG_IMAGE = 'https://ctrlai.com/og-image-home.jpg';
export const DEFAULT_DESCRIPTION = 'Join thousands of professionals mapping their path to AI mastery. Create your Ctrl AI Profile in 2 minutes and showcase your AI integration journey.';

