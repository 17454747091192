const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

interface CachedLocation {
  timestamp: number;
  results: any[];
}

const locationCache = new Map<string, CachedLocation>();

export const searchLocations = async (query: string) => {
  if (query.length < 2) return [];

  // Check cache
  const cached = locationCache.get(query);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.results;
  }

  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${query}&addressdetails=1&limit=5`
    );
    const data = await response.json();
    
    const results = data.map((result: any) => ({
      value: `${result.address.city || result.address.town || result.address.village}, ${result.address.country}`,
      label: `${result.address.city || result.address.town || result.address.village}, ${result.address.country}`,
    }));

    // Cache results
    locationCache.set(query, {
      timestamp: Date.now(),
      results
    });

    return results;
  } catch (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
};
