import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "./ui/hover-card";
import { Switch } from "./ui/switch";
import { Ring } from "./ring/Ring";
import "./ring/metallicRings.css";

interface MetallicRingsProps {
  className?: string;
  forceShowMass?: boolean;
  visibleRings?: string[];
  currentStep?: number;
  setCurrentStep?: (step: number) => void;
}

export const MetallicRings: React.FC<MetallicRingsProps> = ({ 
  className, 
  forceShowMass = false,
  visibleRings = ['founder', 'gold', 'silver', 'bronze'],
  currentStep = 0,
  setCurrentStep
}) => {
  const [openHoverCard, setOpenHoverCard] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showMassRings, setShowMassRings] = useState(forceShowMass);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // 768px is typical md breakpoint
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleInteraction = (ringId: string) => {
    if (isMobile) {
      setOpenHoverCard(prev => prev === ringId ? null : ringId);
    }
  };

  return (
    <div className="space-y-4 w-full overflow-hidden flex justify-center">
      
      <div className="rings-container" style={{ transform: 'scale(0.9)' }}>

      <div 
        className={`relative ${className}`} 
        style={{ 
          width: 'min(var(--ring-container-size), 90vw)', 
          height: 'min(var(--ring-container-size), 90vw)',
          margin: '0 auto'
        }}
      >
        <div className="relative w-full h-full">
          {showMassRings && (
            <>
              <Ring 
                type="mass" 
                isOpen={isMobile ? openHoverCard === 'mass' : false}
                onInteraction={handleInteraction}
                visible={visibleRings.includes('mass')}
                className="ring-size-6"
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                stepIndex={5}
              />
              <Ring 
                type="network" 
                isOpen={isMobile ? openHoverCard === 'network' : false}
                onInteraction={handleInteraction}
                visible={visibleRings.includes('network')}
                className="ring-size-5"
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                stepIndex={4}
              />
            </>
          )}
          
          <Ring 
            type="bronze" 
            isOpen={isMobile ? openHoverCard === 'bronze' : false}
            onInteraction={handleInteraction}
            visible={visibleRings.includes('bronze')}
            className="ring-size-4"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepIndex={3}
          />
          
          <Ring 
            type="silver" 
            isOpen={isMobile ? openHoverCard === 'silver' : false}
            onInteraction={handleInteraction}
            visible={visibleRings.includes('silver')}
            className="ring-size-3"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepIndex={2}
          />
          
          <Ring 
            type="gold" 
            isOpen={isMobile ? openHoverCard === 'gold' : false}
            onInteraction={handleInteraction}
            visible={visibleRings.includes('gold')}
            className="ring-size-2"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepIndex={1}
          />
          
          <Ring 
            type="founder" 
            isOpen={isMobile ? openHoverCard === 'founder' : false}
            onInteraction={handleInteraction}
            visible={visibleRings.includes('founder')}
            className="ring-size-1"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepIndex={0}
          />
        </div>
      </div>
      </div>
      {!forceShowMass && (
        <div className="switch-container">
          <Switch
            checked={showMassRings}
            onCheckedChange={setShowMassRings}
            className="data-[state=checked]:bg-teal-500"
          />
          <label className="text-sm text-gray-500">Ownership Distribution</label>
        </div>
      )}
    </div>

  );
};