import React from 'react';
import { Helmet } from 'react-helmet';

export const DefaultMetaTags: React.FC = () => {
  return (
    <Helmet>
      <title>AI Proficiency Badge - Ctrl AI</title>
      <meta name="description" content="Track and showcase your AI integration progress!" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://ctrlaib.web.app" />
      <meta property="og:title" content="AI Proficiency Badge - Ctrl AI" />
      <meta property="og:description" content="Track and showcase your AI integration progress!" />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/default-og-image.jpg?alt=media" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://ctrlaib.web.app" />
      <meta name="twitter:title" content="AI Proficiency Badge - Ctrl AI" />
      <meta name="twitter:description" content="Track and showcase your AI integration progress!" />
      <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/ctrlaib.appspot.com/o/default-og-image.jpg?alt=media" />
    </Helmet>
  );
}; 