import React, { useState, useEffect } from 'react';
import { Task, Insight, UserAITool, InsightCategory } from '../types';
import { useInsightStore } from '../stores/insightStore';
import { useUserStore } from '../stores/userStore';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader } from './ui/card';
import { InsightForm } from './InsightForm';
import { Spinner } from './ui/Spinner';
import { categoryConfig } from '../config/insightCategories';
import { InsightCard } from './InsightCard';
import { Clock, Timer, Brain } from 'lucide-react';
import { Cross1Icon, Pencil1Icon } from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { ArrowLeft } from 'lucide-react';
import { Avatar } from './ui/avatar';
import { Textarea } from './ui/textarea';
import { DEFAULT_PROFILE_PICTURE } from '../constants';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Input } from './ui/input';
import { Separator } from "./ui/separator";
import { Lightbulb, Target, BookOpen, Wrench, LineChart } from 'lucide-react';
import { ExternalLink } from 'lucide-react';
import { useAIToolStore } from '../stores/aiToolStore';
import { formatFirestoreDate } from '../utils/dateUtils';
import { ImagePlus, X } from 'lucide-react';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { processImage } from '../utils/imageUtils';
import { nanoid } from 'nanoid';
import { Label } from './ui/label';
import { toast } from './ui/use-toast';
import { trackEvent } from '../utils/analytics';
import { useThemeStore } from '../stores/themeStore';

interface TaskInsightsViewProps {
  task: Task;
  onEdit: () => void;
  onBack: () => void;
  onClose: () => void;
}

const formatTime = (minutes: number): string => {
  if (minutes < 60) {
    return `${Math.round(minutes)}min`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.round(minutes % 60);
  return `${hours}:${remainingMinutes.toString().padStart(2, '0')}h`;
};

type CategoryConfigKey = keyof typeof categoryConfig;

const ensureHttps = (url: string): string => {
  if (!url) return '';
  return url.startsWith('http') ? url : `https://${url}`;
};

export const TaskInsightsView: React.FC<TaskInsightsViewProps> = ({
  task,
  onEdit,
  onBack,
  onClose
}) => {
  const [selectedAITool, setSelectedAITool] = useState<UserAITool | null>(null);
  const [showAddInsight, setShowAddInsight] = useState(false);
  const { taskInsights, fetchTaskInsights, isLoading, addInsight } = useInsightStore();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<CategoryConfigKey>('tips');
  const [insightContent, setInsightContent] = useState('');
  const [additionalFields, setAdditionalFields] = useState({
    resourceTitle: '',
    resourceUrl: '',
    challengeSolution: '',
    metricValue: '',
    metricUnit: '',
  });
  const { currentUser } = useUserStore();
  const { aiTools, fetchAITools } = useAIToolStore();
  const [selectedImage, setSelectedImage] = useState<{ file: Blob; preview: string } | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { isDarkMode } = useThemeStore();

  // Get the current default profile picture based on theme
  const defaultProfilePicture = isDarkMode ? DEFAULT_PROFILE_PICTURE.dark : DEFAULT_PROFILE_PICTURE.light;

  useEffect(() => {
    fetchTaskInsights(task.taskId).finally(() => {
      setIsInitialLoad(false);
    });
  }, [task.taskId]);

  useEffect(() => {
    fetchAITools('general');
  }, []);

  const currentTaskInsights = taskInsights[task.taskId]?.insights || {};

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast({
        title: "Invalid file type",
        description: "Please select an image file",
        variant: "destructive"
      });
      return;
    }

    try {
      const processedBlob = await processImage(file, 1200);
      const preview = URL.createObjectURL(processedBlob);
      
      setSelectedImage({
        file: processedBlob,
        preview
      });

      toast({
        title: "Image selected",
        description: "Your image has been added and will be uploaded when you share your insight.",
      });

      trackEvent('insight_image_added', {
        task_name: task.taskName,
        file_type: file.type
      });
    } catch (error) {
      console.error('Error processing image:', error);
      toast({
        title: "Error",
        description: "Failed to process image",
        variant: "destructive"
      });
    }
  };

  const handleQuickInsight = async () => {
    if (!insightContent.trim()) return;
    setIsUploading(true);
    setIsImageUploading(true);

    try {
      let imageId = '';
      
      if (selectedImage) {
        try {
          imageId = nanoid();
          const imagePath = `users/${currentUser?.id}/insights/${imageId}.jpg`;
          const storageRef = ref(storage, imagePath);
          
          await uploadBytes(storageRef, selectedImage.file);
          
          toast({
            title: "Image uploaded",
            description: "Your image has been uploaded successfully.",
          });
        } catch (error) {
          console.error('Error uploading image:', error);
          toast({
            title: "Error",
            description: "Failed to upload image. Your insight will be shared without the image.",
            variant: "destructive"
          });
        }
      }

      const newInsight: Omit<Insight, "id"> = {
        taskId: task.taskId,
        userId: currentUser?.id || '',
        userName: currentUser?.name || 'Anonymous',
        aiToolId: task.aiToolsUsed[0]?.aiToolId || '',
        aiToolName: task.aiToolsUsed[0]?.aiToolName || '',
        category: selectedCategory || 'tips',
        content: insightContent.trim(),
        likes: 0,
        likedBy: [],
        createdAt: new Date().toISOString(),
        timeSaved: task.aiAssistedPercentage,
        ...(imageId && { imageId }),
      };

      await addInsight(task.taskId, newInsight);
      
      // Reset form
      setInsightContent('');
      setSelectedImage(null);
      setSelectedCategory('tips');
      setAdditionalFields({
        resourceTitle: '',
        resourceUrl: '',
        challengeSolution: '',
        metricValue: '',
        metricUnit: '',
      });

      trackEvent('insight_shared', {
        category: selectedCategory,
        has_image: !!selectedImage,
        task_name: task.taskName,
        ai_tool: task.aiToolsUsed[0]?.aiToolName,
        content_length: insightContent.length
      });
    } catch (error) {
      console.error('Error adding insight:', error);
      toast({
        title: "Error",
        description: "Failed to save your insight. Please try again.",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
      setIsImageUploading(false);
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage.preview);
      }
    }
  };

  const renderCommunitySection = () => {
    if (isInitialLoad) {
      return (
        <div className="flex justify-center py-8">
          <Spinner />
        </div>
      );
    }

    if (Object.keys(currentTaskInsights).length > 0) {
      return (
        <>
          <Separator className="my-8" />
          <div className="mb-6">
            <h4 className="text-lg font-semibold">Community Insights</h4>
            <p className="text-sm text-muted-foreground">
              See what others have shared about this task
            </p>
          </div>
          <div className="space-y-4">
            {renderInsights()}
          </div>
        </>
      );
    }

    return null; // Don't render anything if no insights
  };

  const renderQuickInsightComposer = () => (
    <div className="mb-6">
      <div className="mb-4">
        <h4 className="text-lg font-semibold mb-1">
          Share Your Experience
        </h4>
        <p className="text-sm text-muted-foreground">
          Your insights using {task.aiToolsUsed[0]?.aiToolName} can help others optimize their workflow
        </p>
      </div>
      {/* Desktop layout */}
      <div className="hidden md:flex gap-3">
        <Avatar className="h-10 w-10">
          <img 
            src={currentUser?.profilePicture?.thumbnailUrl || defaultProfilePicture}
            data-main-src={currentUser?.profilePicture?.mainUrl}
            alt={currentUser?.name || 'Profile'} 
            className="h-full w-full rounded-full object-cover"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = defaultProfilePicture;
            }}
            onLoad={(e) => {
              const target = e.target as HTMLImageElement;
              const mainSrc = target.getAttribute('data-main-src');
              if (mainSrc && target.src !== mainSrc) {
                const tempImg = new Image();
                tempImg.onload = () => {
                  target.src = mainSrc;
                };
                tempImg.src = mainSrc;
              }
            }}
          />
        </Avatar>
        <div className="flex-1 space-y-3">
          <Textarea
            placeholder={categoryConfig[selectedCategory].placeholder}
            value={insightContent}
            onChange={(e) => setInsightContent(e.target.value)}
            className="min-h-[80px] resize-none"
          />

          {selectedImage && (
            <div className="relative inline-block">
              <img
                src={selectedImage.preview}
                alt="Selected"
                className="max-w-[200px] rounded-md"
              />
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2 bg-black/50 hover:bg-black/70"
                onClick={() => {
                  URL.revokeObjectURL(selectedImage.preview);
                  setSelectedImage(null);
                }}
              >
                <X className="h-4 w-4 text-white" />
              </Button>
            </div>
          )}

          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <Select
                value={selectedCategory}
                onValueChange={(value) => setSelectedCategory(value as CategoryConfigKey)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue>
                    <div className="flex items-center gap-2">
                      {React.createElement(categoryConfig[selectedCategory].icon, {
                        className: "h-4 w-4"
                      })}
                      <span>{categoryConfig[selectedCategory].label}</span>
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {(Object.entries(categoryConfig) as [CategoryConfigKey, typeof categoryConfig[CategoryConfigKey]][]).map(([key, config]) => (
                    <TooltipProvider key={key}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <SelectItem value={key}>
                            <div className="flex items-center gap-2">
                              {React.createElement(config.icon, {
                                className: "h-4 w-4"
                              })}
                              <span>{config.label}</span>
                            </div>
                          </SelectItem>
                        </TooltipTrigger>
                        <TooltipContent side="right" align="start">
                          <p className="max-w-[200px]">{config.description}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  ))}
                </SelectContent>
              </Select>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Label 
                      htmlFor="image-upload" 
                      className="cursor-pointer p-2 hover:bg-muted rounded-md transition-colors"
                    >
                      <ImagePlus className="h-5 w-5 text-muted-foreground hover:text-foreground transition-colors" />
                    </Label>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Add image</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageSelect}
              />
            </div>

            <Button
              size="sm"
              disabled={!insightContent.trim() || isUploading}
              onClick={handleQuickInsight}
              className="px-4 rounded-full text-md py-4"
            >
              {isUploading ? (
                <div className="flex items-center gap-2">
                  <Spinner className="h-4 w-4" />
                  <span>{isImageUploading ? "Uploading image..." : "Sharing..."}</span>
                </div>
              ) : (
                "Share Insight"
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile layout */}
      <div className="md:hidden space-y-3">
        <div className="relative">
          <Textarea
            placeholder={categoryConfig[selectedCategory].placeholder}
            value={insightContent}
            onChange={(e) => setInsightContent(e.target.value)}
            className="min-h-[80px] resize-none w-full pr-10"
          />
          <div className="absolute bottom-2 right-2 flex items-center gap-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Label htmlFor="image-upload" className="cursor-pointer">
                    <ImagePlus className="h-5 w-5 text-muted-foreground hover:text-foreground transition-colors" />
                  </Label>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Add image</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageSelect}
            />
          </div>
        </div>

        {selectedImage && (
          <div className="relative inline-block">
            <img
              src={selectedImage.preview}
              alt="Selected"
              className="max-w-[200px] rounded-md"
            />
            <Button
              type="button"
              variant="ghost"
              size="icon"
              className="absolute top-2 right-2 bg-black/50 hover:bg-black/70"
              onClick={() => {
                URL.revokeObjectURL(selectedImage.preview);
                setSelectedImage(null);
              }}
            >
              <X className="h-4 w-4 text-white" />
            </Button>
          </div>
        )}

        <div className="flex flex-col gap-3">
          <Select
            value={selectedCategory}
            onValueChange={(value) => setSelectedCategory(value as CategoryConfigKey)}
          >
            <SelectTrigger className="w-full">
              <SelectValue>
                <div className="flex items-center gap-2">
                  {React.createElement(categoryConfig[selectedCategory].icon, {
                    className: "h-4 w-4"
                  })}
                  <span>{categoryConfig[selectedCategory].label}</span>
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {(Object.entries(categoryConfig) as [CategoryConfigKey, typeof categoryConfig[CategoryConfigKey]][]).map(([key, config]) => (
                <SelectItem key={key} value={key}>
                  <div className="flex items-center gap-2">
                    {React.createElement(config.icon, {
                      className: "h-4 w-4"
                    })}
                    <span>{config.label}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button
            disabled={!insightContent.trim() || isUploading}
            onClick={handleQuickInsight}
            className="w-full rounded-full text-md py-4"
          >
            {isUploading ? "Sharing..." : "Share Insight"}
          </Button>
        </div>
      </div>

      {/* Category-specific fields remain the same */}
      {selectedCategory !== 'tips' && (
        <div className="mt-3">
          {selectedCategory === 'resources' && (
            <div className="space-y-2">
              <Input
                placeholder="Resource title"
                value={additionalFields.resourceTitle}
                onChange={(e) => setAdditionalFields({
                  ...additionalFields,
                  resourceTitle: e.target.value
                })}
              />
              <Input
                type="url"
                placeholder="Resource URL"
                value={additionalFields.resourceUrl}
                onChange={(e) => setAdditionalFields({
                  ...additionalFields,
                  resourceUrl: e.target.value
                })}
              />
            </div>
          )}
          {/* Add other category-specific fields here */}
        </div>
      )}
    </div>
  );

  const renderTaskStats = () => {
    const hours = Math.floor(task.totalTime / 60);
    const minutes = task.totalTime % 60;
    
    return (
      <div className="space-y-6 mb-8">
                {/* AI Tools - Keeping existing design */}
                <div className="space-y-4">
          {/* AI Tools */}
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-sm text-gray-500">AI Tools Used</p>
              <div className="flex flex-wrap items-center gap-2">
                {task.aiToolsUsed.map((tool, index) => (
                  <React.Fragment key={tool.aiToolId}>
                    {index > 0 && <span className="text-gray-400">|</span>}
                    <div className="flex items-center gap-1">
                      <p className="text-lg font-semibold">
                        {tool.aiToolName}
                      </p>
                      {aiTools.general?.find(t => t.aiToolId === (tool.modelId ? tool.aiToolId.split('_')[0] : tool.aiToolId))?.aiToolLink && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={ensureHttps(aiTools.general.find(t => 
                                  t.aiToolId === (tool.modelId ? tool.aiToolId.split('_')[0] : tool.aiToolId)
                                )?.aiToolLink || '')}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-muted-foreground hover:text-primary"
                              >
                                <ExternalLink className="h-4 w-4" />
                              </a>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Visit {tool.aiToolName.split(' ')[0]}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button 
                    className="flex items-center gap-2 border border-gray-800" 
                    variant="ghost" 
                    onClick={onEdit}
                  >
                    <Pencil1Icon className="h-5 w-5" />
                    <span>Edit Task</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit Task</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>


        {/* Time and Frequency Stats - New Design */}
        <div className="space-y-2">
          <p className="text-md text-gray-900 dark:text-gray-500">
            This task takes you{' '}
            <span className="font-semibold" style={{ color: task.color || '#000' }}>
              {hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`}
            </span>
            {' '}and occurs{' '}
            <span className="font-semibold" style={{ color: task.color || '#000' }}>
              {task.frequency} times
            </span>
            {' '}per month.
          </p>
        </div>


          {/* Time Slider - Updated design */}
          <div className="space-y-2">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="relative h-6 w-full rounded-full bg-gray-200 dark:bg-gray-800">
                    <div 
                      className="absolute h-full rounded-full transition-all"
                      style={{
                        width: `${task.aiAssistedPercentage}%`,
                        backgroundColor: task.color || '#000',
                        opacity: task.aiToolsUsed.length ? 0.8 : 0.3
                      }}
                    />
                  </div>
                </TooltipTrigger>
                {task.aiToolsUsed.length === 0 && (
                  <TooltipContent>
                    <p>Select AI tools to track time savings</p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
            <div className="flex justify-between items-center text-sm text-muted-foreground">
              <div className="flex items-baseline gap-1">
                <span className="font-bold text-xl" style={{ color: task.color || '#000' }}>
                  {task.aiAssistedPercentage}%
                </span>
                <span className="text-sm text-muted-foreground">Saved</span>
              </div>
              <span>Task: {formatTime(task.aiAssistedTime)}</span>
              <span>Month: {formatTime(task.aiAssistedTime * task.frequency)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAITools = () => (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-lg font-semibold">AI Toolkit</h4>
        <p className="text-sm text-muted-foreground">Tools used for this task</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {task.aiToolsUsed.map((tool) => {
          // Find the full AI tool data including models
          const fullToolData = aiTools.general?.find(t => t.aiToolId === tool.aiToolId);
          const isModel = tool.modelId !== undefined;
          
          return (
            <Card key={tool.aiToolId} className="group hover:shadow-md transition-shadow">
              <CardContent className="p-4">
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <h4 className="font-semibold">
                        {isModel ? (
                          <span>
                            {tool.aiToolName} {/* This will now include both tool and model name */}
                          </span>
                        ) : (
                          tool.aiToolName
                        )}
                      </h4>
                      {tool.aiToolLink && (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <a
                                href={tool.aiToolLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-muted-foreground hover:text-primary"
                              >
                                <ExternalLink className="h-4 w-4" />
                              </a>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Visit {tool.aiToolName}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                    </div>
                    {isModel && (
                      <p className="text-sm text-muted-foreground">
                        Model of {tool.aiToolName.split(' ')[0]} {/* Show parent tool name */}
                      </p>
                    )}
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setSelectedAITool(tool);
                      setShowAddInsight(true);
                    }}
                  >
                    Add Insight
                  </Button>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );

  const renderInsights = () => {
    return Object.entries(currentTaskInsights)
      .sort(([, a], [, b]) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime())
      .map(([insightId, insight], index, array) => {
        const aiTool = task.aiToolsUsed.find(tool => tool.aiToolId === insight.aiToolId) || task.aiToolsUsed[0];
        return (
          <InsightCard 
            key={insightId} 
            insightId={insightId}
            taskId={task.taskId}
            isLastCard={index === array.length - 1}
            insight={{
              id: insightId,
              taskId: task.taskId,
              userId: insight.userId,
              userName: insight.userName,
              aiToolId: insight.aiToolId,
              aiToolName: aiTool?.aiToolName || 'Unknown Tool',
              category: insight.category,
              content: insight.content,
              likes: insight.likes,
              imageId: insight.imageId,
              likedBy: insight.likedBy,
              createdAt: formatFirestoreDate(insight.createdAt),
              timeSaved: task.aiAssistedPercentage,
              ...(insight.challengeSolution && { challengeSolution: insight.challengeSolution }),
              ...(insight.resourceUrl && { resourceUrl: insight.resourceUrl }),
              ...(insight.resourceTitle && { resourceTitle: insight.resourceTitle }),
              ...(insight.metricValue && { metricValue: insight.metricValue }),
              ...(insight.metricUnit && { metricUnit: insight.metricUnit })
            }}
          />
        );
      });
  };

  const renderContent = () => {
    if (!currentUser) {
      return (
        <div className="text-center py-8">
          <h4 className="text-lg font-semibold mb-4">Sign Up to Access Community Insights</h4>
          <p className="text-sm text-muted-foreground mb-6">
            Join our community to share your experiences and learn from others using {task.aiToolsUsed[0]?.aiToolName}.
          </p>
          <Button onClick={onBack}>Back to Dashboard</Button>
        </div>
      );
    }

    return (
      <>
        {renderTaskStats()}
        <Separator className="my-8" />
        {renderQuickInsightComposer()}
        {renderCommunitySection()}
      </>
    );
  };

  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage.preview);
      }
    };
  }, [selectedImage]);

  useEffect(() => {
    if (selectedCategory) {
      trackEvent('insight_category_selected', {
        category: selectedCategory,
        task_name: task.taskName
      });
    }
  }, [selectedCategory]);

  return (
    <Card className="w-full">
      <CardHeader className="pb-3 pt-2">
        <div className="flex items-center mb-0 ml-[-0.75rem]">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  className="rounded-full size-10" 
                  variant="ghost" 
                  size="icon" 
                  onClick={onBack}
                >
                  <ArrowLeft className="h-5 w-5" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Back</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        
        <div className="w-full">
          <h3 className="text-xl font-bold">{task.taskName}</h3>
        </div>
      </CardHeader>

      <Separator className="mb-6" />
      
      <CardContent>
        {renderContent()}
      </CardContent>
    </Card>
  );
};
