import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditableBadge } from '../components/EditableBadge';
import { useUserStore } from '../stores/userStore';
import { useAuth } from '../contexts/AuthContext';
import { Task } from '../types';
import { toast } from '../components/ui/use-toast';
import { SignInModal } from '../components/SignInModal';
import { useProfessionStore } from '../stores/professionStore';

const OnboardingPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser: authUser } = useAuth();
  const { fetchUserData } = useUserStore();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(true);
  const [modalMode, setModalMode] = useState<'signin' | 'signup'>('signup');

  useEffect(() => {
    // If user is logged in, redirect to dashboard
    if (authUser) {
      navigate('/dashboard');
      return;
    }
  }, [authUser, navigate]);

  // If not logged in, show sign in modal
  if (!authUser) {
    return (
      <div className="bg-white dark:bg-gray-950">
        <SignInModal 
          isOpen={isSignInModalOpen} 
          onClose={() => navigate('/')}
          initialMode={modalMode}
        />
      </div>
    );
  }

  // This should never render as we redirect logged in users
  return null;
};

export default OnboardingPage; 