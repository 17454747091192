import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/button';

export default function ThankYouPage() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="max-w-3xl mx-auto text-center space-y-8 p-8"
    >
      <div className="space-y-6 mt-28">
        <h1 className="text-4xl font-bold tracking-tight">
          Welcome to the Community! 🎉
        </h1>
        <p className="text-xl text-gray-600 dark:text-gray-400">
          Thank you for subscribing. Check your inbox to confirm your subscription.
        </p>
        
        <div className="bg-white/5 backdrop-blur-sm p-6 rounded-xl space-y-4">
          <h2 className="text-2xl font-semibold">What's Next?</h2>
          <ul className="text-left space-y-3 text-gray-600 dark:text-gray-400">
            <li>✓ Check your email for confirmation</li>
            <li>✓ Add us to your contacts to never miss updates</li>
            <li>✓ Get ready for weekly insights and opportunities</li>
          </ul>
        </div>

        <div className="pt-8">
          <Link to="/">
            <Button 
              className="bg-gray-900 rounded-full dark:bg-white text-white dark:text-gray-900 hover:bg-gray-800 dark:hover:bg-gray-100"
              size="lg"
            >
              Back to Homepage
            </Button>
          </Link>
        </div>
      </div>
    </motion.div>
  );
} 