import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';

interface ContentItem {
  subtitle?: string;
  text?: string;
  list?: string[];
  contact?: string;
}

interface Section {
  title: string;
  content: ContentItem[];
}

export default function PrivacyPolicy() {
  const sections: Section[] = [
    {
      title: "1. Information We Collect",
      content: [
        {
          subtitle: "a. Information You Provide to Us",
          text: "Personal Information: When you sign up for our mailing list, download resources, or join our community, we may collect your name, email address, and any other information you choose to provide."
        },
        {
          subtitle: "b. Information We Collect Automatically",
          text: "When you visit our website, we may collect information about your device and browsing behavior, such as: IP address, Browser type and version, Pages visited and time spent on the site, Cookies and similar tracking technologies (see Section 6 for details)."
        },
        {
          subtitle: "c. Information From Third Parties",
          text: "We may collect information about you from trusted third parties, such as analytics providers or advertising platforms."
        }
      ]
    },
    {
      title: "2. How We Use Your Information",
      content: [{
        text: "We use the information we collect for the following purposes:",
        list: [
          "To provide resources, updates, and services related to Ctrl AI",
          "To send you promotional emails or newsletters (only if you've opted in)",
          "To improve our website, tools, and overall user experience",
          "To respond to your inquiries and provide customer support",
          "To comply with legal obligations or enforce our Terms of Service"
        ]
      }]
    },
    {
      title: "3. How We Share Your Information",
      content: [{
        text: "We do not sell, rent, or trade your personal information. However, we may share your information in the following cases:",
        list: [
          "With Service Providers: We work with trusted partners (e.g., email marketing platforms, analytics tools) to help us operate our business",
          "For Legal Compliance: We may share information when required to comply with laws or protect our rights",
          "In Business Transfers: If Ctrl AI is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction"
        ]
      }]
    },
    {
      title: "4. Your Rights and Choices",
      content: [{
        text: "Depending on your location, you may have the following rights regarding your personal information:",
        list: [
          "Access and Correction: Request access to the personal information we have about you or ask us to correct inaccuracies",
          "Deletion: Request that we delete your personal information, subject to certain legal exceptions",
          "Opt-Out: Unsubscribe from marketing emails by clicking the 'unsubscribe' link in our emails",
          "Cookies: Adjust your browser settings to refuse or delete cookies (see Section 6)"
        ]
      }]
    },
    {
      title: "5. Data Retention",
      content: [{
        text: "We retain your personal information for as long as necessary to fulfill the purposes described in this policy or comply with legal requirements. If you no longer want us to hold your data, you can request deletion at any time (see Section 4)."
      }]
    },
    {
      title: "6. Cookies and Tracking Technologies",
      content: [
        {
          subtitle: "What Are Cookies?",
          text: "Cookies are small text files stored on your device that help us recognize you and track your preferences."
        },
        {
          subtitle: "Why We Use Cookies:",
          list: [
            "To remember your preferences",
            "To measure the effectiveness of our marketing efforts",
            "To improve website functionality"
          ]
        },
        {
          subtitle: "Managing Cookies",
          text: "You can disable cookies in your browser settings, but this may limit your ability to use certain features of our website."
        }
      ]
    },
    {
      title: "7. Security",
      content: [{
        text: "We take reasonable measures to protect your information from unauthorized access, loss, or misuse. However, no method of transmission over the internet is completely secure. Please use caution when sharing your personal information online."
      }]
    },
    {
      title: "8. Third-Party Links",
      content: [{
        text: "Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. Please review their privacy policies before sharing your information."
      }]
    },
    {
      title: "9. Changes to This Privacy Policy",
      content: [{
        text: "We may update this Privacy Policy from time to time. When we do, we'll post the updated version on this page and update the 'Effective Date.' We encourage you to review this policy regularly."
      }]
    },
    {
      title: "10. Contact Us",
        content: [{
        text: "If you have any questions about this Privacy Policy or how your information is handled, please contact us at:",
        contact: "Email: info@ctrlai.com"
      }]
    }
  ];

  return (
    <>
      <Helmet>
        <title>Privacy Policy - Ctrl AI</title>
        <meta 
          name="description" 
          content="Ctrl AI's Privacy Policy - Learn how we collect, use, and protect your personal information." 
        />
      </Helmet>

      <div className="min-h-screen container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto prose dark:prose-invert"
        >
          <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
          <p className="text-gray-600 dark:text-gray-400 mb-8">
            Effective Date: December 13, 2024
          </p>

          <div className="space-y-12">
            <p>
              Ctrl AI ("we," "our," or "us") respects your privacy and is committed to protecting your personal information. 
              This Privacy Policy explains how we collect, use, and protect your data when you visit our website or interact with our services.
            </p>
            <p>
              By using our website or providing your information, you agree to the practices outlined in this policy.
            </p>

            {sections.map((section, index) => (
              <section key={index} className="space-y-6">
                <h2 className="text-2xl font-bold">{section.title}</h2>
                <div className="space-y-4">
                  {section.content.map((content, contentIndex) => (
                    <div key={contentIndex} className="space-y-4">
                      {content.subtitle && (
                        <h3 className="text-xl font-semibold">{content.subtitle}</h3>
                      )}
                      {content.text && (
                        <p className="text-gray-600 dark:text-gray-400">{content.text}</p>
                      )}
                      {content.list && (
                        <ul className="list-disc pl-6 space-y-2">
                          {content.list.map((item: string, itemIndex: number) => (
                            <li key={itemIndex} className="text-gray-600 dark:text-gray-400">{item}</li>
                          ))}
                        </ul>
                      )}
                      {content.contact && (
                        <p className="font-semibold">{content.contact}</p>
                      )}
                    </div>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
} 