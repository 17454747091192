import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';

export default function RemindMePage() {
  return (
    <>
      <Helmet>
        <title>Get Notified - Future Ring Openings | Ctrl AI</title>
        <meta 
          name="description" 
          content="Be the first to know when Ctrl AI opens Growth and Scale Rings. Join our waitlist for early access to upcoming community tiers." 
        />
      </Helmet>
      
      <div className="min-h-screen container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto text-center space-y-12"
        >
          <div className="space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold tracking-tight">
              Don't Miss Your Ring
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-400">
              Be first in line when we open our Growth and Scale Rings. Get exclusive early access and special perks for future community tiers.
            </p>
          </div>

          <div className="bg-white/5 backdrop-blur-sm p-8 rounded-3xl space-y-8">
            <div className="grid md:grid-cols-2 gap-6 text-left">
              <div className="space-y-4 p-6 rounded-2xl border-2 border-white/10">
                <h3 className="text-2xl font-bold">Growth Ring</h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-blue-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">10,000 builders</span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-blue-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">15% ownership stake</span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-blue-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">$20/month membership</span>
                  </li>
                </ul>
              </div>
              
              <div className="space-y-4 p-6 rounded-2xl border-2 border-white/10">
                <h3 className="text-2xl font-bold">Scale Ring</h3>
                <ul className="space-y-3">
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-purple-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">100,000 innovators</span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-purple-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">15% ownership stake</span>
                  </li>
                  <li className="flex items-start space-x-2">
                    <span className="w-2 h-2 mt-2.5 bg-purple-500 rounded-full flex-shrink-0" />
                    <span className="text-gray-500">$5/month membership</span>
                  </li>
                </ul>
              </div>
            </div>

            <form
              action="https://app.convertkit.com/forms/7468781/subscriptions"
              className="seva-form formkit-form"
              method="post"
              data-sv-form="7468781"
              data-uid="7c0d65b702"
              data-format="inline"
              data-version="5"
              data-options={`{"settings":{"after_subscribe":{"action":"redirect","success_message":"Success! You're on the list.","redirect_url":"https://ctrlai.com/thank-you"}}}`}
            >
              <div className="flex flex-col my-10 md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex-1">
                  <input
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-950 focus:ring-2 focus:ring-blue-500"
                    name="email_address"
                    aria-label="Email Address"
                    placeholder="Enter your email"
                    required
                    type="email"
                  />
                </div>
                <Button 
                  type="submit"
                  className="w-full h-12 md:w-auto px-8 bg-gray-900 dark:bg-white text-white dark:text-gray-900 hover:bg-gray-800 dark:hover:bg-gray-100"
                  size="lg"
                >
                  Notify Me
                </Button>
              </div>
            </form>
          </div>

          <p className="text-sm text-gray-500">
            By joining the waitlist, you'll be among the first to know when new rings open. No spam, unsubscribe anytime.
          </p>
        </motion.div>
      </div>
    </>
  );
} 