import React from 'react';
import { Modal } from './ui/modal';
import { Button } from './ui/button';
import { DialogTitle } from '@radix-ui/react-dialog';

interface ProfessionChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentProfession: string;
  newProfession: string;
  isPremium?: boolean;
}

export const ProfessionChangeModal: React.FC<ProfessionChangeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  currentProfession,
  newProfession,
  isPremium
}) => {
  const handleConfirm = () => {
    // Call the onConfirm handler
    onConfirm();
    // Close the modal
    onClose();
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      description={currentProfession 
        ? `Change profession from ${currentProfession} to ${newProfession}` 
        : `Set profession as ${newProfession}`}
    >
      <div className="space-y-4 p-6">
        <DialogTitle className="text-xl font-bold">
          {currentProfession ? 'Change Profession' : 'Confirm Profession'}
        </DialogTitle>
        
        <div className="space-y-4">
          <div>
            {currentProfession ? (
              <span>
                Are you sure you want to change your profession from {currentProfession} to {newProfession}?
              </span>
            ) : (
              <span>
                Would you like to set your profession as {newProfession}?
              </span>
            )}
          </div>

          {!isPremium && currentProfession && (
            <div className="bg-yellow-50 dark:bg-yellow-900/20 p-4 rounded-md">
              <div className="text-sm text-yellow-800 dark:text-yellow-200">
                <strong>Warning:</strong> As a free user, changing your profession will:
                <ul className="list-disc ml-4 mt-2">
                  <li>Archive all your current tasks</li>
                  <li>Reset your points to 0</li>
                  <li>Reset your AI level to 0</li>
                  <li>Lock profession changes for 30 days</li>
                </ul>
                Upgrade to Premium to keep your progress when changing professions.
              </div>
            </div>
          )}
        </div>
        
        <div className="flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            onClick={handleConfirm}
            variant={!isPremium && currentProfession ? "destructive" : "default"}
          >
            {!isPremium && currentProfession ? "Reset & Change" : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
